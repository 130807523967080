// axios is nasty to be included in the closure build process
// therefore we make axios appear as an external library for google closure
// after compilation we concatenate node_modules/axios/dist/axios.min.js (size roughly 12941) with the viewer
var o;
try {
  // in the browser axios will already have been loaded at this stage
  o = eval('axios');
} catch (e) {
  if ( typeof require === 'function' ) {
    // we use eval to require for development, because this require will not be seen by closure
    o = eval('require(\'axios\')');
  }
}
module.exports = o;
