
const THREE = require('../../../externals/three'),
      GLOBAL_UTILS = require('../../../shared/util/GlobalUtils');

/**
 * The light scenes that are defined by us.
 * default: the new default without the flash light
 * legacy: the old light definition (is loaded when the model was last saved in an older version)
 */
let lightScenes = {
  'default': {
    id: 'default',
    lights: {
      'ambient0': {
        id: 'ambient0',
        type: 'ambient',
        properties: {
          color: 0xffffff,
          intensity: 0.5
        }
      },
      'directional0': {
        id: 'directional0',
        type: 'directional',
        properties: {
          color: 0xffffff,
          intensity: 0.75,
          direction: { x: .5774, y: -.5774, z: .5774 },
          castShadow: true,
        }
      },
      'directional1': {
        id: 'directional1',
        type: 'directional',
        properties: {
          color: 0xffffff,
          intensity: 0.35,
          direction: { x: -.25, y: -1, z: 1 },
          castShadow: false,
        }
      },
    }
  },
  'legacy': {
    id: 'legacy',
    lights: {
      'ambient0': {
        id: 'ambient0',
        type: 'ambient',
        properties: {
          color: 0x646464, // rgb 100, 100, 100
          intensity: 1
        }
      },
      'directional0': {
        id: 'directional0',
        type: 'directional',
        properties: {
          color: 0xffffff,
          intensity: 1,
          castShadow: true,
          direction: { x: .5774, y: -.5774, z: .5774 },
        }
      },
      'flash': {
        id: 'flash0',
        type: 'flash',
        properties: {
          color: 0xffffff,
          intensity: 0.4,
          distance: 0,
          angle: Math.PI / 2,
          penumbra: 5,
          decay: 5,
        }
      }
    }
  },
};

/**
 * The properties that can be set per light.
 * The type and default values for every property.
 */
let lightProperties = {
  'ambient': {
    color: {
      type: 'color',
      default: 0xffffff
    },
    intensity: {
      type: 'notnegative',
      default: 0.5
    }
  },
  'directional': {
    color: {
      type: 'color',
      default: 0xffffff
    },
    intensity: {
      type: 'notnegative',
      default: 0.5
    },
    castShadow: {
      type: 'boolean',
      default: false
    },
    direction: {
      type: 'vector3any',
      default: { x: -1, y: 0, z: 1 }
    },
  },
  'hemisphere': {
    skyColor: {
      type: 'color',
      default: 0xffffff
    },
    groundColor: {
      type: 'color',
      default: 0xffffff
    },
    intensity: {
      type: 'notnegative',
      default: 0.5
    },
  },
  'point': {
    color: {
      type: 'color',
      default: 0xffffff
    },
    intensity: {
      type: 'notnegative',
      default: 0.5
    },
    distance: {
      type: 'notnegative',
      default: 0
    },
    decay: {
      type: 'notnegative',
      default: 2
    },
    position: {
      type: 'vector3any',
      default: { x: 0, y: 0, z: 0 }
    },
  },
  // #SS-1113
  // 'rectarea': {
  //   color: {
  //     type: 'color',
  //     default: 0xffffff
  //   },
  //   intensity: {
  //     type: 'notnegative',
  //     default: 1
  //   },
  //   width: {
  //     type: 'notnegative',
  //     default: 10
  //   },
  //   height: {
  //     type: 'notnegative',
  //     default: 10
  //   },
  //   position: {
  //     type: 'vector3any',
  //     default: {x: 1, y: 1, z: 1}
  //   },
  //   lookAt: {
  //     type: 'vector3any',
  //     default: {x: 0, y: 0, z: 0}
  //   },
  // },
  'spot': {
    color: {
      type: 'color',
      default: 0xffffff
    },
    intensity: {
      type: 'notnegative',
      default: 0.5
    },
    distance: {
      type: 'notnegative',
      default: 0
    },
    angle: {
      type: 'notnegative',
      default: Math.PI / 4
    },
    penumbra: {
      type: 'notnegative',
      default: 0.5
    },
    decay: {
      type: 'notnegative',
      default: 1
    },
    position: {
      type: 'vector3any',
      default: { x: 1, y: 1, z: 1 }
    },
    target: {
      type: 'vector3any',
      default: { x: 0, y: 0, z: 0 }
    },
  },
  'flash': {
    color: {
      type: 'color',
      default: 0xffffff
    },
    intensity: {
      type: 'notnegative',
      default: 0.4
    },
    distance: {
      type: 'notnegative',
      default: 0
    },
    angle: {
      type: 'notnegative',
      default: Math.PI / 2
    },
    penumbra: {
      type: 'notnegative',
      default: 5
    },
    decay: {
      type: 'notnegative',
      default: 5
    },
  },
};

/**
 * The different light types. Has to be the same as defined in the LightApiInterface.js and the LightApi.js
 */
let lightTypes = {
  /**  ambient light */
  AMBIENT: 'ambient',
  /**  directional light */
  DIRECTIONAL: 'directional',
  /** point light */
  HEMISPHERE: 'hemisphere',
  /** point light */
  POINT: 'point',
  // #SS-1113
  // /** rectangle area light */
  // RECTAREA : 'rectarea',
  /** spot light */
  SPOT: 'spot',
  /** flash light */
  FLASH: 'flash',
};

let clampingFactor = 230/255;

/**
 * Functions per light type to create a light and the according helper.
 */
let initFunction = {
  'ambient': function (lightDefinition, _lights, _helper) {
    let color = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.color).toThreeColor();
    let threeLight = new THREE.AmbientLight(color, lightDefinition.properties.intensity);
    _lights.add(threeLight);

    let helper = new THREE.Object3D();
    _helper.add(helper);

    return {
      light: threeLight,
      helper: new THREE.Object3D()
    };
  },
  'directional': function (lightDefinition, _lights, _helper, _sceneBS, _forceShadowDisabling, _shadowsEnabled) {
    let color = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.color).toThreeColor();
    let threeLight = new THREE.DirectionalLight(color, lightDefinition.properties.intensity);
    let dirLightTarget = new THREE.Object3D();
    _lights.add(dirLightTarget);
    threeLight.target = dirLightTarget;
    _lights.add(threeLight);

    // Adjust the direction, position and target
    threeLight.direction = GLOBAL_UTILS.toVector3(lightDefinition.properties.direction).normalize();
    threeLight.position.set(_sceneBS.center.x + threeLight.direction.x * _sceneBS.radius * 2.35, _sceneBS.center.y + threeLight.direction.y * _sceneBS.radius * 2.35, _sceneBS.center.z + threeLight.direction.z * _sceneBS.radius * 2.35);
    threeLight.target.position.set(_sceneBS.center.x, _sceneBS.center.y, _sceneBS.center.z);

    if (lightDefinition.properties.castShadow) {
      threeLight.shadows = true;
      threeLight.castShadow = _forceShadowDisabling ? false : _shadowsEnabled;
      threeLight.shadow.camera.up.set(0, 0, 1);
      threeLight.shadow.camera.far = 8 * _sceneBS.radius;
      threeLight.shadow.camera.right = 1.5 * _sceneBS.radius;
      threeLight.shadow.camera.left = -1.5 * _sceneBS.radius;
      threeLight.shadow.camera.top = 1.5 * _sceneBS.radius;
      threeLight.shadow.camera.bottom = -1.5 * _sceneBS.radius;
      threeLight.shadow.mapSize.width = 2048;
      threeLight.shadow.mapSize.height = 2048;
      threeLight.shadow.radius = 2;
      threeLight.shadow.bias = -0.00175;
      threeLight.shadow.camera.updateProjectionMatrix();
    }

    let helper = new THREE.ArrowHelper(
      threeLight.direction.clone().multiplyScalar(-1),
      threeLight.position,
      threeLight.target.position.distanceTo(threeLight.position),
      color.clone().multiplyScalar(clampingFactor)
    );
    helper.cone.material.wireframe = true;
    _helper.add(helper);

    return {
      light: threeLight,
      helper: helper
    };
  },
  'hemisphere': function (lightDefinition, _lights, _helper) {
    let skyColor = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.skyColor).toThreeColor();
    let groundColor = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.groundColor).toThreeColor();
    let threeLight = new THREE.HemisphereLight(skyColor, groundColor, lightDefinition.properties.intensity);
    threeLight.position.set(0,0,1);
    threeLight.up.set(0, 0, 1);
    _lights.add(threeLight);

    let helper = new THREE.Object3D();
    _helper.add(helper);

    return {
      light: threeLight,
      helper: helper
    };
  },
  'point': function (lightDefinition, _lights, _helper, _sceneBS) {
    let color = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.color).toThreeColor();
    let threeLight = new THREE.PointLight(color, lightDefinition.properties.intensity, lightDefinition.properties.distance, lightDefinition.properties.decay);
    threeLight.position.set(lightDefinition.properties.position.x, lightDefinition.properties.position.y, lightDefinition.properties.position.z);
    _lights.add(threeLight);

    let geometry = new THREE.IcosahedronBufferGeometry(_sceneBS.radius / 10, 0.0);
    let material = new THREE.MeshBasicMaterial({ color: color.clone().multiplyScalar(clampingFactor), wireframe: true, fog: false });
    let mesh = new THREE.Mesh(geometry, material);
    let helper = new THREE.Object3D();
    helper.add(mesh);
    helper.position.set(lightDefinition.properties.position.x, lightDefinition.properties.position.y, lightDefinition.properties.position.z);
    _helper.add(helper);

    return {
      light: threeLight,
      helper: helper
    };
  },
  // #SS-1113
  //'rectarea': function () {
  //   let threeLight = new THREE.RectAreaLight(lightDefinition.properties.color, lightDefinition.properties.intensity, lightDefinition.properties.width, lightDefinition.properties.height);
  //   threeLight.up.set(0,0,1);
  //   threeLight.position.set(lightDefinition.properties.position.x, lightDefinition.properties.position.y, lightDefinition.properties.position.z);
  //   threeLight.lookAt(_sceneBS.center.x, _sceneBS.center.y, _sceneBS.center.z);


  //   let rectLightMesh = new THREE.Mesh( new THREE.PlaneBufferGeometry(), new THREE.MeshBasicMaterial( { color: lightDefinition.properties.color, side: THREE.DoubleSide } ) );
  //   rectLightMesh.scale.x = lightDefinition.properties.width;
  //   rectLightMesh.scale.y = lightDefinition.properties.height;
  //   threeLight.add( rectLightMesh );

  //   return {
  //     light: threeLight,
  //     helper: helper
  //   };
  //},
  'spot': function (lightDefinition, _lights, _helper) {
    let color = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.color).toThreeColor();
    let d = new THREE.Vector3(lightDefinition.properties.position.x, lightDefinition.properties.position.y, lightDefinition.properties.position.z)
      .distanceTo(new THREE.Vector3(lightDefinition.properties.target.x, lightDefinition.properties.target.y, lightDefinition.properties.target.z));
    let threeLight = new THREE.SpotLight(color, lightDefinition.properties.intensity, d, lightDefinition.properties.angle, lightDefinition.properties.penumbra, lightDefinition.properties.decay);
    let spotLightTarget = new THREE.Object3D();
    _lights.add(spotLightTarget);
    threeLight.target = spotLightTarget;

    // Adjust the direction, position and target
    threeLight.position.set(lightDefinition.properties.position.x, lightDefinition.properties.position.y, lightDefinition.properties.position.z);
    threeLight.target.position.set(lightDefinition.properties.target.x, lightDefinition.properties.target.y, lightDefinition.properties.target.z);
    threeLight.up.set(0, 0, 1);
    _lights.add(threeLight);

    let helper = new THREE.SpotLightHelper(threeLight, color.clone().multiplyScalar(clampingFactor));
    helper.up.set(0, 0, 1);

    _helper.add(helper);

    return {
      light: threeLight,
      helper: helper
    };
  },
  'flash': function (lightDefinition, _handlers, _helper) {
    let color = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.color).toThreeColor();
    let threeLight = new THREE.SpotLight(color, lightDefinition.properties.intensity, lightDefinition.properties.distance, lightDefinition.properties.angle, lightDefinition.properties.penumbra, lightDefinition.properties.decay);
    threeLight.position.set(0, 0, 1);
    threeLight.target = _handlers.cameraHandler.camera;

    _handlers.cameraHandler.camera.add(threeLight);

    let helper = new THREE.Object3D();
    _helper.add(helper);

    return {
      light: threeLight,
      helper: helper
    };
  },
};

/**
 * Functions per light type to update a light and the according helper.
 */
let updateFunction = {
  'ambient': function (lightDefinition, lightObject) {
    let color = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.color).toThreeColor();
    lightObject.light.color = color;
    lightObject.light.intensity = lightDefinition.properties.intensity;
  },
  'directional': function (lightDefinition, lightObject, _sceneBS, _forceShadowDisabling, _shadowsEnabled) {
    let color = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.color).toThreeColor();
    lightObject.light.color = color;
    lightObject.light.intensity = lightDefinition.properties.intensity;

    lightObject.light.direction = GLOBAL_UTILS.toVector3(lightDefinition.properties.direction).normalize();
    lightObject.light.position.set(_sceneBS.center.x + lightObject.light.direction.x * _sceneBS.radius * 2.35, _sceneBS.center.y + lightObject.light.direction.y * _sceneBS.radius * 2.35, _sceneBS.center.z + lightObject.light.direction.z * _sceneBS.radius * 2.35);
    lightObject.light.target.position.set(_sceneBS.center.x, _sceneBS.center.y, _sceneBS.center.z);

    if (lightObject.light.shadows !== lightDefinition.properties.castShadow) {
      if (lightDefinition.properties.castShadow) {
        lightObject.light.shadows = true;
        lightObject.light.castShadow = _forceShadowDisabling ? false : _shadowsEnabled;
        lightObject.light.shadow.camera.up.set(0, 0, 1);
        lightObject.light.shadow.camera.far = 8 * _sceneBS.radius;
        lightObject.light.shadow.camera.right = 1.5 * _sceneBS.radius;
        lightObject.light.shadow.camera.left = -1.5 * _sceneBS.radius;
        lightObject.light.shadow.camera.top = 1.5 * _sceneBS.radius;
        lightObject.light.shadow.camera.bottom = -1.5 * _sceneBS.radius;
        lightObject.light.shadow.mapSize.width = 2048;
        lightObject.light.shadow.mapSize.height = 2048;
        lightObject.light.shadow.radius = 2;
        lightObject.light.shadow.bias = -0.00175;
        lightObject.light.shadow.camera.updateProjectionMatrix();
      } else {
        lightObject.light.shadows = false;
        lightObject.light.castShadow = false;
      }
    }

    lightObject.helper.setColor(color.clone().multiplyScalar(clampingFactor));
    lightObject.helper.setDirection(lightObject.light.direction.clone().multiplyScalar(-1));
    lightObject.helper.position.set(lightObject.light.position.x, lightObject.light.position.y, lightObject.light.position.z);
    lightObject.helper.setLength(lightObject.light.target.position.distanceTo(lightObject.light.position));

  },
  'hemisphere': function (lightDefinition, lightObject) {
    lightObject.light.intensity = lightDefinition.properties.intensity;

    let skyColor = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.skyColor).toThreeColor();
    let groundColor = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.groundColor).toThreeColor();
    if (lightObject.light.color != skyColor || lightObject.light.groundColor != groundColor) {
      lightObject.light.color = skyColor;
      lightObject.light.groundColor = groundColor;
    }
  },
  'point': function (lightDefinition, lightObject) {
    let color = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.color).toThreeColor();
    lightObject.light.color = color;
    lightObject.light.intensity = lightDefinition.properties.intensity;
    lightObject.light.distance = lightDefinition.properties.distance;
    lightObject.light.decay = lightDefinition.properties.decay;
    lightObject.light.position.set(lightDefinition.properties.position.x, lightDefinition.properties.position.y, lightDefinition.properties.position.z);

    let mesh = lightObject.helper.children[0];
    mesh.material.color = color.clone().multiplyScalar(clampingFactor);
    lightObject.helper.position.set(lightDefinition.properties.position.x, lightDefinition.properties.position.y, lightDefinition.properties.position.z);

  },
  // #SS-1113
  // 'rectarea': function () {
  //   // TODO
  // },
  'spot': function (lightDefinition, lightObject) {
    let color = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.color).toThreeColor();
    lightObject.light.color = color;
    lightObject.light.intensity = lightDefinition.properties.intensity;
    let d = new THREE.Vector3(lightDefinition.properties.position.x, lightDefinition.properties.position.y, lightDefinition.properties.position.z)
      .distanceTo(new THREE.Vector3(lightDefinition.properties.target.x, lightDefinition.properties.target.y, lightDefinition.properties.target.z));
    lightObject.light.distance = d;
    lightObject.light.angle = lightDefinition.properties.angle;
    lightObject.light.penumbra = lightDefinition.properties.penumbra;
    lightObject.light.decay = lightDefinition.properties.decay;

    // Adjust the direction, position and target
    lightObject.light.position.set(lightDefinition.properties.position.x, lightDefinition.properties.position.y, lightDefinition.properties.position.z);
    lightObject.light.target.position.set(lightDefinition.properties.target.x, lightDefinition.properties.target.y, lightDefinition.properties.target.z);

    lightObject.helper.color = color.clone().multiplyScalar(clampingFactor);
    lightObject.helper.update();
  },
  'flash': function (lightDefinition, lightObject) {
    let color = GLOBAL_UTILS.toTinyColor(lightDefinition.properties.color).toThreeColor();
    lightObject.light.color = color;
    lightObject.light.intensity = lightDefinition.properties.intensity;
    lightObject.light.distance = lightDefinition.properties.distance;
    lightObject.light.angle = lightDefinition.properties.angle;
    lightObject.light.penumbra = lightDefinition.properties.penumbra;
    lightObject.light.decay = lightDefinition.properties.decay;
  },
};

let defaultLightDefinitions = {
  lightScenes: lightScenes,
  lightProperties: lightProperties,
  lightTypes: lightTypes,
  lightInit: initFunction,
  lightUpdate: updateFunction
};

module.exports = defaultLightDefinitions;
