"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var SliderCustomComponent = /** @class */ (function (_super) {
    __extends(SliderCustomComponent, _super);
    // #endregion Properties (9)
    // #region Constructors (1)
    function SliderCustomComponent(id, parameterDefinition, parent, domManager, queue, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _a, _b, _c, _d;
        var _this = _super.call(this, "slider" /* SLIDER */, id, parameterDefinition, parent, domManager, queue, editMode) || this;
        // #region Properties (9)
        _this._delay = [0, 500];
        _this._maxVal = 100;
        _this._minVal = 0;
        _this._step = 0;
        _this._decimalPlaces = null;
        _this._value = 0;
        _this._inputId = id + "-number";
        _this._inputRangeId = id + "-range";
        if (_this._parameterDefinition.min) {
            _this._minVal = _this._parameterDefinition.min;
        }
        if (_this._parameterDefinition.max) {
            _this._maxVal = _this._parameterDefinition.max;
        }
        if (_this._parameterDefinition.value) {
            _this._value = _this._parameterDefinition.value;
        }
        if (typeof _this._parameterDefinition.step === "number" || typeof _this._parameterDefinition.step === "string") {
            _this._step = _this._parameterDefinition.step;
        }
        if (_this._parameterDefinition.delay) {
            if (Array.isArray(_this._parameterDefinition.delay)) {
                _this._delay = [Math.max((_a = _this._parameterDefinition.delay[0]) !== null && _a !== void 0 ? _a : 0, _this._delay[0]), Math.max((_b = _this._parameterDefinition.delay[1]) !== null && _b !== void 0 ? _b : 0, _this._delay[1])];
            }
            else if (typeof _this._delay === "number") {
                _this._delay = [Math.max((_c = _this._parameterDefinition.delay) !== null && _c !== void 0 ? _c : 0, _this._delay[0]), Math.max((_d = _this._parameterDefinition.delay) !== null && _d !== void 0 ? _d : 0, _this._delay[1])];
            }
        }
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(SliderCustomComponent.prototype, "decimalPlaces", {
        // #endregion Constructors (1)
        // #region Public Accessors (7)
        get: function () {
            return this._decimalPlaces;
        },
        set: function (v) {
            this._decimalPlaces = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderCustomComponent.prototype, "inputContainer", {
        get: function () {
            return this._inputContainer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderCustomComponent.prototype, "inputRange", {
        get: function () {
            return this._inputRange;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderCustomComponent.prototype, "size", {
        get: function () {
            return 2 /* HALF_WIDTH */;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderCustomComponent.prototype, "value", {
        get: function () {
            return this._inputComponent.value;
        },
        set: function (v) {
            v = Number(v);
            if (this._minVal && v < Number(this._minVal)) {
                v = this._minVal;
            }
            if (this._maxVal && v > Number(this._maxVal)) {
                v = this._maxVal;
            }
            if (this._decimalPlaces || this._decimalPlaces == 0) {
                var roundNum = Math.pow(10, this._decimalPlaces);
                v = Math.round(v * roundNum) / roundNum;
            }
            this._inputComponent.value = v;
            this._inputRange.value = v;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (7)
    // #region Protected Methods (3)
    SliderCustomComponent.prototype.createInput = function () {
        this.createRangeInput();
        this.createNumberInput();
    };
    SliderCustomComponent.prototype.createInputEvents = function () {
        var _this = this;
        var _a, _b;
        var event = this._domManager.isEventSupported("input", "input") ? "input" : "change";
        var delay0 = (_a = this._delay[0]) !== null && _a !== void 0 ? _a : 0;
        var delay1 = (_b = this._delay[1]) !== null && _b !== void 0 ? _b : 0;
        this._domManager.setDomEventListener(this._inputComponent, event, delay1, this.update.bind(this));
        this._domManager.setDomEventListener(this._inputComponent, "focusout", delay1, this.update.bind(this));
        this._domManager.setDomEventListener(this._inputComponent, "keyup", delay1, this.updateKeyUp.bind(this));
        this._domManager.setDomEventListener(this._inputRange, "input", null, function () { return _this._inputComponent.value = _this._inputRange.value; });
        this._domManager.setDomEventListener(this._inputRange, "mouseup", delay0, this.update.bind(this));
        this._domManager.setDomEventListener(this._inputRange, "touchend", delay0, this.update.bind(this));
    };
    SliderCustomComponent.prototype.update = function (v, event) {
        // small check in order to avoid return to previous value, from focusout event.
        var shouldCancel = event.type === "focusout" &&
            (v == this._inputComponent.value || ["mouseup", "touchend"].includes(this._previousEventType));
        this._previousEventType = event.type;
        if (shouldCancel)
            return;
        this.value = v;
        _super.prototype.update.call(this, this.value, event);
    };
    // #endregion Protected Methods (3)
    // #region Private Methods (3)
    SliderCustomComponent.prototype.createNumberInput = function () {
        this._inputComponent = this._domManager.createDomElement("input", this._inputId, this._inputContainer, ["uk-input", "uk-width-1-3", "uk-form-small", "sd-input-number"], {
            min: this._minVal,
            max: this._maxVal,
            step: this._step,
            type: "number",
            name: this._inputId,
            value: this._value || "0"
        });
    };
    SliderCustomComponent.prototype.createRangeInput = function () {
        this._inputRange = this._domManager.createDomElement("input", this._inputRangeId, this._inputContainer, ["uk-range", "uk-width-2-3", "uk-form-small", "sd-input-range"], {
            type: "range",
            name: this._inputRangeId,
            min: this._minVal,
            max: this._maxVal,
            step: this._step,
            value: this._value || "0",
            tabIndex: "-1"
        });
        // mobile styling
        if (this._domManager.userAgentIsMobileOrTabletDevice) {
            this._inputRange.classList.add("sd-input-range-mobile");
        }
        // leave a bit of padding for ios mobile devices due to swipe gesture
        if (this._domManager.userAgentIsIPadOrIPhone) {
            this._inputRange.classList.add("ios-padding-left");
        }
        else {
            this._inputRange.classList.add("uk-padding-remove-left");
        }
    };
    SliderCustomComponent.prototype.updateKeyUp = function (v, e) {
        if (e.which || e.keyCode) {
            if (e.which == 13 || e.keyCode == 13) {
                this.update(v, e);
            }
        }
    };
    return SliderCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.SliderCustomComponent = SliderCustomComponent;
