/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiExportInterfaceV2.1.js*
 *
 * ### Content
 *   * Abstract definition of the ShapeDiver 3D Viewer Export API V2.1
 *
 * @module ApiExportInterface
 * @author ShapeDiver <contact@shapediver.com>
 */


////////////
////////////
//
// Export Interface
//
////////////
////////////

/**
 * Export request result, see {@link module:ApiExportInterface~ApiExportInterface#RESULT} for values
 *
 * @typedef {String} module:ApiExportInterface~ApiExportInterface#ExportRequestResult
 */

/**
  * Export event type, see {@link module:ApiExportInterface~ApiExportInterface#EVENTTYPE} for values
  * @typedef {String} module:ApiExportInterface~ApiExportInterface#ExportEventType
  */

/**
 * Definition of an export
 * @typedef {Object} module:ApiExportInterface~ApiExportInterface#ExportDefinition
 * @property {String} id - unique id of export
 * @property {String} plugin - plugin runtime id of export
 * @property {String} name - name of export
 * @property {String} [_name] - original name of the export, will be set in case the export name got changed from its original value
 * @property {String} type - type of export, currently we support 'email', 'download', and 'shapeways'
 * @property {Boolean} [hidden] - must exist and be true if the export should be hidden from the ui
 * @property {Number} [order] - optional number used for ordering exports in the ui
 */

/**
 * Data required for requesting an export
 * @typedef {Object} module:ApiExportInterface~ApiExportInterface#ExportRequestObject
 * @property {String} [id] - id of export, takes precedence over idOrName and name
 * @property {String} [idOrName] - id or name of export, takes precedence over name
 * @property {String} [name] - name of export, last priority after id and idOrName
 * @property {String} [plugin] - runtime id of plugin the export belongs to
 * @property {Object} [parameters] - optional key value pairs of parameter ids and corresponding values to use instead of the currently set values
 * @property {Boolean} [silent=false] - if true, do not display any information about the export to the user
 */

/**
 * Export content item of a {@link module:ApiExportInterface~ApiExportInterface#ExportResult ExportResult}
 * @typedef {Object} module:ApiExportInterface~ApiExportInterface#ExportResultContentItem
 * @property {String} format - format of the exported file, e.g. stl|3dm|dxf|dwg|…
 * @property {String} href - download link to the exported file, time limited to the duration of the session
 * @property {Number} size - size in bytes of the exported file
 */

/**
 * Definition of the object resulting from a finished export, or from a status of an ongoing export
 * @typedef {Object} module:ApiExportInterface~ApiExportInterface#ExportResult
 * @property {String} id - unique id of the export
 * @property {String} plugin - plugin runtime id of export
 * @property {String} name - name of the export
 * @property {String} type - type of export, currently we support 'email', 'download', and 'shapeways'
 * @property {String} [msg] - optional status message for the export, e.g. describing server side progress or status
 * @property {String} [version] - version id of the export
 * @property {String} [filename] - optional filename to be used for files which are part of content (FILENAME.FILETYPE)
 * @property {module:ApiExportInterface~ApiExportInterface#ExportResultContentItem[]} [content] - will be set once the export has been finished for type 'download'. Typically this array has only one entry.
 * @property {Object} [result] - optional object describing the result of the export for types 'email' and 'shapeways'
 * @property {String} [result.msg] - optional message to display to the user
 * @property {String} [result.err] - optional error message to display to the user
 * @property {String} [result.href] - optional link to direct the user to
 * @property {String} [result.modelId] - optional ShapeWays model id
 * @property {module:ApiExportInterface~ApiExportInterface#ExportRequestResult} [resultcode] - result code
 */

/**
* ### ShapeDiver Viewer - Export API
*
* The export api is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2 API}.
*
* It allows to
*  * get the definition of currently registered exports,
*  * request exports, and
*  * add and remove listeners for related events.
*
* @interface ApiExportInterface
*/
var ApiExportInterface = function () {

  /**
  * Enum for export request result.
  * @readonly
  * @enum {module:ApiExportInterface~ApiExportInterface#ExportRequestResult}
  */
  this.RESULT = {
    /** the export was not found */
    EXPORT_NOT_FOUND: 'export_not_found',
    /** the plugin responsible to handle the export request was not found */
    PLUGIN_NOT_FOUND: 'plugin_not_found',
    /** the export request failed */
    ERROR: 'error',
    /** the export request can be answered from the local cache */
    CACHE: 'cache',
    /** the export request needs to be loaded from somewhere */
    LOAD: 'load'
  };

  /**
   * Enum for supported export event types.
   * @readonly
   * @enum {module:ApiExportInterface~ApiExportInterface#ExportEventType}
   */
  this.EVENTTYPE = {
    /** the result of an export has become available */
    AVAILABLE: 'available',
    /** a new export has been registered */
    REGISTERED: 'registered',
    /** the definition of an export has been updated, typically one of the properties name or order or hidden */
    UPDATE: 'update',
    /** status event for an ongoing export */
    STATUS: 'status'
  };

  /**
  * Get all export definitions
  *
  * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an array of {@link module:ApiExportInterface~ApiExportInterface#ExportDefinition export definitions}
  */
  this.get = function () { };

  /**
  * Requests an export for current parameter values.
  *
  * @param  {module:ApiExportInterface~ApiExportInterface#ExportRequestObject} requestObject - Object defining the export request.
  * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function.
  * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a {@link module:ApiExportInterface~ApiExportInterface#ExportResult export result} object.
  */
  this.requestAsync = function () { };

  /**
  * Update properties of registered exports.
  *
  * The following properties can be updated: name, order, hidden
  * This function will fail in case no unique export can be found for each export definition given.
  *
  * @param {module:ApiExportInterface~ApiExportInterface#ExportDefinition[]} [definitions] - updated export definitions
  * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with its data set to a boolean according to success or error
  */
  this.updateProperties = function () { };

  /**
   * Add a callback to be invoked when a certain event occurs
   *
   * @param {module:ApiExportInterface~ApiExportInterface#ExportEventType} type - Type of event to subscribe to
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback} callback - Function to be called when the event fires
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an {@link module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken EventListenerToken} object. The event listener token can be used to remove the event listener by calling {@link module:ApiExportInterface~ApiExportInterface#removeEventListener removeEventListener}.
   */
  this.addEventListener = function () { };

  /**
   * Remove an event listener
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken} token - event listener token for event listener to be removed
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the event listener could be removed.
   */
  this.removeEventListener = function () { };

};

module.exports = ApiExportInterface;