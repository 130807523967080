/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *GlobalMixin.js*
 *
 * ### Content
 *   * mixin containing common functionality which should be injected to all classes
 *
 * @module GlobalMixin
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Definition of the constructor of the global prototype
 * @mixin GlobalMixin
 * @author Alex Schiftner <alex@shapediver.com>
 */
var GlobalMixin = function() {

  ////////////
  ////////////
  //
  // Commonly shared functionality
  //
  ////////////
  ////////////

  /**
   * JSON.stringify an object without newlines for inline logging
   */
  this.jsify = function(o) {
    return JSON.stringify(o, null, 0);
  };

  return this;
};

// here we could define public functions
//GlobalMixin.prototype.

// export the constructor
module.exports = GlobalMixin;
