"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var SDControlsParameterHandler = /** @class */ (function () {
    // #endregion Properties (5)
    // #region Constructors (1)
    function SDControlsParameterHandler(_api, _sdDependenciesResolver) {
        var _this = this;
        this._api = _api;
        this._sdDependenciesResolver = _sdDependenciesResolver;
        // #region Properties (5)
        this._exportRegisteredEvents = [];
        this._exportUpdateEvents = [];
        this._parameterRegisteredEvents = [];
        this._parametersUpdateEvents = [];
        this._typesMap = {
            "parameter.Int": "slider" /* SLIDER */,
            "parameter.Odd": "slider" /* SLIDER */,
            "parameter.Even": "slider" /* SLIDER */,
            "parameter.Float": "slider" /* SLIDER */,
            "parameter.String": "string" /* STRING */,
            "parameter.Bool": "checkbox" /* CHECKBOX */,
            "parameter.Color": "color" /* COLOR */,
            "parameter.StringList.checklist": "checklist" /* CHECKLIST */,
            "parameter.StringList.sequence": "dropdown" /* DROPDOWN */,
            "parameter.StringList.cycle": "dropdown" /* DROPDOWN */,
            "parameter.StringList.dropdown": "dropdown" /* DROPDOWN */,
            "parameter.File": "file" /* FILE */,
        };
        this._api.parameters.addEventListener(this._api.parameters.EVENTTYPE.REGISTERED, function (event) { return __awaiter(_this, void 0, void 0, function () {
            var param;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        param = this.resolveParameter(event);
                        return [4 /*yield*/, this._sdDependenciesResolver.resolveDependenciesAsync()];
                    case 1:
                        _a.sent();
                        this._parameterRegisteredEvents.forEach(function (cb) { return cb(param); });
                        return [2 /*return*/];
                }
            });
        }); });
        this._api.exports.addEventListener(this._api.exports.EVENTTYPE.REGISTERED, function (event) { return __awaiter(_this, void 0, void 0, function () {
            var param;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        param = this.resolveExport(event);
                        return [4 /*yield*/, this._sdDependenciesResolver.resolveDependenciesAsync()];
                    case 1:
                        _a.sent();
                        this._exportRegisteredEvents.forEach(function (cb) { return cb(param); });
                        return [2 /*return*/];
                }
            });
        }); });
        this._api.parameters.addEventListener(this._api.parameters.EVENTTYPE.UPDATE, function (event) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._sdDependenciesResolver.resolveDependenciesAsync()];
                    case 1:
                        _a.sent();
                        this.handleUpdateEvent(event);
                        return [2 /*return*/];
                }
            });
        }); });
        this._api.exports.addEventListener(this._api.exports.EVENTTYPE.UPDATE, function (event) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._sdDependenciesResolver.resolveDependenciesAsync()];
                    case 1:
                        _a.sent();
                        this.handleUpdateEvent(event);
                        return [2 /*return*/];
                }
            });
        }); });
        ;
    }
    // #endregion Constructors (1)
    // #region Public Methods (4)
    SDControlsParameterHandler.prototype.onExportRegistered = function (cb) {
        this._exportRegisteredEvents.push(cb);
    };
    SDControlsParameterHandler.prototype.onExportUpdated = function (cb) {
        this._exportUpdateEvents.push(cb);
    };
    SDControlsParameterHandler.prototype.onParameterRegistered = function (cb) {
        this._parameterRegisteredEvents.push(cb);
    };
    SDControlsParameterHandler.prototype.onParameterUpdate = function (cb) {
        this._parametersUpdateEvents.push(cb);
    };
    // #endregion Public Methods (4)
    // #region Private Methods (4)
    SDControlsParameterHandler.prototype.convertParameterType = function (paramType) {
        var type = this._typesMap["parameter" + paramType.type + "." + paramType.visualization];
        if (!type)
            type = this._typesMap["parameter." + paramType.type];
        if (!type)
            type = this._typesMap["parameter"];
        return type;
    };
    SDControlsParameterHandler.prototype.handleUpdateEvent = function (event) {
        var _this = this;
        var isParameter = !!event.parameter;
        var object = (isParameter ? event.parameter : event.export);
        var hasId = !!object.id;
        if (hasId) {
            if (isParameter) {
                this._parametersUpdateEvents.forEach(function (cb) { return cb(object); });
            }
            else {
                this._exportUpdateEvents.forEach(function (cb) { return cb(object); });
            }
        }
        else {
            Object.keys(object).forEach(function (key) {
                if (isParameter) {
                    _this._parametersUpdateEvents.forEach(function (cb) { return cb(object[key]); });
                }
                else {
                    _this._exportUpdateEvents.forEach(function (cb) { return cb(object[key]); });
                }
            });
        }
    };
    SDControlsParameterHandler.prototype.resolveExport = function (event) {
        if (!event.export)
            return;
        var id = Object.keys(event.export)[0];
        var param = event.export[id];
        param.elementType = "button" /* BUTTON */;
        param.hidden = param.hidden ? param.hidden : false;
        param.order = param.order ? param.order : Number.MAX_SAFE_INTEGER;
        param.isParameter = false;
        return param;
    };
    SDControlsParameterHandler.prototype.resolveParameter = function (event) {
        if (!event.parameter)
            return;
        var id = Object.keys(event.parameter)[0];
        var param = event.parameter[id];
        param.elementType = this.convertParameterType(param);
        param.hidden = param.hidden ? param.hidden : false;
        param.order = param.order ? param.order : Number.MAX_SAFE_INTEGER;
        param.isParameter = true;
        return param;
    };
    return SDControlsParameterHandler;
}());
exports.SDControlsParameterHandler = SDControlsParameterHandler;
