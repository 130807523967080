"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var THREE = require("../../../../../../externals/three");
var CameraCylindricalInterpolation = /** @class */ (function () {
    // #endregion Properties (10)
    // #region Constructors (1)
    function CameraCylindricalInterpolation(_cameraControls, _from, _to) {
        this._cameraControls = _cameraControls;
        this._from = _from;
        this._to = _to;
        this._h_from = this._from.position.z - this._from.target.z;
        this._from_position_heightAdjusted = new THREE.Vector3(this._from.position.x, this._from.position.y, this._from.target.z);
        this._r_from = this._from_position_heightAdjusted.distanceTo(this._from.target);
        this._dir_from = this._from_position_heightAdjusted.sub(this._from.target).normalize();
        this._h_to = this._to.position.z - this._to.target.z;
        this._to_position_heightAdjusted = new THREE.Vector3(this._to.position.x, this._to.position.y, this._to.target.z);
        this._r_to = this._to_position_heightAdjusted.distanceTo(this._to.target);
        this._dir_to = this._to_position_heightAdjusted.sub(this._to.target).normalize();
        if (this._dir_from.x === 0 && this._dir_from.y === 0 && this._dir_from.z === 0)
            this._dir_from = new THREE.Vector3(1, 0, 0);
        if (this._dir_to.x === 0 && this._dir_to.y === 0 && this._dir_to.z === 0)
            this._dir_to = new THREE.Vector3(1, 0, 0);
        this._lorr = this._dir_to.clone().cross(this._dir_from);
        // This is why people hate JavaScript. The dot product of two normalized vector is larger than 1 on occasion due to precision errors...
        var dot1 = Math.min(1, Math.max(-1, this._dir_to.dot(this._dir_from)));
        var dot2 = Math.min(1, Math.max(-1, this._lorr.dot(new THREE.Vector3(0, 0, 1))));
        this._shortest_angle = dot2 > 0 ? -Math.acos(dot1) : Math.acos(dot1);
    }
    // #endregion Constructors (1)
    // #region Public Methods (3)
    CameraCylindricalInterpolation.prototype.onComplete = function (value) {
        var positionOffset = new THREE.Vector3(this._to.position.x, this._to.position.y, this._to.position.z).sub(this._cameraControls.position);
        this._cameraControls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(positionOffset.x, positionOffset.y, positionOffset.z));
        var targetOffset = new THREE.Vector3(this._to.target.x, this._to.target.y, this._to.target.z).sub(this._cameraControls.target);
        this._cameraControls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(targetOffset.x, targetOffset.y, targetOffset.z));
    };
    CameraCylindricalInterpolation.prototype.onStop = function (value) {
    };
    CameraCylindricalInterpolation.prototype.onUpdate = function (value) {
        var t = this._from.target.clone().multiplyScalar(1 - value.delta).add(this._to.target.clone().multiplyScalar(value.delta));
        var targetOffset = t.clone().sub(this._cameraControls.target);
        this._cameraControls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(targetOffset.x, targetOffset.y, targetOffset.z));
        var angle = this._shortest_angle * value.delta;
        var dir = this._dir_from.clone().applyAxisAngle(new THREE.Vector3(0, 0, 1), angle);
        var p = t.clone().add(dir.clone().multiplyScalar((this._r_from * (1 - value.delta) + this._r_to * value.delta)));
        p.add(new THREE.Vector3(0, 0, (this._h_from * (1 - value.delta) + this._h_to * value.delta)));
        var positionOffset = p.clone().sub(this._cameraControls.position);
        this._cameraControls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(positionOffset.x, positionOffset.y, positionOffset.z));
    };
    return CameraCylindricalInterpolation;
}());
exports.CameraCylindricalInterpolation = CameraCylindricalInterpolation;
