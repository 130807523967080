"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SDControlsSettingsHandler = /** @class */ (function () {
    function SDControlsSettingsHandler(_api) {
        this._api = _api;
        this._settingUpdateEvents = [];
        _api.addEventListener("settings.update", function (event) {
        });
    }
    SDControlsSettingsHandler.prototype.onSettingUpdate = function (cb) {
        this._settingUpdateEvents.push(cb);
    };
    return SDControlsSettingsHandler;
}());
exports.SDControlsSettingsHandler = SDControlsSettingsHandler;
