"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var DropdownSettingComponent_1 = require("../DropdownSettingComponent");
var LoadLightSceneDropdownSettingComponent = /** @class */ (function (_super) {
    __extends(LoadLightSceneDropdownSettingComponent, _super);
    // #endregion Properties (1)
    // #region Constructors (1)
    function LoadLightSceneDropdownSettingComponent(api, settingDefinition, parent, _factory, _controlsEvents, _sdControlSettings) {
        var _this = _super.call(this, api, {
            id: "lights.lightScene",
            setting: "lights.lightScene",
            choices: api.scene.lights.getAllLightScenes().data,
            order: settingDefinition.order,
            groupId: settingDefinition.groupId,
            name: "Load Light Scene",
            title: "Load a saved light configuration.",
            type: "dropdown" /* DROPDOWN */
        }, parent, _factory) || this;
        _this._factory = _factory;
        _this._controlsEvents = _controlsEvents;
        _this._sdControlSettings = _sdControlSettings;
        // #region Properties (1)
        _this._createInitalLightsCalled = false;
        _this.parameterDefinition.update = function (v) { return _this.customUpdate(v); };
        _this._controlsEvents.on("light.scene.created" /* LIGHT_SCENE_CREATED */, function () { return _this.onLightsSceneUpdated(); });
        _this._api.addEventListener(_this._api.EVENTTYPE.SETTINGS_UPDATE, function (event) {
            if (event.settings.key === "lights.lightScenes") {
                _this.onLightsSceneUpdated();
            }
        });
        return _this;
    }
    // #endregion Constructors (1)
    // #region Public Methods (2)
    LoadLightSceneDropdownSettingComponent.prototype.createLightElements = function () {
        if (this._createInitalLightsCalled)
            return;
        this._createInitalLightsCalled = true;
        this._controlsEvents.trigger("clear.lights" /* CLEAR_LIGHTS */);
        var choices = this._api.scene.lights.getAllLightScenes().data;
        this.parameterDefinition.choices = choices;
        var v = this._api.scene.lights.getLightScene().data.id;
        this._component.updateOptions(choices, v);
        this.createLights(v);
    };
    LoadLightSceneDropdownSettingComponent.prototype.customUpdate = function (v) {
        this._controlsEvents.trigger("clear.lights" /* CLEAR_LIGHTS */);
        this.createLights(v);
    };
    // #endregion Public Methods (2)
    // #region Private Methods (2
    LoadLightSceneDropdownSettingComponent.prototype.createLights = function (scene) {
        var lights = [];
        if (this.parameterDefinition.choices.includes(scene)) {
            this._api.scene.lights.setLightSceneFromID(scene).data;
            lights = this._api.scene.lights.getLightScene(scene).data.lights;
        }
        else {
            lights = this._api.scene.lights.getLightScene().data.lights;
        }
        for (var key in lights) {
            var light = lights[key];
            var setting = this._sdControlSettings.createLightSetting(light);
            this._sdControlSettings.settings[light.id] = setting;
            this._factory.createShapeDiverSetting(setting, this._factory.groups.getGroupByIndex(setting.groupId));
            this._controlsEvents.trigger("add" /* ADD */, this);
        }
        ;
    };
    LoadLightSceneDropdownSettingComponent.prototype.onLightsSceneUpdated = function () {
        var scenes = this._api.scene.lights.getAllLightScenes().data;
        var current = this._api.scene.lights.getLightScene().data.id;
        this._component.updateOptions(scenes, current);
    };
    return LoadLightSceneDropdownSettingComponent;
}(DropdownSettingComponent_1.DropdownSettingComponent));
exports.LoadLightSceneDropdownSettingComponent = LoadLightSceneDropdownSettingComponent;
