/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *SettingsMixin.js*
 *
 * ### Content
 *   * mixin containing basic functionality for settings
 *   * initializing settings with default values
 *   * querying settings
 *   * changing settings
 *   * saving settings
 *
 * @module SettingsMixin
 * @requires module:GlobalUtils
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Global utilities
 */
var GlobalUtils = require('../util/GlobalUtils');

/**
 * Message prototype
 */
var MessagePrototype = require('../messages/MessagePrototype');

/**
 * Messaging constants
 */
var messagingConstants = require('../constants/MessagingConstants');

/**
 * Definition of the constructor of the settings mixin
 * @mixin SettingsMixin
 * @author Alex Schiftner <alex@shapediver.com>
 * @requires module:GlobalUtils
 *
 * @param {Object} [settings] - Initial settings to be used
 * @param {Object} [defaultSettings] - Default settings to be used
 * @param {String} [namespace] - Optional namespace to define for these settings, if defined this namespace will be used to notify about settings updates by messages
 * @param {String[]} [assign] - Names of settings which should not be copied but assigned
 */
var SettingsMixin = function(___settings, ___defaultSettings, ___namespace, ___assign) {

  var that = this;

  let _logger = typeof that.warn === 'function' ? that.warn : () => {};

  ////////////
  ////////////
  //
  // Settings
  //
  ////////////
  ////////////

  // make sure settings is an object
  if ( ___settings === undefined || typeof ___settings !== 'object' )
    ___settings = {};
  if ( ___defaultSettings === undefined || typeof ___defaultSettings !== 'object' )
    ___defaultSettings = {};

  // create a private copy of the settings
  var _settings = GlobalUtils.deepCopy(___settings, ___assign);

  // ensure all default settings exist in our private copy, set default values for missing ones
  GlobalUtils.defaults(_settings, ___defaultSettings);

  /**
   * Provide a copy of all settings, or for an array of keys
   * @public
   * @param {String[]} [keys] - optional array of keys to return (keys which don't exist are ignored)
   * @return {Object} copied settings object
   */
  this.getSettings = function(keys) {
    // in case no keys have been specified, return all settings
    if ( keys === undefined || !Array.isArray(keys) ) {
      return GlobalUtils.deepCopy(_settings);
    }
    var settings = {};
    keys.forEach( function(key) {
      if ( that.hasSetting(key) ) {
        GlobalUtils.forceAtPath(settings, key, that.getSetting(key));
      }
    });
    return settings;
  };

  /**
   * Provide a copy of an individual setting
   * @public
   * @param {String} key - name of setting
   * @return {Object} copied setting, undefined if not found
   */
  this.getSetting = function(key) {
    return GlobalUtils.deepCopy(GlobalUtils.getAtPath(_settings, key));
  };

  /**
   * Provide a shallow copy of an individual setting - USE RESPONSIBLY
   * @public
   * @param {String} key - name of setting
   * @return {Object} setting
   */
  this.getSettingShallow = function(key) {
    return GlobalUtils.getAtPath(_settings, key);
  };

  /**
   * Check for existence of an individual setting
   * @public
   * @param {String} key - name of setting
   * @return {Boolean}
   */
  this.hasSetting = function(key) {
    return GlobalUtils.getAtPath(_settings, key) !== undefined;
  };

  // keep a list of hook functions to be used for value checking
  var _hooks = {};

  // number of last hook function registered
  var _hooknum = 0;

  /**
   * Allow to register functions to be used for value checking before updating a setting (hook).
   *
   * The function is called before updating a setting, and must return true to accept the value update.
   * Multiple hooks may be registered for the same setting, all of them must return true to accept the update.
   * If the function does not return true, updating the setting is refused.
   * Functions are called using the following parameters (new value of setting, name of setting).
   * Current value of the setting can be read using getSetting/getSettingShallow if required.
   * @public
   * @see {module:SettingsMixin:deregisterHook}
   * @param {String|RegExp} [key='.*'] - name of setting to install the hook for, may be empty in which case the hook applies for all settings
   * @param {Function} hook - hook to be called
   * @return {Number} Unique handle for hook on success (can be used to deregister the hook), undefined on error
   */
  this.registerHook = function(key, hook) {
    if ( !key ) key = '.*';
    if ( typeof key === 'string' ) {
      // if key is a string, make a RegExp from it
      if ( !key.startsWith('^') )
        key = '^' + key;
      if ( !key.endsWith('$') )
        key = key + '$';
      key = RegExp(key);
    } else if ( !(key instanceof RegExp) ) {
      return;
    }
    if ( typeof hook !== 'function' ) return;
    let hkn = _hooknum;
    _hooks[_hooknum++] = {r: key, cb: hook};
    return hkn;
  };

  /**
   * Deregister a hook
   *
   * @public
   * @see {module:SettingsMixin:registerHook}
   * @param {Number} hkn - number of hook to deregister
   * @return {Boolean} true if hook was deregistered successfully, false otherwise
   */
  this.deregisterHook = function(hkn) {
    if ( !_hooks.hasOwnProperty(hkn) ) return false;
    delete _hooks[hkn];
    return true;
  };

  /**
   * Deregisters all hooks
   */
  this.deregisterAllHooks = function() {
    for(let hkn in _hooks)
      delete _hooks[hkn];
    return true;
  };

  
  // keep a list of typeCheck functions to be used for value checking
  var _typeChecks = {};

  // number of last typeCheck function registered
  var _typeChecknum = 0;

  /**
   * Allow to register functions to be used for value checking before updating a setting and before checking the hooks (typeCheck).
   *
   * The function is called before updating a setting, and must return true to accept the value update.
   * Multiple typeChecks may be registered for the same setting, all of them must return true to accept the update.
   * If the function does not return true, updating the setting is refused.
   * Functions are called using the following parameters (new value of setting, name of setting).
   * Current value of the setting can be read using getSetting/getSettingShallow if required.
   * @public
   * @see {module:SettingsMixin:deregisterTypeCheck}
   * @param {String|RegExp} [key='.*'] - name of setting to install the typeCheck for, may be empty in which case the typeCheck applies for all settings
   * @param {Function} typeCheck - typeCheck to be called
   * @return {Number} Unique handle for typeCheck on success (can be used to deregister the typeCheck), undefined on error
   */
  this.registerTypeCheck = function(key, typeCheck) {
    if ( !key ) key = '.*';
    if ( typeof key === 'string' ) {
      // if key is a string, make a RegExp from it
      if ( !key.startsWith('^') )
        key = '^' + key;
      if ( !key.endsWith('$') )
        key = key + '$';
      key = RegExp(key);
    } else if ( !(key instanceof RegExp) ) {
      return;
    }
    if ( typeof typeCheck !== 'function' ) return;
    let tcn = _typeChecknum;
    _typeChecks[_typeChecknum++] = {r: key, cb: typeCheck};
    return tcn;
  };

  /**
   * Deregister a typeCheck
   *
   * @public
   * @see {module:SettingsMixin:registerTypeCheck}
   * @param {Number} tcn - number of typeCheck to deregister
   * @return {Boolean} true if typeCheck was deregistered successfully, false otherwise
   */
  this.deregisterTypeCheck = function(tcn) {
    if ( !_typeChecks.hasOwnProperty(tcn) ) return false;
    delete _typeChecks[tcn];
    return true;
  };

  /**
   * Deregisters all typeChecks
   */
  this.deregisterAllTypeChecks = function() {
    for(let tcn in _typeChecks)
      delete _typeChecks[tcn];
    return true;
  };

  // keep a list of notifier functions to be invoked after setting updates
  var _notifiers = {};

  // number of last notifier function registered
  var _notifiernum = 0;

  /**
   * Allow to register functions to be invoked after updates to a setting (notifiers).
   *
   * The function is called after updating a setting, and has no influence on the update,
   * regardless of its return value and whether it throws an exception.
   * Multiple notifiers may be registered for the same setting.
   * Functions are called using the following parameters (name of setting, old value of setting, new value of setting).
   * @public
   * @see {module:SettingsMixin:deregisterNotifier}
   * @param {String|RegExp} [key='.*'] - name of setting to install the notifier for, may be empty in which case the notifier applies for all settings
   * @param {Function} notifier - notifier to be called
   * @return {Number} Unique handle for notifier on success (can be used to deregister the notifier), undefined on error
   */
  this.registerNotifier = function(key, notifier) {
    if ( !key ) key = '.*';
    if ( typeof key === 'string' ) {
      // if key is a string, make a RegExp from it
      if ( !key.startsWith('^') )
        key = '^' + key;
      if ( !key.endsWith('$') )
        key = key + '$';
      key = RegExp(key);
    } else if ( !(key instanceof RegExp) ) {
      return;
    }
    if ( typeof notifier !== 'function' ) return;
    let nfn = _notifiernum;
    _notifiers[_notifiernum++] = {r: key, cb: notifier};
    return nfn;
  };

  /**
   * Deregister a notifier
   *
   * @public
   * @see {module:SettingsMixin:registerNotifier}
   * @param {Number} nfn - number of notifier to deregister
   * @return {Boolean} true if notifier was deregistered successfully, false otherwise
   */
  this.deregisterNotifier = function(nfn) {
    if ( !_notifiers.hasOwnProperty(nfn) ) return false;
    delete _notifiers[nfn];
    return true;
  };

  /**
   * Deregisters all notifiers
   */
  this.deregisterAllNotifiers = function() {
    for(let nfn in _notifiers)
      delete _notifiers[nfn];
    return true;
  };

  /**
   * Force update of an individual setting, bypassing hooks and notifiers
   * @param {String} key - name of setting
   * @param {Object} val - new value of setting
   * @return {Boolean} true if setting could be changed, false if not
   */
  this.forceSetting = function(key, val) {
    GlobalUtils.forceAtPath(_settings, key, GlobalUtils.deepCopy(val));
    return true;
  };

  /**
   * Update an individual setting, if allowed according to typeChecks and hooks
   *
   * Before updating the setting, checks if matching typeChecks and hooks have been defined and, in case they exist, return true (all of them).
   * If they do not return true, refuse updating the setting.
   *
   * After updating the setting, checks if matching notifiers have been defined and, in case they exist, calls all of the matching ones.
   *
   * @public
   * @see {module:SettingsMixin:registerTypeCheck}
   * @see {module:SettingsMixin:registerHook}
   * @see {module:SettingsMixin:registerNotifier}
   * @param {String} key - name of setting
   * @param {Object} val - new value of setting
   * @return {Boolean} true if setting could be changed, false if not
   */
  this.updateSetting = function(key, val) {
    let scope = 'SettingsMixin.updateSetting';
    
    // check for typeChecks, run them if they match
    let typeCheckIndices = Object.keys(_typeChecks);
    let a = typeCheckIndices.every((idx) => {
      let typeCheck = _typeChecks[idx];
      // accept if typeCheck does not match the key
      if (!typeCheck.r.test(key))
        return true;
      // typeCheck matches, run the callback, check its return value
      let rv = typeCheck.cb(val, key);
      if ( rv instanceof Promise ) {
        _logger(scope, 'A typeCheck for ' + key + ' returned a Promise, which is always a truthy value, use SettingsMixin.updateSettingAsync instead for updates to this key');
      }
      return rv;
    });
    if (!a) return false;

    // typeChecks passed, check for hooks, run them if they match
    let hookIndices = Object.keys(_hooks);
    let b = hookIndices.every((idx) => {
      let hook = _hooks[idx];
      // accept if hook does not match the key
      if (!hook.r.test(key))
        return true;
      // hook matches, run the callback, check its return value
      let rv = hook.cb(val, key);
      if ( rv instanceof Promise ) {
        _logger(scope, 'A hook for ' + key + ' returned a Promise, which is always a truthy value, use SettingsMixin.updateSettingAsync instead for updates to this key');
      }
      return rv;
    });
    if (!b) return false;

    // hooks and typeChecks passed, update the setting
    let preval = that.getSetting(key);
    GlobalUtils.forceAtPath(_settings, key, GlobalUtils.deepCopy(val));

    // check for notifiers, run them if they match
    let notifierIndices = Object.keys(_notifiers);
    notifierIndices.forEach((idx) => {
      let notifier = _notifiers[idx];
      // check if notifier matches the key
      if (!notifier.r.test(key))
        return;
      // notifier matches, run the callback, ignore exceptions but log them
      try {
        notifier.cb(key, preval, val);
      }
      catch (e) {
        _logger(scope, 'A notifier for setting ' + notifier.r.toString() + ' threw an exception', e);
      }
    });

    // setting was updated, return true
    return true;
  };

  /**
   * Update an individual setting, if allowed according to typeChecks and hooks, for typeChecks and hooks which return promises.
   *
   * Before updating the setting, checks if matching typeChecks and hooks have been defined and, in case they exist, return true.
   * If they do not return true, refuse updating the setting.
   * @public
   * @see {module:SettingsMixin:registerTypeCheck}
   * @see {module:SettingsMixin:registerHook}
   * @param {String} key - name of setting
   * @param {Object} val - new value of setting
   * @return {Promise} Promise which will resolve to true if setting could be changed, false if not
   */
  this.updateSettingAsync = function (key, val) {
    let scope = 'SettingsMixin.updateSettingAsync';


    // check for typeChecks, run them if they match
    let typeCheckIndices = Object.keys(_typeChecks);
    // collect promises that might be returned by typeChecks
    let promisesTypeChecks = [];
    let a = typeCheckIndices.every((idx) => {
      let typeCheck = _typeChecks[idx];
      // accept if typeCheck does not match the key
      if (!typeCheck.r.test(key))
        return true;
      // typeCheck matches, run the callback, react depending on return type
      let rv = typeCheck.cb(val, key);
      if (rv instanceof Promise) {
        // put into list of Promises to 'wait' for
        promisesTypeChecks.push(rv);
        return true;
      } else {
        return rv;
      }
    });
    // check if we can resolve to false right away
    if (!a) return Promise.resolve(false);

    return Promise.all(promisesTypeChecks).then(
      function (rvs) {
        if (rvs.every((r) => r)) {
          // typeChecks passed, check for hooks, run them if they match
          let hookIndices = Object.keys(_hooks);
          // collect promises that might be returned by hooks
          let promisesHooks = [];
          let b = hookIndices.every((idx) => {
            let hook = _hooks[idx];
            // accept if hook does not match the key
            if (!hook.r.test(key))
              return true;
            // hook matches, run the callback, react depending on return type
            let rv = hook.cb(val, key);
            if (rv instanceof Promise) {
              // put into list of Promises to 'wait' for
              promisesHooks.push(rv);
              return true;
            } else {
              return rv;
            }
          });
          // check if we can resolve to false right away
          if (!b) return Promise.resolve(false);

          // chain up with promises
          return Promise.all(promisesHooks).then(
            function (rvs) {
              if (rvs.every((r) => r)) {
                // hooks passed, update the setting
                let preval = that.getSetting(key);
                GlobalUtils.forceAtPath(_settings, key, GlobalUtils.deepCopy(val));

                // check for notifiers, run them if they match
                let notifierIndices = Object.keys(_notifiers);
                notifierIndices.forEach((idx) => {
                  let notifier = _notifiers[idx];
                  // check if notifier matches the key
                  if (!notifier.r.test(key))
                    return;
                  // notifier matches, run the callback, ignore exceptions but log them
                  try {
                    notifier.cb(key, preval, val);
                  }
                  catch (e) {
                    _logger(scope, 'A notifier for setting ' + notifier.r.toString() + ' threw an exception', e);
                  }
                });

                // setting was updated, return true
                return true;
              }
              return false;
            }, function (/*reason*/) {
              return false;
            }
          );
        }
        return false;
      }, function (/*reason*/) {
        return false;
      }
    );
  };

  /**
   * Update a bunch of settings according to the enumerable properties of an object, if allowed.
   * Use with caution for nested settings if keep===false (keep not specified), as
   * nested settings might be overwritten.
   * @public
   * @param {Object} settings - object whose own properties should be used as settings
   * @param {Boolean} [keep=false] - if true keep existing settings
   * @return {Boolean} true if all settings could be changed, false otherwise
   */
  this.updateSettings = function(settings, keep) {
    if ( typeof keep !== 'boolean' )
      keep = false;
    let success = true;
    if ( settings === undefined || typeof settings !== 'object' )
      return success;
    // do a deep iteration over the settings object here instead of using Object.keys
    let keys = [];
    GlobalUtils.getPaths(settings, keys);
    keys.forEach( function(key) {
      if ( !keep || !that.hasSetting(key) ) {
        if ( !that.updateSetting(key, GlobalUtils.getAtPath(settings, key) ) )
          success = false;
      }
    });
    return success;
  };

  /**
   * Update a bunch of settings according to the enumerable properties of an object, if allowed.
   * Use with caution for nested settings if keep===false (keep not specified), as
   * nested settings might be overwritten.
   * @public
   * @param {Object} settings - object whose own properties should be used as settings
   * @param {Boolean} [keep=false] - if true keep existing settings
   * @return {Promise} Promise which will resolve to a copy of the settings object whose property values are replaced by true or false depending on whether the corresponding setting could be updated
   */
  this.updateSettingsAsync = function() {
    // to be implemented
    let scope = 'SettingsMixin.updateSettingsAsync';
    _logger(scope, 'to be implemented');
    return Promise.reject(new Error(scope + 'to be implemented'));
  };

  /**
   * Check whether a setting is persistent (shall be saved)
   * @public
   * @param {String} key - name of setting
   * @return {Boolean}
   */
  this.isSettingPersistent = function() {
    // #SS-981 to be implemented

    return false;
  };

  /**
   * Get accessor functions for a section of the settings (e.g. camera.*)
   * Typically this is used to give control to a section of the settings to a member.
   * Checks whether the section exists, fails if not.
   * Say 'camera' is given, the following happens:
   *   * checks for exist of 'camera' in the current settings (must be an object)
   *   * creates accessor functions for all function members of {@link module:SettingsMixin} for section 'camera.'
   * @public
   * @param {String} section - section to which access will be given
   * @return {Object} Object containing accessor functions for all function members of {@link module:SettingsMixin}
   */
  this.getSection = function(section) {

    // check if section exists
    let o = that.getSettingShallow(section);
    if ( o === undefined || typeof o !== 'object' )
      return false;

    return {

      getSettings : function(keys) {
        // in case no keys have been specified, return complete section
        if ( keys === undefined || !Array.isArray(keys) ) {
          let s = that.getSettings([section]);
          return GlobalUtils.getAtPath(s, section);
        }
        // otherwise prepend section name to each key
        let _keys = GlobalUtils.deepCopy(keys);
        for (let i=0; i<_keys.length; i++) {
          _keys[i] = section + '.' + _keys[i];
        }
        return that.getSettings(_keys);
      },

      getSetting : function(key) {
        return that.getSetting(section + '.' + key);
      },

      getSettingShallow : function(key) {
        return that.getSettingShallow(section + '.' + key);
      },

      forceSetting : function(key, value) {
        return that.forceSetting(section + '.' + key, value);
      },

      hasSetting : function(key) {
        return that.hasSetting(section + '.' + key);
      },

      registerHook : function(key, hook) {
        // can't deal with RegExp objects in this case
        if ( typeof key !== 'string' ) return false;
        // remove leading ^
        if ( key.startsWith('^') ) key = key.substring(1);
        // remember length of section, so we can trim k in the callback
        let len = section.length + 1;
        // define callback
        let h = function(v, k) {
          return hook(v, k.substring(len));
        };
        return that.registerHook(section + '.' + key, h);
      },

      deregisterHook : function(hkn) {
        return that.deregisterHook(hkn);
      },

      registerNotifier : function(key, notifier) {
        // can't deal with RegExp objects in this case
        if ( typeof key !== 'string' ) return false;
        // remove leading ^
        if ( key.startsWith('^') ) key = key.substring(1);
        // remember length of section, so we can trim k in the callback
        let len = section.length + 1;
        // define callback
        let h = function(k, ov, nv) {
          return notifier(k.substring(len), ov, nv);
        };
        return that.registerNotifier(section + '.' + key, h);
      },

      deregisterNotifier : function(nfn) {
        return that.deregisterNotifier(nfn);
      },

      updateSetting : function(key, val) {
        return that.updateSetting(section + '.' + key, val);
      },

      updateSettingAsync : function(key, val) {
        return that.updateSettingAsync(section + '.' + key, val);
      },

      updateSettings : function(settings, keep) {
        if ( typeof keep !== 'boolean' )
          keep = false;
        let success = true;
        if ( settings === undefined || typeof settings !== 'object' )
          return success;
        // do a deep iteration over the settings object here instead of using Object.keys
        let keys = [];
        GlobalUtils.getPaths(settings, keys);
        keys.forEach( function(key) {
          var k = section + '.' + key;
          if ( !keep || !that.hasSetting(k) ) {
            if ( !that.updateSetting(k, GlobalUtils.getAtPath(settings, key) ) )
              success = false;
          }
        });
        return success;
      },

      updateSettingsAsync : function() {
        // to be implemented
        let scope = 'SettingsMixin.updateSettingsAsync';
        _logger(scope, 'to be implemented');
        return Promise.reject(new Error(scope + 'to be implemented'));
      },

      isSettingPersistent : function(key) {
        // to be implemented
        return that.isSettingPersistent(section + '.' + key);
      },

      getSection : function(_section) {
        return that.getSection(section + '.' + _section);
      }

    };
  };

  /**
   * In case a namespace has been defined, use it to broadcast messages whenever a setting gets updated
   */
  if (GlobalUtils.typeCheck(___namespace, 'string')) {
    that.registerNotifier('', function(key, valueOld, valueNew) {
      let settingsUpdateMsg = {key: key, valueNew: valueNew, valueOld: valueOld, namespace: ___namespace};
      let m = new MessagePrototype(messagingConstants.messageDataTypes.SETTINGS_UPDATE, settingsUpdateMsg);
      that.message(messagingConstants.messageTopics.SETTINGS_UPDATE, m);
    });
  }

  return this;
};

// here we could define public functions
//SettingsMixin.prototype.

// export the constructor
module.exports = SettingsMixin;
