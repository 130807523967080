/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ViewerAppConstants.js*
 *
 * ### Content
 *   * definition of constants used by the ViewerApp (logging levels, default settings)
 *
 * @module ViewerAppConstants
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Import globally defined logging constants
 */
var loggingConstants = require('../shared/constants/LoggingConstants');

/**
 * Use globally defined logging levels
 */
var loggingLevels = loggingConstants.loggingLevels;

/**
 * Import globally defined messaging constants
 */
var messagingConstants = require('../shared/constants/MessagingConstants');

/**
 * Use globally defined messaging topics
 */
var messageTopics = messagingConstants.messageTopics;

/**
 * Use globally defined messaging data types
 */
var messageDataTypes = messagingConstants.messageDataTypes;

/**
 * Default material
 */
const DEFAULT_MATERIAL = {
  'name': 'Default material',
  'version': '2.0',
  'bumpAmplitude': 1,
  'color': [
    211,
    211,
    211,
    255
  ],
  'metalness': 0,
  'roughness': 1
};

/**
 * Use material attribute object
 */
//var MaterialAttributes = require('../shared/materials/MaterialAttributes');

/**
 * Scene initialisation mode
 * @typedef {Number} ShowSceneMode
 */

/**
 * Possible modes for showing the 3D scene
 *
 * This defines when to hide the loading screen, and show the 3D scene
 *
 * @typedef {Object} ShowSceneModes
 * @property {ShowSceneMode} INSTANT - show scene immediately once instance of ViewerApp gets created, typically empty
 * @property {ShowSceneMode} ON_SHOW - show scene once {@link ViewerApp#showScene} is called
 * @property {ShowSceneMode} ON_FIRST_PLUGIN - show scene once first plugin reports ready for displaying geometry
 * @property {ShowSceneMode} ON_ALL_PLUGINS - show scene once all registered plugins report ready for displaying geometry
 */
var showSceneModes = {
  INSTANT: 0,
  ON_SHOW: 1,
  ON_FIRST_PLUGIN: 2,
  ON_ALL_PLUGINS: 3
};

/**
 * Definition of default settings for the viewer app
 */
var defaultSettings = {
  blurSceneWhenBusy: true,
  defaultMaterial: DEFAULT_MATERIAL, // used by sceneManager
  ignoreSuperseded: true, // used by sceneManager
  hasRestoredSettings: false,
  loggingLevel: loggingLevels.NONE,
  messageLoggingLevel: loggingLevels.NONE,
  showMessages: true,
  commitParameters: false,
  commitSettings: false,
  showScene: false, // used by viewportVisibilityHandler
  showSceneMode: showSceneModes.ON_FIRST_PLUGIN, // used by viewportVisibilityHandler
  showSceneTransition: '1s', // used by viewportVisibilityHandler
  strictMode: false,
  useModelSettings: true, // used by SettingsHandler
  ar: {
    enableCameraSync: false,
    enableCameraSyncInitial: false,
    enableLightingEstimation: true,
    enableTouchControls: true,
    enableTouchControlRotation: true,
    enableAutomaticPlacement: true,
    defaultHitTestType: 'existingPlaneUsingGeometry',
  }
};

/**
 * Collection of all constants
 */
var viewerAppConstants = {
  loggingLevels: loggingLevels,
  messageTopics: messageTopics,
  messageDataTypes: messageDataTypes,
  showSceneModes: showSceneModes,
  defaultSettings: defaultSettings,
};

/**
 * Import GlobalUtils
 */
var GlobalUtils = require('../shared/util/GlobalUtils');

/**
 * Export a deep copy of the constants
 */
module.exports = GlobalUtils.deepCopy(viewerAppConstants);
