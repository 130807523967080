/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ParameterInterface.js*
 *
 * ### Content
 *   * Interface for all parameters recognized by the ViewerApp
 *
 * @module ParameterInterface
 * @author Mathias Höbinger <mathias@shapediver.com>
 */


/**
 * Include the exception for reporting missing implementations.
 */
var MissingImplementationException = require('../exceptions/MissingImplementationException');


/**
 * Interface for classes that represent a parameter
 *
 * @interface ParameterInterface
 */
var ParameterInterface = function() {

  ////////////
  ////////////
  //
  // Parameter API
  //
  ////////////
  ////////////

  /**
   * Set new parameter value
   * @param  {*} value - The new parameter value
   * @return {Boolean}         True if new value is valid and could be set
   */
  this.setValue = function() {
    throw new MissingImplementationException();
  };

  /**
   * Set parameter value to new value from a compatible string representation
   * @param  {String} value - The new parameter value represented by a string
   * @return {Boolean}      True if new value is valid and could be set
   */
  this.parseValue = function() {
    throw new MissingImplementationException();
  };

  /**
   * Retrieves the current parameter value.
   * @return {*} The current parameter value
   */
  this.getValue = function() {
    throw new MissingImplementationException();
  };

  /**
   * Retrieves the current parameter value as a properly formatted string
   * @return {String} String representation of the current value
   */
  this.getValueString = function() {
    throw new MissingImplementationException();
  };

  /**
   * Checks if given value qualifies as a valid parameter value.
   * @param  {*} value - value to check
   * @return {Boolean}   True if value is a valid parameter value.
   */
  this.checkValue = function() {
    throw new MissingImplementationException();
  };

  /**
   * Checks if the parameter definition is valid.
   * Essentially we use this to check whether the settings used for construction of a parameter were valid,
   * and the resulting parameter object is usable.
   * @return {Boolean}   True if parameter object was constructed successfully.
   */
  this.isValid = function() {
    throw new MissingImplementationException();
  };

  /**
   * Get definition of paramater
   * @return {module:JSONParameter~JSONParameter}   Definition of parameter if available, undefined otherwise
   */
  this.getDefinition = function() {
    throw new MissingImplementationException();
  };

};

// export the constructor
module.exports = ParameterInterface;
