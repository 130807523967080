"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * The generic event.
 */
var GenericEvent = /** @class */ (function () {
    function GenericEvent(events) {
        var _this = this;
        if (events === void 0) { events = []; }
        this.handlers = {};
        events.forEach(function (e) { return _this.handlers[e] = []; });
    }
    ;
    GenericEvent.prototype.on = function (id, handler) {
        if (!this.handlers[id]) {
            this.handlers[id] = [];
        }
        this.handlers[id].push(handler);
    };
    GenericEvent.prototype.off = function (id, handler) {
        this.handlers[id] = this.handlers[id].splice(0).filter(function (h) { return h !== handler; });
    };
    GenericEvent.prototype.clear = function (id) {
        this.handlers[id] = [];
    };
    GenericEvent.prototype.trigger = function (id, data) {
        if (!this.handlers[id]) {
            this.handlers[id] = [];
        }
        this.handlers[id].forEach(function (h) { return h(data); });
    };
    return GenericEvent;
}());
exports.GenericEvent = GenericEvent;
