"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ModalSettingComponent_1 = require("../ModalSettingComponent");
var HTMLSettingsHelper_1 = require("../../settings/HTMLSettingsHelper");
var AddLightModalSetting = /** @class */ (function (_super) {
    __extends(AddLightModalSetting, _super);
    // #endregion Properties (3)
    // #region Constructors (1)
    function AddLightModalSetting(api, settingDefinition, _parent, componentFactory, _controlsSettings, _events, _shapeDiverDragControls) {
        var _this = _super.call(this, api, {
            type: "modal" /* MODAL */,
            id: settingDefinition.id,
            order: settingDefinition.order,
            name: "Add Light",
            title: "Add a new light source to the scene.",
            groupId: settingDefinition.groupId,
        }, _parent, componentFactory) || this;
        _this._parent = _parent;
        _this._controlsSettings = _controlsSettings;
        _this._events = _events;
        _this._shapeDiverDragControls = _shapeDiverDragControls;
        // #region Properties (3)
        _this._modalConfirmId = "jq-add-light-modal-confirm";
        _this._typeId = "jq-add-light-type";
        _this.component.content = _this.getModalString();
        _this.bindLightTypeChangeEvent();
        return _this;
    }
    // #endregion Constructors (1)
    // #region Public Methods (2)
    AddLightModalSetting.prototype.customUpdate = function () {
        var name = document.getElementById("jq-add-light-name").value;
        var alertHtml = document.getElementById("jq-add-light-alert");
        if (!name) {
            alertHtml.innerHTML = HTMLSettingsHelper_1.HTMLSettingsHelper.createAlert("Please specify a name for the new light");
            return;
        }
        else {
            alertHtml.innerHTML = "";
        }
        var type = (document.getElementById("jq-add-light-type")).value;
        name = this.resolveNameIfDuplicate(name);
        var lightParams = {
            id: name,
            type: this._api.scene.lights.LIGHTTYPE[type],
            properties: {}
        };
        this._api.scene.lights.updateLight(lightParams);
        var light = this._api.scene.lights.getLight(name).data;
        var setting = this._controlsSettings.createLightSetting(light);
        this._controlsSettings.settings[light.id] = setting;
        var componentSetting = this._componentFactory.createShapeDiverSetting(setting, this._parent);
        this.component.close();
        // open accordion
        componentSetting.open();
        this._shapeDiverDragControls.makeLightDraggableAndZoomAsync(light.id);
        // add small timeout due to animation of modal closing
        window.setTimeout(this.setNameFieldAccordingToSelectedType.bind(this), 2000);
    };
    // #endregion Public Methods (2)
    // #region Private Methods (4)
    AddLightModalSetting.prototype.bindLightTypeChangeEvent = function () {
        var _this = this;
        this.component.onOpen(function () { return _this.setNameFieldAccordingToSelectedType(); });
        this.setNameFieldAccordingToSelectedType();
        document.getElementById(this._modalConfirmId).onclick = function () { return _this.customUpdate(); };
        document.getElementById(this._typeId).onchange = function () { return _this.setNameFieldAccordingToSelectedType(); };
    };
    AddLightModalSetting.prototype.getModalString = function () {
        return "\n        <div class=\"uk-modal-dialog uk-modal-body\">\n\n        <h2 class=\"uk-modal-title\">Add Light</h2>\n\n          <form class=\"uk-form-horizontal\" onsubmit=\"return false\"> \n            <div class=\"uk-margin\">\n              <label class=\"uk-form-label\" for=\"form-horizontal-select\">Type:</label>\n              <div class=\"uk-form-controls\">\n                  <select class=\"uk-select\" id=\"" + this._typeId + "\">\n                      " + Object.keys(this._api.scene.lights.LIGHTTYPE).map(function (x) { return "<option>" + x + "</option>"; }).join("") + "\n                  </select>\n              </div>\n            </div>\n            <div class=\"uk-margin\">\n              <label class=\"uk-form-label\" for=\"form-horizontal-text\">Name:</label>\n              <div class=\"uk-form-controls\">\n                  <input id=\"jq-add-light-name\" class=\"uk-input\" type=\"text\" placeholder=\"type name ...\">\n              </div>\n            </div>\n            <p class=\"uk-text-right\">\n              <button class=\"uk-button uk-button-default uk-modal-close\" type=\"button\">Cancel</button>\n              <input id=\"jq-add-light-modal-confirm\" class=\"uk-button uk-button-default sd-control-button\" type=\"submit\" value=\"save\">\n              <div id=\"jq-add-light-alert\">\n\n              </div>\n            </p>\n          </form>\n        </div>";
    };
    AddLightModalSetting.prototype.resolveNameIfDuplicate = function (name) {
        var lightScene = this._api.scene.lights.getLightScene().data;
        // check for dupe id
        if (lightScene.lights[name]) {
            var dupeId = 1;
            while (this._controlsSettings.settings[name + ("_" + dupeId)]) {
                dupeId++;
            }
            name += "_" + dupeId;
        }
        return name;
    };
    AddLightModalSetting.prototype.setNameFieldAccordingToSelectedType = function () {
        var lightTypeElem = document.getElementById("jq-add-light-type");
        document.getElementById("jq-add-light-name").value = this.resolveNameIfDuplicate(lightTypeElem.value);
    };
    return AddLightModalSetting;
}(ModalSettingComponent_1.ModalSettingComponent));
exports.AddLightModalSetting = AddLightModalSetting;
