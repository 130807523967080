"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var ChecklistCustomComponent = /** @class */ (function (_super) {
    __extends(ChecklistCustomComponent, _super);
    // #endregion Properties (4)
    // #region Constructors (1)
    function ChecklistCustomComponent(id, parameterDefinition, parent, domManager, queue, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, "checklist" /* CHECKLIST */, id, parameterDefinition, parent, domManager, queue, editMode) || this;
        // #region Properties (4)
        _this._choices = [];
        _this._inputComponents = [];
        _this._currentValues = [];
        _this._choices = _this._parameterDefinition.choices;
        _this._initalValue = _this._parameterDefinition.value ? _this._parameterDefinition.value.map(function (x) { return x; }) : [];
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(ChecklistCustomComponent.prototype, "inputComponent", {
        // #endregion Constructors (1)
        // #region Public Accessors (4)
        get: function () {
            return this._form;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChecklistCustomComponent.prototype, "size", {
        get: function () {
            return 2 /* HALF_WIDTH */;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChecklistCustomComponent.prototype, "value", {
        get: function () {
            return this._inputComponents.filter(function (x) { return x.checked; }).map(function (x) { return x.value; });
        },
        set: function (val) {
            var _this = this;
            this._currentValues = val.map(function (x) { return x; });
            this._inputComponents.forEach(function (ic) { return ic.checked = _this._currentValues.some(function (x) { return x == ic.value; }); });
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (4)
    // #region Public Methods (1)
    ChecklistCustomComponent.prototype.isCurrentValueSameAsInital = function () {
        var va = this.value;
        if (va.length !== this._initalValue.length) {
            return false;
        }
        for (var i = 0; i < va.length; i++) {
            if (!this._initalValue.includes(va[i])) {
                return false;
            }
        }
        return true;
    };
    // #endregion Public Methods (1)
    // #region Protected Methods (3)
    ChecklistCustomComponent.prototype.createInput = function () {
        this._form = this._domManager.createDomElement("form", this.id + "-form", this._inputContainer, ["sdv-control-panel-control", "sdv-control-panel-dropdown-input-form"]);
        for (var i = 0; i < this._choices.length; i++) {
            var choice = this._choices[i];
            // create the div dom element for a choice
            var listContainer = this._domManager.createDomElement("div", null, this._form);
            var rLabel = this._domManager.createDomElement("label", this.id + "-label-" + i, listContainer, ["sdv-control-panel-checklist-input-checkbox-label"]);
            rLabel.innerHTML = choice;
            // create the checkbox dom element for a choice
            var id = this.id + "-checkbox-" + i;
            var inputCheckbox = this._domManager.createDomElement("input", id, rLabel, [], { type: "checkbox", name: id });
            inputCheckbox.innerHTML = choice;
            inputCheckbox.value = choice;
            // set to checked if it is in the default values
            inputCheckbox.checked = this._initalValue.includes(choice);
            if (inputCheckbox.checked) {
                this._currentValues.push(choice);
            }
            // create the checkbox helper dom element for a choice
            this._domManager.createDomElement("div", this.id + "-checkbox-helper", rLabel, ["sdv-control-panel-checklist-input-boxhelper"], { for: id });
            this._inputComponents.push(inputCheckbox);
        }
    };
    ChecklistCustomComponent.prototype.createInputEvents = function () {
        for (var i = 0; i < this._inputComponents.length; i++) {
            this._domManager.setDomEventListener(this._inputComponents[i], "change", 10, this.update.bind(this));
        }
    };
    ChecklistCustomComponent.prototype.update = function (v, event) {
        if (this._currentValues.includes(v)) {
            this._currentValues.splice(this._currentValues.indexOf(v), 1);
        }
        else if (!Array.isArray(v)) {
            this._currentValues.push(v);
        }
        _super.prototype.update.call(this, this._currentValues, event);
    };
    return ChecklistCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.ChecklistCustomComponent = ChecklistCustomComponent;
