
const GLOBAL_UTILS = require('../shared/util/GlobalUtils');

class ArSettingsHooks {

    constructor(ctx) {
        this._settings = ctx.settings;
        this._loggingHandler = ctx.loggingHandler;
        this._viewportManager = ctx.viewportManager;
        this._container = ctx.container;

        this.onSettingChange = null;

        this.getEnableCameraSyncVal = null;
        this.getEnableLightingEstimationVal = null;
        this.getEnableTouchControlsVal = null;
        this.getEnableTouchControlRotationVal = null;
        this.getEnableAutomaticPlacementVal = null;
        this.getDefaultHitTestTypeVal = null;

        const settings = this._settings.getSettings();

        const updateSetting = (s, v) => {
            const valid = this[s](v);
            if (this.onSettingChange && valid) {
                this.onSettingChange(s, v);
            }
            return valid;
        }

        this.enableCameraSync(settings.enableCameraSync);
        this._settings.registerHook('enableCameraSync', v => updateSetting('enableCameraSync', v));

        // check these settings
        this.enableLightingEstimation(settings.enableLightingEstimation);
        this._settings.registerHook('enableLightingEstimation', v => updateSetting('enableLightingEstimation', v));

        // check these settings
        this.enableTouchControls(settings.enableTouchControls);
        this._settings.registerHook('enableTouchControls', v => updateSetting('enableTouchControls', v));

        this.enableTouchControlRotation(settings.enableTouchControlRotation);
        this._settings.registerHook('enableTouchControlRotation', v => updateSetting('enableTouchControlRotation', v));

        // check these settings
        this.enableAutomaticPlacement(settings.enableAutomaticPlacement);
        this._settings.registerHook('enableAutomaticPlacement', v => updateSetting('enableAutomaticPlacement', v));

        this.defaultHitTestType(settings.defaultHitTestType);
        this._settings.registerHook('defaultHitTestType', v => updateSetting('defaultHitTestType', v));
    }

    enableCameraSync(value) {
        if (!GLOBAL_UTILS.typeCheck(value, 'boolean', this._loggingHandler.warn, 'ArHandler.Hook->enableCameraSync')) return false;

        this.getEnableCameraSyncVal = value;
        return true;
    }

    enableLightingEstimation(value) {
        if (!GLOBAL_UTILS.typeCheck(value, 'boolean', this._loggingHandler.warn, 'ArHandler.Hook->enableLightingEstimation')) return false;

        this.getEnableLightingEstimationVal = value;
        return true;
    }

    enableTouchControls(value) {
        if (!GLOBAL_UTILS.typeCheck(value, 'boolean', this._loggingHandler.warn, 'ArHandler.Hook->enableTouchControls')) return false;

        this.getEnableTouchControlsVal = value;
        return true;
    }

    enableTouchControlRotation(value) {
        if (!GLOBAL_UTILS.typeCheck(value, 'boolean', this._loggingHandler.warn, 'ArHandler.Hook->enableTouchControlRotation')) return false;

        this.getEnableTouchControlRotationVal = value;
        return true;
    }

    enableAutomaticPlacement(value) {
        if (!GLOBAL_UTILS.typeCheck(value, 'boolean', this._loggingHandler.warn, 'ArHandler.Hook->enableAutomaticPlacement')) return false;

        this.getEnableAutomaticPlacementVal = value;
        return true;
    }

    defaultHitTestType(value) {
        if (!GLOBAL_UTILS.typeCheck(value, 'string', this._loggingHandler.warn, 'ArHandler.Hook->defaultHitTestType')) return false;

        this.getDefaultHitTestTypeVal = value;
        return true;
    }
}

module.exports = ArSettingsHooks;
