// including THREE in the closure build process results in a pretty big viewer, because we currently cannot
// run the closure compiler using full optimizations for our viewer
// there we make THREE appear as an external library for google closure
// after compilation we concatenate node_modules/three//build/three.min.js (size roughly 534113) with the viewer
var o;
try {
  // in the browser THREE will already have been loaded at this stage
  o = eval('THREE');
} catch (e) {
  if ( typeof require === 'function' ) {
    // we use eval to require for development, because this require will not be seen by closure
    o = eval('require(\'three\')');
  }
}
module.exports = o;

// OR, include three directly
//module.exports = require('three');
