"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DragControlsCache = /** @class */ (function () {
    // #endregion Properties (4)
    // #region Constructors (1)
    function DragControlsCache(_apiWrapper) {
        this._apiWrapper = _apiWrapper;
        // #region Properties (4)
        this._anchors = {};
        this._geometry = {};
        this._obj = {};
        this._svgs = {};
    }
    // #endregion Constructors (1)
    // #region Public Methods (7)
    DragControlsCache.prototype.clear = function (svg, anchors, geometry) {
        var _this = this;
        if (svg) {
            Object.keys(this._svgs).forEach(function (k) {
                document.getElementById(_this._svgs[k].id).remove();
                delete _this._svgs[k];
            });
        }
        if (geometry || anchors) {
            var f = function (a) {
                Object.keys(a).forEach(function (k) {
                    _this._apiWrapper.removeAsync({ id: a[k].id });
                    delete a[k];
                });
            };
            if (geometry)
                f(this._geometry);
            if (anchors)
                f(this._anchors);
        }
    };
    DragControlsCache.prototype.clearWithKey = function (key, svg, anchors, geometry) {
        var _this = this;
        if (geometry || anchors) {
            var f = function (a) {
                _this._apiWrapper.removeAsync({ id: key });
                delete a[key];
            };
            if (geometry)
                f(this._geometry);
            if (anchors)
                f(this._anchors);
        }
        if (svg) {
            delete this._svgs[key];
        }
    };
    DragControlsCache.prototype.get = function (light, property) {
        var key = this.getLightDefPropertyKey(light, property);
        return this.getFromKey(key);
    };
    DragControlsCache.prototype.getAllAsArray = function (svg, anchors, geometry) {
        var array = [];
        var f = function (a) { return Object.keys(a).forEach(function (k) { return array.push(a[k]); }); };
        if (svg) {
            f(this._svgs);
        }
        if (anchors) {
            f(this._anchors);
        }
        if (geometry) {
            f(this._geometry);
        }
        return array;
    };
    DragControlsCache.prototype.getFromKey = function (key) {
        this._obj.anchor = this._anchors[key];
        this._obj.svg = this._svgs[key];
        this._obj.geometry = this._geometry[key];
        return this._obj;
    };
    DragControlsCache.prototype.put = function (light, property, svg, anchor, geometry) {
        var key = this.getLightDefPropertyKey(light, property);
        this.putWithkey(key, svg, anchor, geometry);
    };
    DragControlsCache.prototype.putWithkey = function (key, svg, anchor, geometry) {
        if (svg) {
            this._svgs[key] = svg;
        }
        if (anchor) {
            this._anchors[key] = anchor;
        }
        if (geometry) {
            this._geometry[key] = geometry;
        }
    };
    // #endregion Public Methods (7)
    // #region Private Methods (1)
    DragControlsCache.prototype.getLightDefPropertyKey = function (lightDef, property) {
        return lightDef.type + "_" + lightDef.id + "_" + property;
    };
    return DragControlsCache;
}());
exports.DragControlsCache = DragControlsCache;
