"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var HTMLSettingsHelper = /** @class */ (function () {
    function HTMLSettingsHelper() {
    }
    HTMLSettingsHelper.createAlert = function (warning) {
        return "<div class=\"uk-alert-danger\" uk-alert>\n              <a class=\"uk-alert-close\" uk-close></a>\n              <p>" + warning + "</p>\n            </div>";
    };
    return HTMLSettingsHelper;
}());
exports.HTMLSettingsHelper = HTMLSettingsHelper;
