"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var WebGLBufferType;
(function (WebGLBufferType) {
    WebGLBufferType[WebGLBufferType["ARRAY_BUFFER"] = 34962] = "ARRAY_BUFFER";
    WebGLBufferType[WebGLBufferType["ELEMENT_ARRAY_BUFFER"] = 34963] = "ELEMENT_ARRAY_BUFFER";
})(WebGLBufferType = exports.WebGLBufferType || (exports.WebGLBufferType = {}));
;
var GLTF2AccessorType;
(function (GLTF2AccessorType) {
    GLTF2AccessorType["SCALAR"] = "SCALAR";
    GLTF2AccessorType["VEC2"] = "VEC2";
    GLTF2AccessorType["VEC3"] = "VEC3";
    GLTF2AccessorType["VEC4"] = "VEC4";
    GLTF2AccessorType["MAT2"] = "MAT2";
    GLTF2AccessorType["MAT3"] = "MAT3";
    GLTF2AccessorType["MAT4"] = "MAT4";
})(GLTF2AccessorType = exports.GLTF2AccessorType || (exports.GLTF2AccessorType = {}));
var GLTF2ComponentType;
(function (GLTF2ComponentType) {
    GLTF2ComponentType[GLTF2ComponentType["BYTE"] = 5120] = "BYTE";
    GLTF2ComponentType[GLTF2ComponentType["UNSIGNED_BYTE"] = 5121] = "UNSIGNED_BYTE";
    GLTF2ComponentType[GLTF2ComponentType["SHORT"] = 5122] = "SHORT";
    GLTF2ComponentType[GLTF2ComponentType["UNSIGNED_SHORT"] = 5123] = "UNSIGNED_SHORT";
    GLTF2ComponentType[GLTF2ComponentType["UNSIGNED_INT"] = 5125] = "UNSIGNED_INT";
    GLTF2ComponentType[GLTF2ComponentType["FLOAT"] = 5126] = "FLOAT";
})(GLTF2ComponentType = exports.GLTF2ComponentType || (exports.GLTF2ComponentType = {}));
var GLTF2AlphaMode;
(function (GLTF2AlphaMode) {
    GLTF2AlphaMode["OPAQUE"] = "OPAQUE";
    GLTF2AlphaMode["MASK"] = "MASK";
    GLTF2AlphaMode["BLEND"] = "BLEND";
})(GLTF2AlphaMode = exports.GLTF2AlphaMode || (exports.GLTF2AlphaMode = {}));
