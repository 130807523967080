"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var Vector3CustomComponent = /** @class */ (function (_super) {
    __extends(Vector3CustomComponent, _super);
    // #endregion Properties (13)
    // #region Constructors (1)
    function Vector3CustomComponent(id, parameterDefinition, parent, domManager, queue, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, "vector3" /* VECTOR3 */, id, parameterDefinition, parent, domManager, queue, editMode) || this;
        // #region Properties (13)
        _this._step = 1;
        _this._delay = 500;
        _this._inputContainerClasses = ["uk-width-1-1", "uk-padding-remove", "uk-margin-remove"];
        if (_this._parameterDefinition.step) {
            _this._step = _this._parameterDefinition.step;
        }
        _this._value = { x: 0, y: 0, z: 0 };
        if (_this._parameterDefinition.value) {
            var paramVal = _this._parameterDefinition.value;
            _this._value = { x: paramVal.x || 0, y: paramVal.y || 0, z: paramVal.z || 0 };
        }
        if (typeof _this.parameterDefinition.delay === "number") {
            _this._delay = Math.max(_this._delay, _this.parameterDefinition.delay);
        }
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(Vector3CustomComponent.prototype, "inputComponent", {
        // #endregion Constructors (1)
        // #region Public Accessors (5)
        get: function () {
            return this._inputContainer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Vector3CustomComponent.prototype, "size", {
        get: function () {
            return 1 /* QTR_WIDTH */;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Vector3CustomComponent.prototype, "xInput", {
        get: function () {
            return this._xInput;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Vector3CustomComponent.prototype, "yInput", {
        get: function () {
            return this._yInput;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Vector3CustomComponent.prototype, "zInput", {
        get: function () {
            return this._zInput;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (5)
    // #region Protected Methods (3)
    Vector3CustomComponent.prototype.createInput = function () {
        this._inputContainer.setAttribute("uk-grid", "");
        this._xContainer = this.createContainerElement('x');
        this._yContainer = this.createContainerElement('y');
        this._zContainer = this.createContainerElement('z');
        this._xLabel = this.createLabelElement('x', this._xContainer);
        this._yLabel = this.createLabelElement('y', this._yContainer);
        this._zLabel = this.createLabelElement('z', this._zContainer);
        this._xInput = this.createInputElement('x', this._xContainer);
        this._yInput = this.createInputElement('y', this._yContainer);
        this._zInput = this.createInputElement('z', this._zContainer);
    };
    Vector3CustomComponent.prototype.createInputEvents = function () {
        this._domManager.setDomEventListener(this._xInput, "input", this._delay, this.update.bind(this));
        this._domManager.setDomEventListener(this._yInput, "input", this._delay, this.update.bind(this));
        this._domManager.setDomEventListener(this._zInput, "input", this._delay, this.update.bind(this));
        this._domManager.setDomEventListener(this._xInput, "focusout", 10, this.update.bind(this));
        this._domManager.setDomEventListener(this._yInput, "focusout", 10, this.update.bind(this));
        this._domManager.setDomEventListener(this._zInput, "focusout", 10, this.update.bind(this));
        this._domManager.setDomEventListener(this._xInput, "keyup", 10, this.updateKeyUp.bind(this));
        this._domManager.setDomEventListener(this._yInput, "keyup", 10, this.updateKeyUp.bind(this));
        this._domManager.setDomEventListener(this._zInput, "keyup", 10, this.updateKeyUp.bind(this));
    };
    Vector3CustomComponent.prototype.update = function (v, event) {
        var axis = event.srcElement.getAttribute("data-component");
        this._value[axis] = Number(Number(v).toFixed(this._step));
        _super.prototype.update.call(this, this._value, event);
    };
    // #endregion Protected Methods (3)
    // #region Private Methods (4)
    Vector3CustomComponent.prototype.createContainerElement = function (axis) {
        return this._domManager.createDomElement("div", this.id + "-vector-" + axis + "-container", this._inputContainer, [
            "uk-width-3-3",
            "uk-padding-remove-left",
            "vector3-container",
            "uk-form-custom"
        ]);
    };
    Vector3CustomComponent.prototype.createInputElement = function (axis, container) {
        var attributes = {
            "type": "number",
            "name": this.id + "-number-" + axis,
            "data-component": axis
        };
        if (this._parameterDefinition.step) {
            attributes["step"] = this._parameterDefinition.step;
        }
        if (this._parameterDefinition.min) {
            attributes["min"] = this._parameterDefinition.min;
        }
        if (this._parameterDefinition.max) {
            attributes["max"] = this._parameterDefinition.max;
        }
        var input = this._domManager.createDomElement("input", this.id + "-number-" + axis, container, [
            "uk-input",
            "uk-form-small",
            "sd-input-number",
            "uk-width-2-3"
        ], attributes);
        input.value = this._value[axis];
        return input;
    };
    Vector3CustomComponent.prototype.createLabelElement = function (axis, container) {
        var label = this._domManager.createDomElement("label", this.id + "-vector-" + axis + "-label", container, ['vector3-label'], { for: this.id + "-number-" + axis });
        label.innerHTML = axis.toUpperCase();
        return label;
    };
    Vector3CustomComponent.prototype.updateKeyUp = function (v, event) {
        if (event.which || event.keyCode) {
            if (event.which == 13 || event.keyCode == 13) {
                this.update(v, event);
            }
        }
    };
    return Vector3CustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.Vector3CustomComponent = Vector3CustomComponent;
