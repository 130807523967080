
/**
  * AR status type definition
  * @class {Object} module:ArApiInterface~ArApiInterface#ARStatusType
  * @property {String} framework - framework which provides AR functionality, one of
  *  * 'arkit'
  *  * 'arcore'
  *
  * @property {Object<String, module:ArApiInterface~ArApiInterface#ARAnchor>} [anchors] - anchors currently known, object mapping anchor uuid to anchor
  * @property {String} [tracking] - describes position tracking quality, one of:
  *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate camera tracking status})
  *     * 'notAvailable'
  *     * 'limited'
  *     * 'normal'
  *
  * @property {String} [trackingReason] - describes possible reasons for 'limited' position tracking quality, one of:
  *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arcamera/trackingstate/reason camera tracking reason})
  *     * '' (in case tracking is not 'limited')
  *     * 'initializing'
  *     * 'relocalizing'
  *     * 'excessiveMotion'
  *     * 'insufficientFeatures'
  *
  * @property {String} [worldMapping] - describes how thoroughly the currently visible part of the virtual world has already been mapped, one of:
  *   * for ARKit (see {@link https://developer.apple.com/documentation/arkit/arframe/worldmappingstatus world mapping status})
  *     * 'notAvailable'
  *     * 'limited'
  *     * 'extending'
  *     * 'mapped'
  */
class ArStatusType{
  constructor(framework, anchors, tracking, trackingReason, worldMapping){
    this.framework = framework;
    this.anchors = anchors;
    this.tracking = tracking;
    this.trackingReason = trackingReason;
    this.worldMapping = worldMapping;
  }
}


module.exports = ArStatusType;
