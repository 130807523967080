"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var SDControlsDependenciesResolver = /** @class */ (function () {
    // #endregion Properties (1)
    // #region Constructors (1)
    function SDControlsDependenciesResolver(_useSpectrum) {
        this._useSpectrum = _useSpectrum;
        // #region Properties (1)
        this._dependenciesPromise = null;
        this.resolveDependenciesAsync();
    }
    // #endregion Constructors (1)
    // #region Public Methods (1)
    SDControlsDependenciesResolver.prototype.resolveDependenciesAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (this._dependenciesPromise == null) {
                    // cached
                    this._dependenciesPromise = new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!this._useSpectrum) return [3 /*break*/, 3];
                                    return [4 /*yield*/, this.resolveScript("sdv-jquery", "https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.4/jquery.min.js", "sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44=")];
                                case 1:
                                    _a.sent();
                                    // Purposefully adding get variable to spectrum.js, to work around previously solved problem of CDN caching settings
                                    return [4 /*yield*/, this.resolveScript("sdv-spectrum", "https://viewer.shapediver.com/external/spectrum.js?v=1908130856")];
                                case 2:
                                    // Purposefully adding get variable to spectrum.js, to work around previously solved problem of CDN caching settings
                                    _a.sent();
                                    _a.label = 3;
                                case 3:
                                    resolve(true);
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                }
                return [2 /*return*/, this._dependenciesPromise];
            });
        });
    };
    // #endregion Public Methods (1)
    // #region Private Methods (2)
    SDControlsDependenciesResolver.prototype.resolveLink = function (id, href, integrity) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        var link = document.getElementById(id);
                        if (link == null) {
                            link = document.createElement("link");
                            link.id = id;
                            link.rel = "stylesheet";
                            link.type = "text/css";
                            link.media = "all";
                            link.crossOrigin = "anonymous";
                            if (integrity) {
                                link.integrity = integrity;
                            }
                            link.addEventListener("load", function () {
                                link.setAttribute("loaded", "true");
                                resolve(true);
                            });
                            document.head.appendChild(link);
                            link.href = href; // we set the href AFTER the load event has been registered
                        }
                        if (link.getAttribute("loaded") === "true") {
                            resolve(true);
                        }
                        else {
                            link.addEventListener("load", function () {
                                link.setAttribute("loaded", "true");
                                resolve(true);
                            });
                        }
                    })];
            });
        });
    };
    SDControlsDependenciesResolver.prototype.resolveScript = function (id, src, integrity) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        var script = document.getElementById(id);
                        if (script == null) {
                            script = document.createElement("script");
                            script.id = id;
                            script.crossOrigin = "anonymous";
                            if (integrity) {
                                script.integrity = integrity;
                            }
                            script.addEventListener("load", function () {
                                script.setAttribute("loaded", "true");
                                resolve(true);
                            });
                            document.head.appendChild(script);
                            script.src = src; // we set the src AFTER the load event has been registered
                        }
                        if (script.getAttribute("loaded") == "true") {
                            resolve(true);
                        }
                        else {
                            script.addEventListener("load", function () {
                                script.setAttribute("loaded", "true");
                                resolve(true);
                            });
                        }
                    })];
            });
        });
    };
    return SDControlsDependenciesResolver;
}());
exports.SDControlsDependenciesResolver = SDControlsDependenciesResolver;
