/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *package.js*
 *
 * ### Content
 *   * packaged ShapeDiver 3D Viewer Application
 *   * common plugins for the ShapeDiver 3D Viewer
 *   * browser UI
 *   * iframe embedding helpers
 *
 * @module ParametricViewer
 * @author ShapeDiver <contact@shapediver.com>
 */

/**
* Globally required polyfills
*/
require('../src/polyfills/CustomEvent');

// define constants
const GLOBAL_UTILS = require('../src/shared/util/GlobalUtils'),
      BUILD_DATA = require('./build_data').build_data,
      API_REFLECTOR = require('../src/api/v2/ApiReflectorV2.1');

const RUNNING_IN_BROWSER = GLOBAL_UTILS.runningInBrowser(),
      RUNNING_IN_IFRAME = GLOBAL_UTILS.runningInIframe();

const SETTING_DEFINITIONS = {
  anchorElements: { type: 'boolean', defval: RUNNING_IN_BROWSER },
  authorization: { type: 'string', defval: '' },
  arkitbridge : {type: 'boolean', defval: false},
  brandedMode: { type: 'boolean', defval: true },
  busyGraphic: { type: 'string', defval: null },
  createButtons: { type: 'boolean', defval: true },
  editMode: { type: 'boolean', defval: false },
  commitParameters: { type: 'boolean', defval: false },
  exportModal: { type: 'boolean', defval: true },
  downloadExportButtonName: { type: 'string', defval: 'download' },
  showControlsButton: { type: 'boolean', defval: true },
  showControlsInitial: { type: 'boolean', defval: false },
  showSettingsButton: { type: 'boolean', defval: true },
  showSettingsInitial: { type: 'boolean', defval: false },
  showZoomButton: { type: 'boolean', defval: true },
  zoomButtonResetsCamera: { type: 'boolean', defval: false },
  showFullscreenButton: { type: 'boolean', defval: true },
  showInitialSpinner: { type: 'boolean', defval: true },
  showBusySpinner: { type: 'boolean', defval: true },
  viewportOverlays: { type: 'boolean', defval: true },
  apiversion: { type: 'string', defval: '2' },
  runtimeId: { type: 'string', defval: '' },
  blurSceneWhenBusy: { type: 'boolean', defval: true },
  deferGeometryLoading: { type: 'boolean', defval: false },
  ignoreSuperseded: { type: 'boolean', defval: true },
  exposeViewer: { type: 'boolean', defval: false },
  loggingLevel: { type: 'number', defval: -1 },
  messageLoggingLevel: { type: 'number', defval: -1 },
  iframeDebugging: { type: 'boolean', defval: false },
  modelViewUrl: { type: 'string', defval: '' },
  showSceneMode: { type: 'number', defval: 2 },
  ticket: { type: 'string', defval: '' },
  iframeId: { type: 'string', defval: 'sdv-iframe' },
  commPluginRuntimeId: { type: 'string', defval: 'CommPlugin_1' },
  useModelSettings: { type: 'boolean', defval: true },
};


/**
* Loading the minified ShapeDiver 3D Viewer v2 from `sdv.concat.min.js` defines a global variable `SDVApp` in the browser.
* ```
* <script defer src="https://viewer.shapediver.com/v2/2.1.0/sdv.concat.min.js"></script>
* ```
* @global
* @namespace SDVApp
*/
let ParametricViewer = function () {

  let that = this,
      SDVApp = this;

  /**
  * @typedef {Object} BrowserUserInterfaceApps
  * @property {Function} controls - constructor for the parameter and settings UI
  * @property {Function} overlays - constructor for controller of elements which overlay the viewport (buttons, spinners, loading icons, message boxes)
  * @property {Function} domElements - constructor of controller of HTML elements anchored at 3D positions (text tags)
  * @property {Function} exportModal - constructor of modal export dialog
  */

  /**
  * @typedef {Object} ApiExtensions
  * @property {Function} getApiV1 - returns an object of type {@link module:ApiInterfaceV1~ApiInterfaceV1 ApiInterfaceV1}
  * @property {Function} getApiV2 - returns an object of type {@link module:ApiInterfaceV2~ApiInterfaceV2 ApiInterfaceV2}
  * @property {Object} apps - instantiated browser UI apps
  * @property {Object} apps.controls - parameter UI
  * @property {Object} apps.settings - settings UI
  * @property {Object} apps.overlays - controller of elements which overlay the viewport (buttons, spinners, loading icons, message boxes)
  * @property {Object} apps.domElements - controller of HTML elements anchored at 3D positions (text tags)
  * @property {Object} apps.exportModal - modal export dialog
  */

  /**
  * @typedef {Object} ApiObjectV1
  * @mixes module:ApiInterfaceV1~ApiInterfaceV1
  * @mixes module:ParametricViewer~ApiExtensions
  */

  /**
  * @typedef {Object} ApiObjectV2
  * @mixes module:ApiInterfaceV2~ApiInterfaceV2
  * @mixes module:ParametricViewer~ApiExtensions
  */


  /**
   * Constants which are helpful for defining settings of the {@link SDVApp.ParametricViewer ParametricViewer constructor}
   * @member
   * @memberof SDVApp
   */
  SDVApp.constants = require('../src/app/ViewerAppConstants');

  /**
  * @typedef {Object} PluginData
  * @property {String[]} runtimeIds - runtime ids of plugins which have been instantiated by the {@link SDVApp.ParametricViewer ParametricViewer constructor}
  * @property {Function} CommPlugin - constructor for the ShapeDiver Communication Plugin
  * @property {Function} PluginPrototype - constructor for the ShapeDiver 3D Viewer Plugin prototype
  */

  /**
   * Constructor of the ShapeDiver Communication Plugin, runtime ids of plugins created
   * by the {@link SDVApp.ParametricViewer ParametricViewer constructor}
   *
   * @type {module:ParametricViewer~PluginData}
   * @member
   * @memberof SDVApp
   */
  SDVApp.plugins = { runtimeIds: [] };
  SDVApp.plugins.CommPlugin = require('../src/plugins/comm/CommPlugin');
  SDVApp.plugins.PluginPrototype = require('../src/plugins/PluginPrototype');

  /**
   * Constructors for browser UI apps (make use of the ShapeDiver Viewer API v2)
   *
   * @type {module:ParametricViewer~BrowserUserInterfaceApps}
   * @member
   * @memberof SDVApp
   */
  SDVApp.apps = {};
  SDVApp.apps.controls = require('../src/api/apps/SDControls').default;
  SDVApp.apps.domElements = require('../src/api/apps/DomElementManager');
  SDVApp.apps.overlays = require('../src/api/apps/UIkitViewportOverlays');
  SDVApp.apps.exportModal = require('../src/api/apps/UIkitExportModal');
  SDVApp.apps.snapModule = require('../src/modules/shapediver-snap-module/SnapModule').SnapModule;
  //this.apps.Scanner = require('../src/api/apps/Scanner');

  /**
  * Utility functions
  * @static
  */
  SDVApp.utils = GLOBAL_UTILS;

  /**
  * @typedef {Object} BuildData
  * @property {String} build_version - version of the build, e.g. "2.1.0"
  * @property {String} build_date - date of the build, e.g. "2018-10-09T09:31:41.437Z"
  */

  /**
  * Get build data (version, build date)
  * @function
  * @memberof SDVApp
  * @return {module:ParametricViewer~BuildData}
  */
  SDVApp.getBuildData = function () {
    return GLOBAL_UTILS.deepCopy(BUILD_DATA);
  };

  /**
   * Constructor for the ShapeDiver Viewer Application
   *
   * The constructor accepts a settings object consisting of
   *
   *   * browser user interface settings,
   *   * viewer settings which are passed on to the constructor of the core ShapeDiver Viewer, and
   *   * settings specific to this constructor.
   *
   * In case the viewer is embedded using iframe embed code provided by ShapeDiver, these settings can be
   * passed by means of query string parameters of the iframe URL. As an example append `&showControlsInitial=true`
   * to the iframe's `src` attribute in order to show the parameter controls panel on initialisation.
   *
   * The browser user interface is implemented by means of JavaScript _apps_ making use of the core ShapeDiver Viewer API v2.
   * Browser user interface settings are ignored in case the viewer is not operated inside a browser, e.g. in case it
   * is operated as part of a React Native app.
   *
   * @class
   * @memberof SDVApp
   *
   * @param {Object} settings - settings object, containing browser UI settings, and viewer application settings
   *
   * @param {Boolean} [settings.useQueryStringParameters=false] - constructor setting - if true, the constructor tries to read settings from the query string,
   *                                                              existing settings will not be overridden
   * @param {Boolean} [settings.forceQueryStringParameters=false] - constructor setting - if true, the constructor tries to read settings from the query string
   *                                                              existing settings will be overridden
   *
   * @param {Boolean} [settings.anchorElements=true] - browser UI setting - choose whether the default handler for creating DOM elements representing
   *                                                   anchors shall be instantiated
   * @param {Boolean} [settings.brandedMode=true] - browser UI setting - choose whether ShapeDiver branding shall be shown during initial loading
   * @param {String} [settings.busyGraphic] - browser UI setting - optional URL to an image which shall be shown instead of the busy spinner
   * @param {Element} [settings.containerControls] - browser UI setting - optional container to use for creating parameter controls, may be undefined in
   *                                   which case a DOM element whose id is domElementIdPrefix+'-controls' will be looked for. Set this to a falsy value
   *                                   different from `undefined` to prevent the settings widget from being created. Not creating the settings and controls
   *                                   widgets saves some resources on loading of the viewer. 
   * @param {Element} [settings.containerSettings] - browser UI setting - optional container to use for creating settings controls, may be `undefined` in
   *                                   which case a DOM element whose id is domElementIdPrefix+'-settings' will be looked for. Set this to a falsy value
   *                                   different from `undefined` to prevent the settings widget from being created. Not creating the settings and controls
   *                                   widgets saves some resources on loading of the viewer.
   * @param {Boolean} [settings.createButtons=true] - browser UI setting - choose whether standard buttons will be created for the viewport
   * @param {String} [settings.domElementIdPrefix='sdv-container'] - browser UI setting - prefix to use for lookup of dom elements
   * @param {Boolean} [settings.editMode=false] - browser UI setting - choose whether the parameter controls should be initialized in edit mode
   * @param {Boolean} [settings.exportModal=true] - browser UI setting - choose whether a modal dialog for export handling shall be instantiated
   * @param {Boolean} [settings.downloadExportButtonName='download'] - browser UI setting - caption to use for displaying the export download button in modal dialogs of downloadable exports
   * @param {Boolean} [settings.showControlsButton=true] - browser UI setting - choose whether a button for showing/hiding the parameter controls shall be shown
   * @param {Boolean} [settings.showControlsInitial=false] - browser UI setting - choose whether the parameter controls shall be shown initially
   * @param {Boolean} [settings.showSettingsButton=true] - browser UI setting - choose whether a button for showing/hiding the settings controls shall be shown
   * @param {Boolean} [settings.showSettingsInitial=false] - browser UI setting - choose whether the settings controls shall be shown initially
   * @param {Boolean} [settings.showZoomButton=true] - browser UI setting - choose whether a button for zooming shall be shown
   * @param {Boolean} [settings.zoomButtonResetsCamera=false] - browser UI setting - choose whether the zoom button shall reset the camera to its default position
   * @param {Boolean} [settings.showFullscreenButton=true] - browser UI setting - choose whether a button for to/from fullscreen mode shall be shown
   * @param {Boolean} [settings.showInitialSpinner=true] - browser UI setting - choose whether an initial loading spinner shall be shown
   * @param {Boolean} [settings.showBusySpinner=true] - browser UI setting - choose whether a busy mode spinner or the optional custom busyGraphic shall be shown
   * @param {Boolean} [settings.viewportOverlays=true] - browser UI setting - choose whether viewport overlays will be created at all (buttons, spinners, progress bar)
   *
   * @param {String} [settings.apiversion='2'] - constructor setting - major version of the API to return by default
   * @param {String} [settings.runtimeId=''] - constructor setting - runtimeId to set for returned API object, a random one will be chosen by default
   * @param {Boolean} [settings.arkitbridge=false] - constructor setting - enable bridge to ShapeDiver iOS app using ARKit
   * @param {Boolean} [settings.blurSceneWhenBusy=true] - viewer setting - choose whether to blur the scene during updates are busy
   * @param {*} [settings.container] - viewer setting - Container to use for creating the viewport, may be undefined in which case
   *                                   a DOM element whose id is domElementIdPrefix+'-viewport' will be looked for.
   *                                   An array of containers may be passed to create multiple viewports.
   *                                   Pass an empty array to avoid creating a viewport.
   * @param {Boolean} [settings.deferGeometryLoading=false] - viewer setting - true: tell the CommPlugin instance created by the constructor to not
   *                                                          load any geometry until first parameter update or refresh, false: load default geometry
   * @param {Boolean} [settings.ignoreSuperseded=true] - viewer setting - If truthy ignore intermediate solutions which at the time of their
   *                                                     arrival have already been superseded by another customization request.
   *                                                     Caution: In case of continuously repeated customization requests this may lead to the scene never being updated.
   * @param {String} [settings.commPluginRuntimeId='CommPlugin_1'] - viewer setting - runtime id to use for the CommPlugin instance created by the constructor
   * @param {Number} [settings.loggingLevel=-1] - viewer setting - general logging level: NONE(-1), ERROR(0), WARN(1), INFO(2), DEBUG(3)
   * @param {Number} [settings.messageLoggingLevel=-1] - viewer setting - message logging level: NONE(-1), ERROR(0), WARN(1), INFO(2), DEBUG(3)
   * @param {String} [settings.modelViewUrl='us-east-1'] - constructor setting - optional model view url to pass to the default CommPlugin,
   *                                                  a leading 'https://' will be prefixed if not in place,
   *                                                  'us-east-1' and 'eu-central-1' may be used as abbreviations for ShapeDiver's default systems
   * @param {Number} [settings.showSceneMode=2] - viewer setting - when to fade in the scene: ON_SHOW(1), ON_FIRST_PLUGIN(2), ON_ALL_PLUGINS(3)
   * @param {String} [settings.ticket=''] - constructor setting - optional model view ticket to be used for immediately instantiating a CommPlugin instance.
   *                                      No CommPlugin instance will be registered by the constructor if this is empty.
   *                                      Further CommPlugin instances can be initialized after the constructor has finished, using the API v2 function
   *                                      {@link module:ApiInterfaceV2~ApiPluginInterface#registerCommPluginAsync registerCommPluginAsync}.
   * @param {String} [settings.authorization=''] - constructor setting - optional authorization token to include with
   *                                      requests to the model view interface (prepend 'Bearer ' yourself if necessary)
   *
   * @param {Boolean} [settings.useModelSettings=true] - viewer setting - if set to false the viewer will ignore settings stored model settings, which would otherwise be set on loading of the first model
   * @return {Object} An instance of {@link module:ParametricViewer~ApiObjectV1 ApiObjectV1} or {@link module:ParametricViewer~ApiObjectV2 ApiObjectV2}
   *                  depending on `settings.apiversion`.
   */
  SDVApp.ParametricViewer = function (settings_) {
    let scope = 'SDVApp.ParametricViewer';

    // settings sanity check
    settings_ = settings_ || {};

    // optionally try to read settings from the query string
    if (RUNNING_IN_IFRAME && (settings_.useQueryStringParameters || settings_.forceQueryStringParameters)) {
      let queryStringKvps = GLOBAL_UTILS.parseQueryString(window.location.search.substring(1));
      Object.keys(queryStringKvps).forEach(function (key) {
        if (!SETTING_DEFINITIONS.hasOwnProperty(key)) {
          return;
        }
        if (settings_.forceQueryStringParameters || !settings_.hasOwnProperty(key)) {
          let definition = SETTING_DEFINITIONS[key];
          if (definition.type === 'boolean') {
            settings_[key] = GLOBAL_UTILS.toBoolean(queryStringKvps[key], definition.defval);
          } else if (definition.type === 'number') {
            settings_[key] = GLOBAL_UTILS.toNumber(queryStringKvps[key], definition.defval);
          } else {
            settings_[key] = queryStringKvps[key];
          }
        }
      });
    }

    // did we get a prefix for looking for DOM elements?
    settings_.domElementIdPrefix = settings_.domElementIdPrefix || 'sdv-container';

    // get containers for viewport, parameter controls, settings
    if (RUNNING_IN_BROWSER) {
      // container to use for the viewport
      if (!settings_.container) settings_.container = document.getElementById(settings_.domElementIdPrefix + '-viewport');
      if (!settings_.container && (settings_.logginglevel & 3) > 1) {
        console.log('No viewport container'); // eslint-disable-line no-console
      }
      // optional container to use for the controls
      settings_.containerControls = settings_.containerControls === undefined ? document.getElementById(settings_.domElementIdPrefix + '-controls') : settings_.containerControls;
      // optional container to use for the settings
      settings_.containerSettings = settings_.containerSettings === undefined ? document.getElementById(settings_.domElementIdPrefix + '-settings') : settings_.containerSettings;

      // define a handler for switching container classes between portrait and landscape mode,
      // if the container layout looks as expected

      // define abbreviations for containers: cv - viewport, cc - controls, cs - settings
      let cv = settings_.container ? (Array.isArray(settings_.container) && settings_.container.length > 0 ? settings_.container[0].parentElement : settings_.container.parentElement) : null,
          cc = settings_.containerControls,
          cs = settings_.containerSettings;

      // use mode to notify controls about resize change.
      if (cv && cc && cs) {
        // get parent elements of containers
        let pv = cv.parentElement,
            pc = cc.parentElement,
            ps = cs.parentElement;
        // we expect parents to be identical
        if (pv === pc && pv === ps && pv !== null) {
          let c = [pv, cv, cc, cs];
          settings_.resizeHandler = function () {
            // based on size of parent and visibility of controls and settings,
            // decide between portrait and landscape mode for the placement of controls and settings
            let c_add = 'landscape',
                c_remove = 'portrait',
                c_flex_basis = 250,
                landscapeAvailableWidth = pv.offsetWidth,
                num_visible = 0,
                mode = 'landscape';

            if (cc.style.display !== 'none') {
              landscapeAvailableWidth -= c_flex_basis;
              num_visible += 1;
            }
            if (cs.style.display !== 'none') {
              landscapeAvailableWidth -= c_flex_basis;
              num_visible += 1;
            }
            if (pv.offsetHeight > landscapeAvailableWidth) {
              // switch layout of containers to portrait mode
              c_add = 'portrait';
              c_remove = 'landscape';
              // in case of portrait mode, change flex-basis of cc and cs according
              // to pv.offsetHeight and aspect ratio of pv
              c_flex_basis = 40;
              c_flex_basis += num_visible > 1 ? 99 : 2 * 99;
              mode = 'portrait';
            }
            c.forEach((c) => {
              if (c) {
                c.classList.remove(c_remove);
                c.classList.add(c_add);
              }
            });
            cc.style.flexBasis = c_flex_basis + 'px';
            cs.style.flexBasis = c_flex_basis + 'px';

            var event = new CustomEvent('viewer-resized', { detail: { mode: mode, flexBasis: c_flex_basis, clientWidth: window.innerWidth  } });
            cs.dispatchEvent(event);
            cc.dispatchEvent(event);
          };
          // add/remove css classes when the window gets resized (e.g. when the device is turned)
          window.addEventListener('resize', settings_.resizeHandler);
          window.addEventListener('orientationchange',settings_.resizeHandler);
          // initially set css classes
          settings_.resizeHandler();
        }
      }
    }

    // settings default values
    Object.keys(SETTING_DEFINITIONS).forEach(function (key) {
      if (!settings_.hasOwnProperty(key)) {
        let definition = SETTING_DEFINITIONS[key];
        let key_lower = key.toLowerCase();
        let val = GLOBAL_UTILS.getNodeAttribute(settings_.container, key_lower, definition.defval);
        if (definition.type === 'boolean') {
          settings_[key] = GLOBAL_UTILS.toBoolean(val, definition.defval);
        } else if (definition.type === 'number') {
          settings_[key] = GLOBAL_UTILS.toNumber(val, definition.defval);
        } else {
          settings_[key] = val;
        }
      }
    });

    // additional value checking
    if (settings_.showSceneMode < 1 || settings_.showSceneMode > 3) settings_.showSceneMode = 2;

    // the settings button should not be shown in case the controls are not running in edit mode
    if (!settings_.editMode && settings_.showSettingsButton === undefined) {
      settings_.showSettingsButton = false;
    }

    // inject build data to settings
    for (let k in BUILD_DATA)
      settings_[k] = BUILD_DATA[k];

    // in case we are running in an iframe, before instantiating the viewer, post a message to our parent,
    // telling that the content of the iframe has been loaded
    if (RUNNING_IN_IFRAME && document.referrer) {
      parent.postMessage({ type: 'sdv-DOMContentLoaded', iframeId: settings_.iframeId }, document.referrer);
    }

    // private viewer instance
    let _viewer = new (require('../src/app/ViewerApp'))(settings_);

    // public viewer API, version according to settings
    let _api = _viewer.api({ version: settings_.apiversion, runtimeId: settings_.runtimeId });
    _api.apps = {};

    // public viewer API v2
    _api.getApiV2 = function (opts) {
      opts = opts || {};
      opts.version = 2;
      return _viewer.api(opts);
    };

    //
    // expose viewer
    // we allow this for development builds only
    //
    if (settings_.exposeViewer && (BUILD_DATA.build_branch.startsWith('feature') || BUILD_DATA.build_branch.startsWith('development'))) {
      _api.getViewer = function () {
        console.warn('Direct access to the ShapeDiver Viewer is disabled for release builds.'); // eslint-disable-line no-console
        return _viewer;
      };
    }

    //
    // get a local API v2 - we need it below for buttons, controls, etc
    //
    let _api2 = _api;
    if (!_api2.scene) {
      _api2 = _api.getApiV2();
    }

    //
    // if we are given a ticket, create a CommPlugin instance right away
    //
    if (settings_.ticket) {
      let pluginSettings = {
        runtimeId: settings_.commPluginRuntimeId,
        ticket: settings_.ticket,
        deferGeometryLoading: settings_.deferGeometryLoading,
        loggingLevel: settings_.loggingLevel,
        messageLoggingLevel: settings_.messageLoggingLevel,
        authorization: settings_.authorization,
        ignoreSuperseded: settings_.ignoreSuperseded
      };
      if (settings_.modelViewUrl)
        pluginSettings['modelViewUrl'] = settings_.modelViewUrl;
      let plugin = new that.plugins.CommPlugin(pluginSettings);
      _api2.plugins.registerPluginAsync(plugin)
        .then( // in case we are running in an iframe, tell our parent about success / error of the CommPlugin loading
          function (result) {
            if (RUNNING_IN_IFRAME && document.referrer) {
              if (result.err || (result.data && result.data.status === 'failed')) {
                parent.postMessage({
                  type: 'sdv-CommPluginFailed',
                  runtimeId: pluginSettings.runtimeId,
                  iframeId: settings_.iframeId
                }, document.referrer);
              } else {
                that.plugins.runtimeIds.push(pluginSettings.runtimeId);
                parent.postMessage({
                  type: 'sdv-CommPluginLoaded',
                  data: result.data,
                  runtimeId: pluginSettings.runtimeId,
                  iframeId: settings_.iframeId
                }, document.referrer);
              }
            }
          },
          function (err) {
            if (RUNNING_IN_IFRAME && document.referrer) {
              parent.postMessage({
                type: 'sdv-CommPluginFailed',
                err: err,
                runtimeId: pluginSettings.runtimeId,
                iframeId: settings_.iframeId
              }, document.referrer);
            }
          }
        )
      ;
    }

    //
    // Browser UI - viewport overlays (buttons, spinners, etc)
    //
    if (settings_.viewportOverlays) {
      let options = {
        containerViewport: settings_.container,
        containerControls: settings_.containerControls,
        containerSettings: settings_.containerSettings,
        domElementIdPrefix: settings_.domElementIdPrefix,
      };
      ['brandedMode', 'createButtons', 'showControlsButton', 'showSettingsButton',
        'showControlsInitial', 'showSettingsInitial',
        'showZoomButton', 'showFullscreenButton', 'showInitialSpinner', 'showBusySpinner',
        'viewportOverlays', 'zoomButtonResetsCamera', 'resizeHandler'].forEach(function (key) {
        options[key] = settings_[key];
      });
      try {
        _api.apps.overlays = new that.apps.overlays(_api2, options);
        if (!_api.apps.overlays) {
          _api.warn(scope, 'Viewport overlays could not be created, has UIkit been loaded ?');
        }
      } catch (e) {
        delete _api.apps.overlays;
        _api.warn(scope, 'Exception when creating viewport overlays, has UIkit been loaded ?', e);
      }
      // should a special busy graphic be set?
      if (settings_.busyGraphic) {
        _api.apps.overlays.setBusyGraphic(settings_.busyGraphic);
      }
    }

    //
    // Browser UI - create controls for parameters
    //
    if (settings_.containerControls) {
      let options = { editMode: settings_.editMode, commitParameters: settings_.commitParameters };
      if (_api.apps.overlays) {
        options.showMessage = _api.apps.overlays.showMessage;
      }
      try {
        _api.apps.controls = new that.apps.controls(_api2, settings_.containerControls, options, { onElementCreated: settings_.resizeHandler });
        if (!_api.apps.controls) {
          _api.warn(scope, 'Parameter controls could not be created.');
        }
      } catch (e) {
        delete _api.apps.controls;
        _api.warn(scope, 'Exception when creating parameter controls', e);
      }
    }

    //
    // Browser UI - create controls for settings
    //
    if (settings_.containerSettings) {
      try
      {
        _api.apps.settings = new that.apps.controls(_api2, settings_.containerSettings, { settings: true, editMode: settings_.editMode}, { onElementCreated: settings_.resizeHandler });
        if (!_api.apps.settings) {
          _api.warn(scope, 'Setting controls could not be created.');
        }
      } catch (e) {
        delete _api.apps.settings;
        _api.warn(scope, 'Exception when creating setting controls', e);
      }
    }

    //
    // Browser UI - create handler for anchors
    //
    if (settings_.anchorElements) {
      try {
        _api.apps.domElements = new that.apps.domElements(_api2);
        if (!_api.apps.domElements) {
          _api.warn(scope, 'Anchor elements app could not be created.');
        }
      } catch (e) {
        delete _api.apps.domElements;
        _api.warn(scope, 'Exception when creating anchor elements app', e);
      }
    }

    //
    // Browser UI - create export modal dialog
    //
    if (settings_.exportModal) {
      try {
        _api.apps.exportModal = new that.apps.exportModal(_api2, {
          domElementIdPrefix: settings_.domElementIdPrefix + '-modal-export',
          downloadExportButtonName: settings_.downloadExportButtonName
        });
        if (!_api.apps.exportModal) {
          _api.warn(scope, 'Modal dialog could not be created, has UIkit been loaded ?');
        }
      } catch (e) {
        delete _api.apps.exportModal;
        _api.warn(scope, 'Exception when creating modal dialog', e);
      }
    }

    //
    // API v1 backwards compatibility
    // Inject further functions into API v1, which depend on UI elements managed here
    //
    let _injectMissingFunctionsApiV1 = function (apiv1) {

      if (_api.apps.overlays) {

        // setBusyGraphic
        apiv1.setBusyGraphic = _api.apps.overlays.setBusyGraphic;

        // setBusyGraphic
        apiv1.setBusyGraphicPosition = _api.apps.overlays.setBusyGraphicPosition;

        // hideControls
        apiv1.hideControls = function () {
          _api.apps.overlays.buttonOnClick('controls', false);
          _api.apps.overlays.toggleVisibility('controls', false);
          apiv1._callCommandResultCallback('hideControls', true);
        };

        // hideFullscreenToggle
        apiv1.hideFullscreenToggle = function () {
          _api.apps.overlays.toggleVisibility('fullscreen', false);
          return true;
        };

        // hideZoomToggle
        apiv1.hideZoomToggle = function () {
          _api.apps.overlays.toggleVisibility('zoom', false);
          return true;
        };

        // showControls
        apiv1.showControls = function () {
          _api.apps.overlays.buttonOnClick('controls', true);
          _api.apps.overlays.toggleVisibility('controls', true);
          apiv1._callCommandResultCallback('showControls', true);
        };

        // showFullscreenToggle
        apiv1.showFullscreenToggle = function () {
          _api.apps.overlays.toggleVisibility('fullscreen', true);
          return true;
        };

        // showZoomToggle
        apiv1.showZoomToggle = function () {
          _api.apps.overlays.toggleVisibility('zoom', true);
          return true;
        };

      }

      return apiv1;
    };

    if (Number(settings_.apiversion) === 1) {
      _injectMissingFunctionsApiV1(_api);
    }

    //
    // public viewer API v1
    //
    _api.getApiV1 = function (opts) {
      opts = opts || {};
      opts.version = 1;
      return _injectMissingFunctionsApiV1(_viewer.api(opts));
    };

    // in case we are running in an iframe, reflect API to parent
    if (RUNNING_IN_IFRAME && document.referrer) {
      // make sure the apps member exists for _api2
      if (!_api2.apps) _api2.apps = _api.apps;
      // instantiate the reflector and reflect the _api2 object
      let reflector = new API_REFLECTOR(parent, document.referrer, settings_.iframeDebugging, settings_.iframeId);
      reflector.reflect(_api2, 'api')
        .then(
          function () {
            // post a message telling our parent that the viewer has been loaded
            parent.postMessage({ type: 'sdv-ViewerLoaded', iframeId: settings_.iframeId }, document.referrer);
            settings_.iframeDebugging && console.log('API object could be reflected'); // eslint-disable-line no-console
          },
          function () {
            settings_.iframeDebugging && console.log('API object could NOT be reflected'); // eslint-disable-line no-console
          }
        )
      ;
    }

    return _api;
  };

  return this;
};

module.exports = new ParametricViewer();
