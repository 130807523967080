"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var build_data_1 = require("../../build/build_data");
/**
 * Build data interface.
 */
var BuildDataAccessor = /** @class */ (function () {
    // #region Methods (2)
    function BuildDataAccessor() {
        this.buildVersion = build_data_1.build_data.build_version;
        this.buildDate = build_data_1.build_data.build_date;
        this.buildBranch = build_data_1.build_data.build_branch;
        this.buildCommit = build_data_1.build_data.build_commit;
    }
    return BuildDataAccessor;
}());
exports.BuildData = new BuildDataAccessor();
