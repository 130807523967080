"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseSettingComponent_1 = require("./BaseSettingComponent");
var StringInputSettingComponent = /** @class */ (function (_super) {
    __extends(StringInputSettingComponent, _super);
    // #region Constructors (1)
    function StringInputSettingComponent(api, settingDefinition, parent, componentFactory) {
        return _super.call(this, api, settingDefinition, componentFactory.createUserDefinedParameter(settingDefinition, parent, false)) || this;
    }
    return StringInputSettingComponent;
}(BaseSettingComponent_1.BaseSettingComponent));
exports.StringInputSettingComponent = StringInputSettingComponent;
