"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var THREE = require("../../../../../../externals/three");
var CameraMultipleInterpolation = /** @class */ (function () {
    // #endregion Properties (1)
    // #region Constructors (1)
    function CameraMultipleInterpolation(_cameraControls, _path, _interpolationFunction) {
        this._cameraControls = _cameraControls;
        this._path = _path;
        this._interpolationFunction = _interpolationFunction;
        // #region Properties (1)
        this.end = {
            position: { x: [], y: [], z: [] },
            target: { x: [], y: [], z: [] }
        };
        for (var i = 0; i < this._path.length; i++) {
            this.end.position.x.push(this._path[i].position.x);
            this.end.position.y.push(this._path[i].position.y);
            this.end.position.z.push(this._path[i].position.z);
            this.end.target.x.push(this._path[i].target.x);
            this.end.target.y.push(this._path[i].target.y);
            this.end.target.z.push(this._path[i].target.z);
        }
    }
    // #endregion Constructors (1)
    // #region Public Methods (3)
    CameraMultipleInterpolation.prototype.onComplete = function (value) {
        var positionOffset = new THREE.Vector3(this._path[this._path.length - 1].position.x, this._path[this._path.length - 1].position.y, this._path[this._path.length - 1].position.z).sub(this._cameraControls.position);
        this._cameraControls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(positionOffset.x, positionOffset.y, positionOffset.z));
        var targetOffset = new THREE.Vector3(this._path[this._path.length - 1].target.x, this._path[this._path.length - 1].target.y, this._path[this._path.length - 1].target.z).sub(this._cameraControls.target);
        this._cameraControls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(targetOffset.x, targetOffset.y, targetOffset.z));
    };
    CameraMultipleInterpolation.prototype.onStop = function (value) {
    };
    CameraMultipleInterpolation.prototype.onUpdate = function (value) {
        var p = new THREE.Vector3(this._interpolationFunction(this.end.position.x, value.delta), this._interpolationFunction(this.end.position.y, value.delta), this._interpolationFunction(this.end.position.z, value.delta));
        var positionOffset = p.clone().sub(this._cameraControls.position);
        this._cameraControls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(positionOffset.x, positionOffset.y, positionOffset.z));
        var t = new THREE.Vector3(this._interpolationFunction(this.end.target.x, value.delta), this._interpolationFunction(this.end.target.y, value.delta), this._interpolationFunction(this.end.target.z, value.delta));
        var targetOffset = t.clone().sub(this._cameraControls.target);
        this._cameraControls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(targetOffset.x, targetOffset.y, targetOffset.z));
    };
    return CameraMultipleInterpolation;
}());
exports.CameraMultipleInterpolation = CameraMultipleInterpolation;
