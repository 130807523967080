
/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiPluginImplementationV2.1.js*
 *
 * ### Content
 *   * Implementation of the ShapeDiver 3D Viewer Plugin API V2.1
 *
 * @module PluginApi
 * @author ShapeDiver <contact@shapediver.com>
 */

const COMM_PLUGIN = require('../../../plugins/comm/CommPlugin');

/**
* Imported messaging constant definitions
*/
var messagingConstants = require('../../../shared/constants/MessagingConstants');

/**
* Imported global plugin constants
*/
var pluginConstantsGlobal = require('../../../shared/constants/PluginConstantsGlobal');

/**
* ApiInterfaceV2
*/
var ApiInterfaceV2 = new (require('../ApiInterfaceV2.1'))();

/**
* APIResponse factory
*/
const APIResponse = require('../ApiResponse');


////////////
////////////
//
// Plugin Interface
//
////////////
////////////

/**
 * ShapeDiver 3D Viewer API V2 - Plugin Interface
 * @class
 * @implements {module:ApiPluginInterface~ApiPluginInterface}
 * @param {Object} api - The global api object to which this api belongs
 * @param {Object} references.pluginHandler - Reference to the plugin handler
 * @param {Object} references.messagingHandler - Reference to the messaging handler
 * @param {Object} references.parameterHandler - Reference to the parameter handler
 */
var PluginApi = function (_api, ___refs) {

  var that = this;

  // include enums from interface definition
  this.STATUS = ApiInterfaceV2.plugins.STATUS;

  // shortcuts to handlers
  var _pluginHandler = ___refs.pluginHandler;
  var _messagingHandler = ___refs.messagingHandler;
  var _parameterHandler = ___refs.parameterHandler;

  /** @inheritdoc */
  this.get = function () {
    return APIResponse(null, _pluginHandler.getStatusDescription());
  };

  /** @inheritdoc */
  this.registerPluginAsync = function (plugin) {

    return new Promise(function (resolve) {

      try {
        // get runtime id of plugin
        let runtimeId = plugin.getRuntimeId();

        // msg subscription tokens
        let subtokens = [];

        // callback for message subscription
        var cb = function (topic, msg) {
          let pf = msg.getUniquePartByType(messagingConstants.messageDataTypes.PLUGIN_RUNTIME_ID);
          if (pf && pf.data === runtimeId) {
            subtokens.forEach((t) => {
              _messagingHandler.unsubscribeFromMessageStream(t);
            });
            resolve(APIResponse(null, plugin.getStatusDescription()));
          }
        };

        // register to messages of type messagingConstants.messageTopics.PLUGIN_ACTIVE and messagingConstants.messageTopics.PLUGIN_FAILED
        subtokens.push(_messagingHandler.subscribeToMessageStream(messagingConstants.messageTopics.PLUGIN_ACTIVE, cb));
        subtokens.push(_messagingHandler.subscribeToMessageStream(messagingConstants.messageTopics.PLUGIN_FAILED, cb));

        // call _pluginHandler's registerPlugin
        if (_pluginHandler.registerPlugin(plugin) === undefined) {

          // in case registerPlugin failed, unsubscribe from messages
          subtokens.forEach((t) => {
            _messagingHandler.unsubscribeFromMessageStream(t);
          });

          resolve(APIResponse('Failed to register plugin.', plugin.getStatusDescription()));
        }
      } catch (ex) {
        resolve(APIResponse(ex));
      }
    });
  };

  /** @inheritdoc */
  this.registerCommPluginAsync = function (settings) {
    let plugin = new COMM_PLUGIN(settings);
    return that.registerPluginAsync(plugin);
  };

  /** @inheritdoc */
  this.refreshPluginAsync = function (id, payload) {
    let scope = 'ApiImplementationV2.refreshPluginAsync';
    // create a random process token id
    let token = messagingConstants.makeMessageToken();
    token.payload = payload;
    // recall history
    return new Promise(function (resolve) {
      // create a process callback and register it
      let processCallback = function (t, v) {
        if (v.hasOwnProperty('parts')) {
          for (let p of v.parts) {
            if (p.type == messagingConstants.messageDataTypes.PROCESS_SUCCESS) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(null, _parameterHandler.getParameterState(), payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ABORT) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ERROR) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
          }
        }
      };
      let subToken = _api.setProcessCallback(token.id, processCallback);
      //
      let res = _parameterHandler.refreshPlugin(id, token);
      if (res.err) {
        _api.error(scope, '_parameterHandler.refreshPlugin failed');
        _api.clearProcessCallback(subToken);
        resolve(APIResponse('Failed to refresh plugin ' + id, null, payload));
        return;
      }
      // wait for process message (see above)
    });
  };

  /** @inheritdoc */
  this.saveDefaultParameterValuesAsync = function (ids) {
    if (!ids) ids = _pluginHandler.getRuntimeIds();
    if (!Array.isArray(ids)) ids = [ids];
    let promises = [];
    // for all plugin runtime ids...
    for (let i = 0, imax = ids.length; i < imax; i++) {
      let id = ids[i];
      //...get plugin,
      let plugin = _pluginHandler.getPluginByRuntimeId(id);
      if (!plugin) {
        promises.push(Promise.resolve(false));
        continue;
      }
      //...check whether it has the required capability,
      if (!plugin.getCapabilities().includes(pluginConstantsGlobal.pluginCapabilities.DEFAULTPARAM)) {
        promises.push(Promise.resolve(false));
        continue;
      }
      //...get parameter values,
      let kvps = _parameterHandler.getParameterValuesForPlugin(id, true /* string values */);
      //...save them as default
      promises.push(plugin.saveDefaultParameterValues(kvps));
    }
    return Promise.all(promises)
      .then(
        function (arrResult) {
          return APIResponse(null, arrResult.every((r) => (r)));
        },
        function (err) {
          return APIResponse(err, false);
        }
      );
  };

  /** @inheritdoc */
  this.deregisterPluginAsync = function () {
    // see #SS-39
    return Promise.reject('not implemented yet');
  };

};

module.exports = PluginApi;