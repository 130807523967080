"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var ShapeDiverRowSizeDoesNotExists = /** @class */ (function (_super) {
    __extends(ShapeDiverRowSizeDoesNotExists, _super);
    // #region Constructors (1)
    function ShapeDiverRowSizeDoesNotExists(rowSize) {
        return _super.call(this, "Row size " + rowSize + " does not exist. Possible sizes are \"1,2,4\"") || this;
    }
    return ShapeDiverRowSizeDoesNotExists;
}(Error));
/**
 * Private class used to resolve the rows.
 */
var Rows = /** @class */ (function () {
    function Rows() {
        // #region Properties (4)
        this._elements = [];
        this._currentRow = [];
        this._spaceTaken = 0;
        // #endregion Private Methods (1)
    }
    Object.defineProperty(Rows.prototype, "isFull", {
        // #endregion Properties (4)
        // #region Private Accessors (2)
        get: function () {
            return this.rowSize >= Rows.MAX_SIZE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Rows.prototype, "rowSize", {
        get: function () {
            var e_1, _a;
            var usedSpace = 0;
            try {
                for (var _b = __values(this._currentRow), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var tuple = _c.value;
                    usedSpace += tuple[1];
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return usedSpace;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Private Accessors (2)
    // #region Public Methods (2)
    /**
     * Adds element to rows.
     * @param { IResizableComponent } elem - element to add
     * @param { number } spaceReq - space required
     */
    Rows.prototype.addElement = function (elem, spaceReq) {
        if (this._spaceTaken + spaceReq > Rows.MAX_SIZE) {
            this.resolveRow();
            this._currentRow = [];
            this._spaceTaken = 0;
        }
        this._spaceTaken += spaceReq;
        this._currentRow.push([elem, spaceReq]);
    };
    /**
     * Finalizes rows and returns final result of all added rows with their final sizes.
     * @returns { [IResizableComponent, number][] } finalizes and retruns final rows and sizes.
     */
    Rows.prototype.finalize = function () {
        if (this.isFull) {
            var last = this._currentRow.pop();
            this.resolveRow();
            this._currentRow = [last];
            this.resolveRow();
        }
        else {
            this.resolveRow();
        }
        return this._elements;
    };
    // #endregion Public Methods (2)
    // #region Private Methods (1)
    Rows.prototype.resolveRow = function () {
        var _this = this;
        if (!this.isFull) {
            // first expand 1 elems
            for (var i = 0; i < this._currentRow.length; i++) {
                var tuple = this._currentRow[i];
                if (tuple[1] == 1 /* QTR_WIDTH */) {
                    tuple[1] = 2 /* HALF_WIDTH */;
                    if (this.isFull) {
                        break;
                    }
                }
            }
            // expand 2 elems
            if (!this.isFull) {
                for (var i = 0; i < this._currentRow.length; i++) {
                    var tuple = this._currentRow[i];
                    if (tuple[1] == 2 /* HALF_WIDTH */) {
                        tuple[1] = 4 /* FULL_WIDTH */;
                        if (this.isFull) {
                            break;
                        }
                    }
                }
            }
        }
        this._currentRow.forEach(function (x) { return _this._elements.push(x); });
        this._currentRow = [];
    };
    Rows.MAX_SIZE = 4;
    return Rows;
}());
/**
 * The resize manager
 */
var SDControlsResizeManager = /** @class */ (function () {
    // #endregion Properties (6)
    // #region Constructors (1)
    function SDControlsResizeManager(_isMobileDevice) {
        var _a;
        this._isMobileDevice = _isMobileDevice;
        this._possibleClasses = {
            landscape: ["sd-landscape-max-width", "uk-width-1-1"],
            fullWidth: ["uk-width-1-1", "sd-max-width-4"],
            quarter: ["uk-width-1-4", "sd-max-width-1"],
            half: ["uk-width-1-2", "sd-max-width-2"],
            all: ["uk-width-1-1", "uk-width-1-2", "uk-width-1-4", "uk-width-expand", "sd-max-width-1", "sd-max-width-2", "sd-max-width-4", "sd-landscape-max-width"],
        };
        this._spacesRequiredMap = (_a = {},
            _a[1 /* QTR_WIDTH */] = 1,
            _a[2 /* HALF_WIDTH */] = 2,
            _a[4 /* FULL_WIDTH */] = 4,
            _a);
        this._clientWidth = 1920;
        this._isClientSizeSmall = false;
        this._mode = "landscape" /* LANDSCAPE */;
    }
    // #endregion Constructors (1)
    // #region Public Methods (1)
    /**
     * Fires of resize.
     * @param { Array<IResizableComponent> } elements - elements to resize
     * @param { ViewerMode? } mode - viewer mode, if not set it's going to use last set or default LANDSCAPE
     * @param { number? } clientWidth - client width, if not set it's goint to use last set or assume 1920
     * @param { number? } delay - in case of multiple calls to resize, delay is set in order to optimize and cancel previous calls. Default is 1000
     */
    SDControlsResizeManager.prototype.resize = function (elements, mode, clientWidth, delay) {
        var _this = this;
        if (delay === void 0) { delay = 100; }
        if (mode) {
            this._mode = mode;
        }
        if (clientWidth) {
            this._clientWidth = clientWidth;
        }
        // Since resize coule be potentially called multiple times in a row, add a bit of timeout to it
        this._resizeTimeout = window.setTimeout(function () {
            if (_this._resizeTimeout && elements.length > 1) {
                window.clearTimeout(_this._resizeTimeout);
            }
            var useFullRowWidth = _this._mode === "landscape" /* LANDSCAPE */;
            _this.adjustSpaceRequiredAccordingToClientWidth(_this._clientWidth);
            _this.resizeHandle(elements, useFullRowWidth);
        }, delay);
    };
    // #endregion Public Methods (1)
    // #region Private Methods (5)
    SDControlsResizeManager.prototype.addClassesToElem = function (elem, classes) {
        // remove every
        this._possibleClasses.all.forEach(function (cls) {
            if (elem.row.classList.contains(cls)) {
                elem.row.classList.remove(cls);
            }
        });
        // add full row classes
        classes.forEach(function (cls) { return elem.row.classList.add(cls); });
    };
    SDControlsResizeManager.prototype.adjustSpaceRequiredAccordingToClientWidth = function (clientWidth) {
        this._isClientSizeSmall = clientWidth < SDControlsResizeManager.CLIENT_WIDTH_ADJUST_SIZE;
        // for small client sizes expand elements.
        this._spacesRequiredMap[2 /* HALF_WIDTH */] = this._isClientSizeSmall ? 4 : 2;
        this._spacesRequiredMap[1 /* QTR_WIDTH */] = this._isClientSizeSmall ? 2 : 1;
    };
    SDControlsResizeManager.prototype.handleFullWidth = function (elements) {
        var e_2, _a;
        try {
            for (var elements_1 = __values(elements), elements_1_1 = elements_1.next(); !elements_1_1.done; elements_1_1 = elements_1.next()) {
                var elem = elements_1_1.value;
                this.addClassesToElem(elem, this._possibleClasses.landscape);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (elements_1_1 && !elements_1_1.done && (_a = elements_1.return)) _a.call(elements_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    SDControlsResizeManager.prototype.handleResizable = function (elements) {
        var e_3, _a, e_4, _b, e_5, _c;
        var _loop_1 = function (elem) {
            this_1._possibleClasses.all.forEach(function (cls) { return elem.row.classList.remove(cls); });
        };
        var this_1 = this;
        try {
            // first remove every.
            for (var elements_2 = __values(elements), elements_2_1 = elements_2.next(); !elements_2_1.done; elements_2_1 = elements_2.next()) {
                var elem = elements_2_1.value;
                _loop_1(elem);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (elements_2_1 && !elements_2_1.done && (_a = elements_2.return)) _a.call(elements_2);
            }
            finally { if (e_3) throw e_3.error; }
        }
        var rows = new Rows();
        try {
            for (var elements_3 = __values(elements), elements_3_1 = elements_3.next(); !elements_3_1.done; elements_3_1 = elements_3.next()) {
                var elem = elements_3_1.value;
                rows.addElement(elem, this._spacesRequiredMap[elem.size]);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (elements_3_1 && !elements_3_1.done && (_b = elements_3.return)) _b.call(elements_3);
            }
            finally { if (e_4) throw e_4.error; }
        }
        var elementSizeTuples = rows.finalize();
        try {
            for (var elementSizeTuples_1 = __values(elementSizeTuples), elementSizeTuples_1_1 = elementSizeTuples_1.next(); !elementSizeTuples_1_1.done; elementSizeTuples_1_1 = elementSizeTuples_1.next()) {
                var tuple = elementSizeTuples_1_1.value;
                switch (tuple[1]) {
                    case 1:
                        this.addClassesToElem(tuple[0], this._possibleClasses.quarter);
                        break;
                    case 2:
                        this.addClassesToElem(tuple[0], this._possibleClasses.half);
                        break;
                    case 4:
                        this.addClassesToElem(tuple[0], this._possibleClasses.fullWidth);
                        break;
                    default:
                        throw new ShapeDiverRowSizeDoesNotExists(tuple[1]);
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (elementSizeTuples_1_1 && !elementSizeTuples_1_1.done && (_c = elementSizeTuples_1.return)) _c.call(elementSizeTuples_1);
            }
            finally { if (e_5) throw e_5.error; }
        }
    };
    SDControlsResizeManager.prototype.resizeHandle = function (elements, useFullRowWidth) {
        var e_6, _a;
        useFullRowWidth ? this.handleFullWidth(elements) : this.handleResizable(elements);
        try {
            // if elements have children, resize children as well. This is in case of modal.
            for (var elements_4 = __values(elements), elements_4_1 = elements_4.next(); !elements_4_1.done; elements_4_1 = elements_4.next()) {
                var elem = elements_4_1.value;
                this.resizeHandle(elem.children, useFullRowWidth);
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (elements_4_1 && !elements_4_1.done && (_a = elements_4.return)) _a.call(elements_4);
            }
            finally { if (e_6) throw e_6.error; }
        }
    };
    SDControlsResizeManager.CLIENT_WIDTH_ADJUST_SIZE = 640;
    return SDControlsResizeManager;
}());
exports.SDControlsResizeManager = SDControlsResizeManager;
