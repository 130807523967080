
/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiParameterImplementationV2.1.js*
 *
 * ### Content
 *   * Implementation of the ShapeDiver 3D Viewer Parameter API V2.1
 *
 * @module ParameterApi
 * @author ShapeDiver <contact@shapediver.com>
 */

/**
* Imported messaging constant definitions
*/
var messagingConstants = require('../../../shared/constants/MessagingConstants');

/**
* Import GlobalUtils
*/
var GlobalUtils = require('../../../shared/util/GlobalUtils');

/**
* ApiInterfaceV2
*/
var ApiInterfaceV2 = new (require('../ApiInterfaceV2.1'))();

/**
* APIResponse factory
*/
const APIResponse = require('../ApiResponse');


////////////
////////////
//
// Parameter Interface
//
////////////
////////////

/**
 * ShapeDiver 3D Viewer API V2 - Parameter Interface
 * @class
 * @implements {module:ApiParameterInterface~ApiParameterInterface}
 * @param {Object} api - The global api object to which this api belongs
 * @param {Object} references.parameterHandler - Reference to the parameter handler
 */
var ParameterApi = function (_api, ___refs) {

  var that = this;

  // include enums from interface definition
  this.RESULT = ApiInterfaceV2.parameters.RESULT;
  this.TYPE = ApiInterfaceV2.parameters.TYPE;
  this.VISUALIZATION = ApiInterfaceV2.parameters.VISUALIZATION;
  this.EVENTTYPE = ApiInterfaceV2.parameters.EVENTTYPE;

  // shortcuts to handlers
  var _parameterHandler = ___refs.parameterHandler;

  /** @inheritdoc */
  this.get = function (filter) {
    let r = _parameterHandler.getParameterDefinitionsAndValues(filter);
    if (r) return APIResponse(null, r);
    else return APIResponse('Failed to get parameter definitions');
  };

  /** is it a ParameterUpdateObject */
  var _isParameterUpdateObject = function (o) {
    if (!o || typeof o !== 'object')
      return false;
    // at least one of id, idOrName, or name must exist
    if (!GlobalUtils.typeCheck(o.id, 'string') && !GlobalUtils.typeCheck(o.idOrName, 'string') && !GlobalUtils.typeCheck(o.name, 'string'))
      return false;
    // if plugin exists, it must be a string
    if (o.plugin && !GlobalUtils.typeCheck(o.plugin, 'string'))
      return false;
    // value must exist
    if (o.value === undefined)
      return false;
    // got it
    return true;
  };

  /** @inheritdoc */
  this.updateAsync = function (_values, payload) {
    var scope = 'ApiImplementationV2.ParameterApi.updateAsync';

    // parameter sanity check
    if (!Array.isArray(_values)) {
      _values = [_values];
    }
    for (let v of _values) {
      if (!_isParameterUpdateObject(v)) {
        return Promise.resolve(APIResponse('Invalid input, expecting array of ParameterUpdateObject.', null, payload));
      }
    }

    // create a deep copy of the value object, because we will add properties to it for our response
    let values = GlobalUtils.deepCopy(_values);

    // some values might be Blobs, we can't copy them, therefore we assign
    for (let i = 0, imax = _values.length; i < imax; i++) {
      if (_values[i].value instanceof Blob) {
        values[i].value = _values[i].value;
      }
    }

    // create a random process token id
    let token = messagingConstants.makeMessageToken();
    token.payload = payload;

    return new Promise(function (resolve) {

      // define function for parameter update
      let f_update = function () {

        // create a process callback and register it
        let processCallback = function (t, v) {
          if (v.hasOwnProperty('parts')) {
            for (let p of v.parts) {
              if (p.type == messagingConstants.messageDataTypes.PROCESS_SUCCESS) {
                _api.clearProcessCallback(subToken);
                resolve(APIResponse(null, values, payload));
                return;
              }
              if (p.type == messagingConstants.messageDataTypes.PROCESS_ABORT) {
                _api.clearProcessCallback(subToken);
                resolve(APIResponse(p.data, null, payload));
                return;
              }
              if (p.type == messagingConstants.messageDataTypes.PROCESS_ERROR) {
                _api.clearProcessCallback(subToken);
                resolve(APIResponse(p.data, null, payload));
                return;
              }
            }
          }
        };
        let subToken = _api.setProcessCallback(token.id, processCallback);

        // call parameterHandler
        var r = _parameterHandler.setMultipleParameterValues(values, token);

        // in case of error in parameterHandler, report it and immediately stop subscription
        if (r.err) {
          _api.error(scope, '_parameterHandler.setMultipleParameterValues returned error', r);
          _api.clearProcessCallback(subToken);
          resolve(APIResponse({ m: 'Failed to set parameter values', d: r }, null, payload));
          return;
        }
        else if (r.warn) {
          _api.warn(scope, '_parameterHandler.setMultipleParameterValues returned warning', r);
        }

        // copy result values for each parameter for returning them to caller
        for (let p of r.params) {
          values.find((v) => (v.id === p.id && v.plugin === p.plugin)).result = p.result;
        }

        // wait for process message (see above)
      };

      // check for parameters which require upload
      let requiresPriorUpload = _parameterHandler.updateRequiresPriorUpload(values);
      if (requiresPriorUpload === undefined) {
        resolve(APIResponse('Parameter not found', null, payload));
      } else if (requiresPriorUpload === false) {
        f_update();
      } else {
        // in case there are, call the upload
        _parameterHandler.priorUpload(values)
          .then(
            function (r) {
              if (r.err) {
                _api.error(scope, '_parameterHandler.priorUpload returned error', r);
                resolve(APIResponse({ m: 'Failed to upload blobs', d: r }, null, payload));
              } else {
                f_update();
              }
            },
            function (error) {
              _api.error(scope, '_parameterHandler.priorUpload failed', error);
              resolve(APIResponse({ m: 'Failed to upload blobs', d: error }, null, payload));
            }
          );
      }
    });
  };

  /** @inheritdoc */
  this.canGoBackInHistory = function () {
    return _parameterHandler.canGoBackInHistory();
  };

  /** @inheritdoc */
  this.canGoForwardInHistory = function () {
    return _parameterHandler.canGoForwardInHistory();
  };

  /** @inheritdoc */
  this.goBackInHistoryAsync = function (payload) {
    let scope = 'ApiImplementationV2.goBackInHistoryAsync';
    // create a random process token id
    let token = messagingConstants.makeMessageToken();
    token.payload = payload;
    // recall history
    return new Promise(function (resolve) {
      // create a process callback and register it
      let processCallback = function (t, v) {
        if (v.hasOwnProperty('parts')) {
          for (let p of v.parts) {
            if (p.type == messagingConstants.messageDataTypes.PROCESS_SUCCESS) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(null, _parameterHandler.getParameterState(), payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ABORT) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ERROR) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
          }
        }
      };
      let subToken = _api.setProcessCallback(token.id, processCallback);
      //
      if (!_parameterHandler.goBackInHistory(token)) {
        _api.error(scope, '_parameterHandler.goBackInHistory returned false');
        _api.clearProcessCallback(subToken);
        resolve(APIResponse('Failed to go back in history', null, payload));
        return;
      }
      // wait for process message (see above)
    });
  };

  /** @inheritdoc */
  this.goForwardInHistoryAsync = function (payload) {
    let scope = 'ApiImplementationV2.goForwardInHistoryAsync';
    // create a random process token id
    let token = messagingConstants.makeMessageToken();
    token.payload = payload;
    // recall history
    return new Promise(function (resolve) {
      // create a process callback and register it
      let processCallback = function (t, v) {
        if (v.hasOwnProperty('parts')) {
          for (let p of v.parts) {
            if (p.type == messagingConstants.messageDataTypes.PROCESS_SUCCESS) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(null, _parameterHandler.getParameterState(), payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ABORT) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
            if (p.type == messagingConstants.messageDataTypes.PROCESS_ERROR) {
              _api.clearProcessCallback(subToken);
              resolve(APIResponse(p.data, null, payload));
              return;
            }
          }
        }
      };
      let subToken = _api.setProcessCallback(token.id, processCallback);
      //
      if (!_parameterHandler.goForwardInHistory(token)) {
        _api.error(scope, '_parameterHandler.goForwardInHistory returned false');
        _api.clearProcessCallback(subToken);
        resolve(APIResponse('Failed to go forward in history', null, payload));
        return;
      }
      // wait for process message (see above)
    });
  };

  /** @inheritdoc */
  this.updateProperties = function (definitions) {
    return APIResponse(null, _parameterHandler.updateMultipleParameters(definitions));
  };

  /** @inheritdoc */
  this.addEventListener = function (type, cb) {
    // check if event type is supported
    if (!Object.keys(that.EVENTTYPE).find((k) => (that.EVENTTYPE[k] === type)))
      return APIResponse('Unsupported event type');
    // compose topic and subscribe to message stream
    let t = messagingConstants.messageTopics.PARAMETER + '.' + type;
    let subtokens = _api.subscribeToMessageStream(t, function (topic, msg) {
      // create event object, add common event properties
      let event = new CustomEvent(type);
      event.api = msg.api;
      if (msg.token) event.token = msg.token;
      event.parameter = {};
      // get relevant data parts from message, add special event properties
      let partTypes = {};
      partTypes[messagingConstants.messageDataTypes.PARAMETER_DEFINITION] = null; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.PARAMETER_UPDATE] = null; // null: copy all properties
      for (let pt in partTypes) {
        let part = msg.getUniquePartByType(pt);
        if (part) {
          if (part.data) part = part.data;
          // add special event properties
          let props = partTypes[pt] ? partTypes[pt] : part;
          for (let k in props) {
            event.parameter[k] = part[k];
          }
        }
      }
      // invoke callback (exception handling takes place in _api.subscribeToMessageStream)
      cb(event);
    });
    return APIResponse(null, subtokens);
  };

  /** @inheritdoc */
  this.removeEventListener = function (token) {
    return APIResponse(null, _api.unsubscribeFromMessageStream(token));
  };

};

module.exports = ParameterApi;