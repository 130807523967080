"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseLightPropertySettingComponent_1 = require("./BaseLightPropertySettingComponent");
var ColorLightPropertySettingComponent = /** @class */ (function (_super) {
    __extends(ColorLightPropertySettingComponent, _super);
    function ColorLightPropertySettingComponent(api, settingDefinition, parent, componentFactory, dragControls) {
        var _this = _super.call(this, api, settingDefinition, componentFactory.createShapeDiverSetting({
            id: settingDefinition.id,
            name: settingDefinition.name,
            type: "color" /* COLOR */,
            value: settingDefinition.value,
            order: settingDefinition.order,
            groupId: settingDefinition.groupId,
            lightDef: settingDefinition.lightDef,
        }, parent), dragControls) || this;
        _this._component.parameterDefinition.update = function (v) {
            var lightDef = _this.getLightDef();
            lightDef.properties[_this.id] = v;
            _this._api.scene.lights.updateLight(lightDef);
        };
        return _this;
    }
    return ColorLightPropertySettingComponent;
}(BaseLightPropertySettingComponent_1.BaseLightPropertySettingComponent));
exports.ColorLightPropertySettingComponent = ColorLightPropertySettingComponent;
