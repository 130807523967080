"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ModalSettingComponent_1 = require("../ModalSettingComponent");
var HTMLSettingsHelper_1 = require("../../settings/HTMLSettingsHelper");
var SaveLightSceneModalSettingComponent = /** @class */ (function (_super) {
    __extends(SaveLightSceneModalSettingComponent, _super);
    // #endregion Properties (4)
    // #region Constructors (1)
    function SaveLightSceneModalSettingComponent(api, settingDefinition, _parent, componentFactory, _controlsSettings, _events, _shapeDiverDragControls) {
        var _this = _super.call(this, api, {
            type: "modal" /* MODAL */,
            setting: "scene.add.light",
            id: settingDefinition.id,
            order: settingDefinition.order,
            name: "Save Light Scene",
            title: "Save the current configuration of light sources. Apply settings afterwards to store the configuration in the model.",
            groupId: settingDefinition.groupId,
        }, _parent, componentFactory) || this;
        _this._parent = _parent;
        _this._controlsSettings = _controlsSettings;
        _this._events = _events;
        _this._shapeDiverDragControls = _shapeDiverDragControls;
        // #region Properties (4)
        _this._saveId = "jq-add-lights-scene-modal-save";
        _this._confirmId = "jq-add-lights-scene-modal-confirm";
        _this._cancelId = "jq-add-lights-scene-modal-overwrite-cancel";
        _this._overwriteContent = "<label>The scene already exists. Do you want to overwrite it?</label>\n                          <p class=\"uk-text-right\">\n                            <button id=\"" + _this._confirmId + "\" data-overwrite=\"true\" class=\"uk-button uk-button-default sd-control-button\" type=\"button\">Yes</button>\n                            <button id=\"" + _this._cancelId + "\" class=\"uk-button uk-button-default\" type=\"button\">No</button>\n                          </p>";
        _this.component.content = _this.createModalContent(_this.getNameContent());
        _this.component.onOpen(function () { return _this.onModalOpen(); });
        _this.bindEvents();
        return _this;
    }
    // #endregion Constructors (1)
    // #region Private Methods (8)
    SaveLightSceneModalSettingComponent.prototype.bindEvents = function () {
        var _this = this;
        var saveElem = document.getElementById(this._saveId);
        if (saveElem) {
            saveElem.onclick = function () { return _this.customUpdate(); };
        }
        var ovewriteElem = document.getElementById(this._confirmId);
        if (ovewriteElem) {
            ovewriteElem.onclick = function () { return _this.customUpdate(); };
        }
    };
    SaveLightSceneModalSettingComponent.prototype.bindExistingSceneDropdownChanged = function () {
        var existing = document.getElementById("jq-add-lights-scene-existing");
        if (existing !== null) {
            existing.onchange = function () { return document.getElementById("jq-add-lights-scene-name").value = existing.value; };
        }
    };
    SaveLightSceneModalSettingComponent.prototype.createModalContent = function (nameContent) {
        return "<div class=\"uk-modal-dialog uk-modal-body\">\n            <h2 class=\"uk-modal-title\">Save Light Scene</h2>\n\n              <form id=\"jq-add-lights-scenes-content\" class=\"uk-form-horizontal\">\n                " + nameContent + "\n              </form>\n              <div id=\"jq-add-lights-scenes-alert\">\n\n              </div>\n            </div>";
    };
    SaveLightSceneModalSettingComponent.prototype.customUpdate = function () {
        var _this = this;
        var _a;
        var isOverwrite = (_a = document
            .getElementById(this._confirmId)) === null || _a === void 0 ? void 0 : _a.getAttribute("data-overwrite");
        var scenes = this._api.scene.lights.getAllLightScenes().data;
        var nameElem = (document.getElementById("jq-add-lights-scene-name"));
        if (nameElem) {
            this._name = nameElem.value;
        }
        if (!isOverwrite) {
            var alertHtml = document.getElementById("jq-add-lights-scenes-alert");
            if (!this._name) {
                if (alertHtml) {
                    alertHtml.innerHTML = HTMLSettingsHelper_1.HTMLSettingsHelper.createAlert("Please specify a name for the light scene");
                }
                this.bindEvents();
                return;
            }
            else if (this.isDefaultOrLegacyScene(this._name)) {
                if (alertHtml) {
                    alertHtml.innerHTML = HTMLSettingsHelper_1.HTMLSettingsHelper.createAlert("Light scene \"" + this._name + "\" cannot be overriden or saved. Please use another name for light scene");
                }
                this.bindEvents();
                return;
            }
            else {
                if (alertHtml) {
                    alertHtml.innerHTML = "";
                }
            }
        }
        if (isOverwrite || !scenes.some(function (x) { return x == _this._name; })) {
            this._api.scene.lights.saveLightScene(this._name);
            this._component.close();
            document.getElementById("jq-add-lights-scenes-content").innerHTML = this.getNameContent();
            this._events.trigger("light.scene.created" /* LIGHT_SCENE_CREATED */);
        }
        else {
            document.getElementById("jq-add-lights-scenes-content").innerHTML = this._overwriteContent;
            document.getElementById(this._cancelId).onclick = function () {
                document.getElementById("jq-add-lights-scenes-content").innerHTML = _this.getNameContent();
            };
        }
        this.bindEvents();
    };
    SaveLightSceneModalSettingComponent.prototype.getNameContent = function () {
        var _this = this;
        var savedScenes = this._api.scene.lights.getAllLightScenes().data.filter(function (x) { return !_this.isDefaultOrLegacyScene(x); });
        var currentScene = this._api.scene.lights.getLightScene().data;
        var savedSceneContent = '';
        if (savedScenes.length > 0) {
            savedSceneContent = "<div class=\"uk-margin\">\n                              <label class=\"uk-form-label\" for=\"form-horizontal-select\">Saved Scenes:</label>\n                              <div class=\"uk-form-controls\">\n                                  <select class=\"uk-select\" id=\"jq-add-lights-scene-existing\">\n                                      " + savedScenes.map(function (x) { return "<option " + (_this.isDefaultOrLegacyScene(x) ? 'disabled' : '') + " " + (savedScenes.some(function (x) { return x === currentScene.id; }) ? 'selected' : '') + ">" + x + "</option>"; }).join("") + "\n                                  </select>\n                              </div>\n                            </div>";
        }
        return savedSceneContent + "\n            <div class=\"uk-margin\">\n              <label class=\"uk-form-label\" for=\"form-horizontal-text\">Save Current Scene As:</label>\n              <div class=\"uk-form-controls\">\n                  <input id=\"jq-add-lights-scene-name\" class=\"uk-input\" type=\"text\" placeholder=\"type name ...\">\n              </div>\n            </div>\n            <p class=\"uk-text-right\">\n              <button class=\"uk-button uk-button-default uk-modal-close\" type=\"button\">Cancel</button>\n              <button id=\"" + this._saveId + "\" class=\"uk-button uk-button-default sd-control-button\" type=\"button\">Save</button>\n            </p>";
    };
    SaveLightSceneModalSettingComponent.prototype.isDefaultOrLegacyScene = function (sceneName) {
        return sceneName === "default" || sceneName === "legacy";
    };
    SaveLightSceneModalSettingComponent.prototype.moveNameIndex = function (name) {
        var index = name.lastIndexOf("_");
        if (index > -1) {
            var number = name.substr(index + 1);
            if (isNaN(number) == false) {
                number = Number(number);
                number++;
                name = name.replace(name.substr(index), "");
                return name + "_" + number;
            }
        }
        return name + "_1";
    };
    SaveLightSceneModalSettingComponent.prototype.onModalOpen = function () {
        var lightScene = this._api.scene.lights.getLightScene().data;
        var name = this.resolveNameIfDuplicate("scene");
        if (this._api.scene.lights.getAllLightScenes().data.some(function (x) { return x === lightScene.id; })) {
            name = this.resolveNameIfDuplicate(lightScene.id);
        }
        var nameInputField = document.getElementById("jq-add-lights-scene-name");
        if (nameInputField !== null) {
            nameInputField.value = name;
            var dropdownInputField = document.getElementById("jq-add-lights-scene-existing");
            if (dropdownInputField !== null && !this.isDefaultOrLegacyScene(lightScene.id)) {
                dropdownInputField.value = lightScene.id;
            }
        }
        this.bindExistingSceneDropdownChanged();
        this.bindEvents();
    };
    SaveLightSceneModalSettingComponent.prototype.resolveNameIfDuplicate = function (name) {
        var scenes = this._api.scene.lights.getAllLightScenes().data;
        if (scenes.some(function (x) { return x == name; })) {
            // check if extension is same or name is same
            while (scenes.some(function (x) { return x == name; })) {
                name = this.moveNameIndex(name);
            }
        }
        return name;
    };
    return SaveLightSceneModalSettingComponent;
}(ModalSettingComponent_1.ModalSettingComponent));
exports.SaveLightSceneModalSettingComponent = SaveLightSceneModalSettingComponent;
