/**
 * @file The LightApi is a property of the ViewportApi and must be used by all corresponding ViewportApis.
 *       It is used for all light related functionality.
 *
 * @module LightApi
 * @author Michael Oppitz
 */
let LightApi = function (_api, ___refs) {

  const GLOBAL_UTILS = require('../../../shared/util/GlobalUtils'),
        LightApiInterface = require('../../interfaces/api/LightApiInterface'),
        APIResponse = require('../../../api/v2/ApiResponse');


  let _threeDManager = ___refs.threeDManager;

  /**
   * @extends module:LightApiInterface~LightApiInterface
   * @lends module:LightApi~LightApi
   */
  class LightApi extends LightApiInterface {

    /**
     * ### ShapeDiver Viewer - Light API
     *
     * The LightApi implements all functionality related to the lights.
     * @constructs module:LightApi~LightApi
     */
    constructor() {
      super();

      this.utils = _api.utils;

      /**
      * Enum for supported light types.
      * @readonly
      * @enum {module:LightApiInterface~LightApiInterface#LIGHTTYPE}
      */
      this.LIGHTTYPE = {
        /**  ambient light */
        AMBIENT : 'ambient',
        /**  directional light */
        DIRECTIONAL : 'directional',
        /** point light */
        HEMISPHERE : 'hemisphere',
        /** point light */
        POINT : 'point',
        // /** rectangle area light */
        // RECTAREA : 'rectarea',
        /** spot light */
        SPOT : 'spot',
        /** flash light */
        FLASH : 'flash',
      };

      /**
       * Enum for predefined light scenes.
       * @readonly
       * @enum {module:LightApiInterface~LightApiInterface#LIGHTSCENE}
       */
      this.LIGHTSCENE = {
        /** the default light scene */
        DEFAULT: 'default',
        /** the legacy light scene */
        LEGACY: 'legacy'
      };

      this.updateLight = this.updateLight.bind(this);
      this.getLight = this.getLight.bind(this);
      this.removeLight = this.removeLight.bind(this);
      this.setLightSceneFromID = this.setLightSceneFromID.bind(this);
      this.setLightSceneFromDefinition = this.setLightSceneFromDefinition.bind(this);
      this.getLightScene = this.getLightScene.bind(this);
      this.getAllLightScenes = this.getAllLightScenes.bind(this);
      this.saveLightScene = this.saveLightScene.bind(this);
    }


    /** @inheritdoc */
    updateLight(definition) {
      return APIResponse(null, _threeDManager.lightHandler.updateLight(definition));
    }

    /** @inheritdoc */
    getLight(id) {
      return APIResponse(null, _threeDManager.lightHandler.getLight(id));
    }

    /** @inheritdoc */
    removeLight(id) {
      return APIResponse(null, _threeDManager.lightHandler.removeLight(id));
    }

    /** @inheritdoc */
    setLightSceneFromID(id) {
      return APIResponse(null, _threeDManager.lightHandler.setLightSceneFromID(id));
    }

    /** @inheritdoc */
    setLightSceneFromDefinition(scene) {
      return APIResponse(null, _threeDManager.lightHandler.setLightSceneFromDefinition(scene));
    }

    /** @inheritdoc */
    getLightScene(id) {
      return APIResponse(null, _threeDManager.lightHandler.getLightScene(id));
    }

    /** @inheritdoc */
    getAllLightScenes() {
      return APIResponse(null, _threeDManager.lightHandler.getAllLightScenes());
    }

    /** @inheritdoc */
    saveLightScene(id) {
      return APIResponse(null, _threeDManager.lightHandler.saveLightScene(id));
    }
  }
  return new LightApi();
};

module.exports = LightApi;
