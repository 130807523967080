/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ViewerApp.js*
 *
 * ### Content
 *   * Core application class which combines
 *   * a 3D scene
 *   * user interface elements
 *   * plugins
 *   * a public API
 *
 * @module ViewerApp
 * @author Alex Schiftner <alex@shapediver.com>
 */


/**
 * Import constants
 */
var viewerAppConstants = require('./ViewerAppConstants');

/**
 * Import GlobalUtils
 */
var GlobalUtils = require('../shared/util/GlobalUtils');


let StateDashboardLibrary = require('../modules/shapediver-state-dashboard/StateDashboard').StateDashboardLibrary;


/**
 * Globally required polyfills
 */
require('../polyfills/CustomEvent');

/**
 * Constructor of the viewer application
 * @class
 *
 * @mixes module:GlobalMixin~GlobalMixin
 * @mixes module:SettingsMixin~SettingsMixin
 * @mixes module:LoggingMixin~LoggingMixin
 * @mixes module:MessagingMixin~MessagingMixin
 * @mixes module:ViewerAppLoggingPartial~ViewerAppLoggingPartial
 * @mixes module:ViewerAppMessagingPartial~ViewerAppMessagingPartial
 * @mixes module:ViewerAppPluginManager~ViewerAppPluginManager
 *
 * @param {Object} [settings] - Initial settings to be used
 * @param {HtmlElement} [settings.container] - The container to which we will render
 * @param {Boolean} [settings.blurSceneWhenBusy] - Blur or don't blur the scene while a process is busy
 * @param {module:ApiInterfaceV2~ApiInterfaceV2#Color} [settings.defaultMaterial.color] - Color of the default material
 * @param {Number} [settings.defaultMaterial.bumpAmplitude] - Bump amplitude of the default material
 * @param {Number} [settings.loggingLevel] - Initial logging level
 * @param {Number} [settings.messageLoggingLevel] - Initial logging level for messages
 * @param {Boolean} [settings.showScene=false] - Set to true for showing the scene right away
 * @param {module:ViewerAppConstants~ShowSceneModes} [settings.showSceneMode=ON_FIRST_PLUGIN] - When to fade in the 3D scene, by default the scene will be faded in once the first plugin has finished adding geometry to the scene
 * @param {String} [settings.showSceneTransition] - Transition to use when fading in the 3D scene
 * @param {Boolean} [settings.strictMode=false] - Run parameters manager in strict mode? 
 * @param {Boolean} [settings.useModelSettings=true] - Apply the model settings once they are loaded?
 */
var ViewerApp = function (___settings) {

  var that = this;

  ////////////
  ////////////
  //
  // Global prototype
  //
  ////////////
  ////////////

  require('../shared/mixins/GlobalMixin').call(this);

  ////////////
  ////////////
  //
  // Settings
  //
  ////////////
  ////////////

  require('../shared/mixins/SettingsMixin').call(this, ___settings, viewerAppConstants.defaultSettings, 'app', [
    'container',
    'containerControls',
    'containerSettings',
    'resizeHandler'
  ]);
  let id = GlobalUtils.createRandomId();
  that.updateSetting('viewerRuntimeId', id);
  StateDashboardLibrary.getInstance(id);


  ////////////
  ////////////
  //
  // Logging
  //
  // register standard logging functions,
  // and replace default logging to console by pub/sub scheme
  //
  ////////////
  ////////////

  // mix in global logging functions
  require('../shared/mixins/LoggingMixin').call(this);

  // mix in logging functions
  require('./partials/ViewerAppLoggingPartial').call(this);


  // create a pseudo logging handler to object, to pass on to childs
  // replaces three js logger interface, each function gets list of arguments as parameter, containg various information about webgl errors.
  let _loggingHandler = {
    error: that.error,
    warn: that.warn,
    info: that.info,
    debug: that.debug,
    log: that.log,
  };

  // replaces default three js implementation of logging handler. Which just logs to console.
  THREE.Extensions.Logger.bind(_loggingHandler);

  /**
   * Replaces THREEJS default settings interface.
   * This just gives outside control to various three js settings.
   * @param {Function} debug - should return a boolean. If true threejs is using various information logs, such as validity of shader, compile information etc...
   * @param {Function} allowedCachedPrograms - maximum number of cached programs on gpu. Whenever viewer parameter is changed material is recreated and old ones are deleted.
   * this in turn results in shader recreation which takes significant times and slow the viewer down. Allowed cached programs allows materials to live beetween parameter changes
   * by caching them and not deleting them. If cached program counts gets above given number, cache is released in order to make room for new ones if necessary, just as precation, although this should actually never
   * be the case, unless large number of materials and lights are defined.
   */
  let _settingsHandler = {
    debug: function () {
      return false;
    },
    allowedCachedPrograms: function () {
      return 50;
    }
  }
  THREE.Extensions.Settings.bind(_settingsHandler);

  ////////////
  ////////////
  //
  // General messaging via pub/sub
  //
  ////////////
  ////////////

  // mix in global messaging functions
  require('../shared/mixins/MessagingMixin').call(this);

  // mix in messaging functions
  require('./partials/ViewerAppMessagingPartial').call(this);

  ////////////
  ////////////
  //
  // Plugins
  //
  ////////////
  ////////////

  // mix in plugin functions - following the naming convention we settled on, we should rename ViewerAppPluginManager to ViewerAppPluginHandler
  this.pluginManager = require('./partials/ViewerAppPluginManager').call(that);

  ////////////
  ////////////
  //
  // Parameters
  //
  ////////////
  ////////////

  // mix in parameter functions - following the naming convention we settled on, we should rename ViewerAppParameterManager to ViewerAppParameterHandler
  this.parameterManager = require('./partials/ViewerAppParameterManager').call(that, { pluginManager: that.pluginManager });

  ////////////
  ////////////
  //
  // Exports
  //
  ////////////
  ////////////

  // mix in export functionality - following the naming convention we settled on, we should rename ViewerAppExportManager to ViewerAppExportHandler
  this.exportManager = require('./partials/ViewerAppExportManager').call(that, { pluginManager: that.pluginManager, parameterManager: that.parameterManager });

  ////////////
  ////////////
  //
  // Interaction Group Manager
  //
  ////////////
  ////////////

  this.interactionGroupManager = new (require('../3d/InteractionGroupManager'))();

  ////////////
  ////////////
  //
  // Viewport Manager
  //
  ////////////
  ////////////

  this.viewportManager = new (require('../3d/viewportManager/ViewportManager'))(that, _loggingHandler, that.interactionGroupManager);

  ////////////
  ////////////
  //
  // Scene Geometry Manager
  //
  ////////////
  ////////////

  this.sceneGeometryManager = new (require('../3d/SceneGeometryManager'))(that.viewportManager,
    {
      loggingHandler: _loggingHandler,
      messagingHandler: {
        message: that.message,
        subscribeToMessageStream: that.subscribeToMessageStream,
        unsubscribeFromMessageStream: that.unsubscribeFromMessageStream
      }
    }
  );

  ////////////
  ////////////
  //
  // Container handler
  //
  ////////////
  ////////////

  this.viewportVisibilityHandler = require('./handlers/ViewportVisibilityHandler').call(that, {
    viewerApp: that,
    viewportManager: that.viewportManager,
    sceneGeometryManager: that.sceneGeometryManager
  });

  // provide access to container to plugins
  this.getContainer = function () {
    let container = that.viewportManager.getContainers();
    return container.length === 1 ? container[0] : container;
  };

  ////////////
  ////////////
  //
  // Scene manager
  //
  ////////////
  ////////////

  this.sceneManager = require('./partials/ViewerAppSceneManager').call(that, { sceneGeometryManager: that.sceneGeometryManager });

  ////////////
  ////////////
  //
  // Process status handler
  //
  ////////////
  ////////////

  this.processStatusHandler = require('./handlers/ProcessStatusHandler').call(that, { viewportManager: that.viewportManager });

  ////////////
  ////////////
  //
  // AR bridge
  //
  ////////////
  ////////////

  if ( this.getSetting('arkitbridge') === true ) {
    this.arApi = (require('../ar/ArApiARKit'))({
      viewportManager: that.viewportManager,
      apiResponse: require('../api/v2/ApiResponse'),
      sceneGeometryManager: that.sceneGeometryManager,
      settings: that.getSection('ar'),
      container: ___settings.container,
      loggingHandler: _loggingHandler,
    });
  }

  ////////////
  ////////////
  //
  // API
  //
  ////////////
  ////////////

  /**
   * Create and return an API interface object
   * @public
   * @param {Object} options - Options for creating the API interface object
   * @param {string} [options.version] - Optional version of API to return
   * @param {String} [options.runtimeId] - Optional runtime id to use for the API instance
   * @return {Object} API object according to options
   */
  this.api = function (options) {
    var api;
    // We return API v1 by default for backwards compatibility,
    // even if this does not make much sense given the viewport setup
    options = options || { version: 1 };
    if (options.version && (options.version === 2 || options.version === '2')) {

      api = new (require('../api/v2/ApiImplementationV2.1.js'))({
        exportHandler: that.exportManager,
        parameterHandler: that.parameterManager,
        pluginHandler: that.pluginManager,
        processStatusHandler: that.processStatusHandler,
        sceneManager: that.sceneManager,
        settingsHandler: that.settingsHandler,
        viewportManager: that.viewportManager,
        sceneGeometryManager: that.sceneGeometryManager,
        interactionGroupManager: that.interactionGroupManager,
        arApi: that.arApi,
        messagingHandler: {
          message: that.message,
          subscribeToMessageStream: that.subscribeToMessageStream,
          unsubscribeFromMessageStream: that.unsubscribeFromMessageStream,
          getFailedStatusMessages: that.getFailedStatusMessages,
        },
        loggingHandler: _loggingHandler,
        app: that
      }, options);
    } else {
      api = new (require('../api/v1/ApiImplementationV1.js'))({
        exportHandler: that.exportManager,
        parameterHandler: that.parameterManager,
        pluginHandler: that.pluginManager,
        processStatusHandler: that.processStatusHandler,
        sceneManager: that.sceneManager,
        settingsHandler: that.settingsHandler,
        threeDManager: that.viewportManager.getApis()[0].threeDManager,
        viewportVisibilityHandler: that.viewportVisibilityHandler,
        messagingHandler: {
          message: that.message,
          subscribeToMessageStream: that.subscribeToMessageStream,
          unsubscribeFromMessageStream: that.unsubscribeFromMessageStream,
          getFailedStatusMessages: that.getFailedStatusMessages,
        },
        loggingHandler: _loggingHandler,
        app: that
      }, options);
    }
    return api;
  };

  if (___settings.container) {
    that.viewportManager.createThreeDManager(___settings.container);
  }

  ////////////
  ////////////
  //
  // Settings handler - takes care of stored settings received from CommPlugin instances
  //
  ////////////
  ////////////

  // this.settingsHandler = require('./handlers/SettingsHandler').call(that, {
  //   viewportManager: that.viewportManager,
  //   parameterManager: that.parameterManager,
  //   exportManager: that.exportManager,
  //   pluginManager: that.pluginManager,
  //   app: that,
  // });

  this.settingsHandler = new (require('./handlers/SettingsHandler')).SettingsHandler(that);
  ////////////
  ////////////
  //
  // Message dispatching - last thing to do
  //
  ////////////
  ////////////

  /**
   * Initialize dispatching
   */
  this.setupDispatching(require('./conf/DefaultDispatching').call(that, {
    parameterManager: that.parameterManager,
    exportManager: that.exportManager,
    pluginManager: that.pluginManager,
    sceneManager: that.sceneManager,
    processStatusHandler: that.processStatusHandler,
    viewportVisibilityHandler: that.viewportVisibilityHandler,
    settingsHandler: that.settingsHandler,
  }));

  /**
   * Code example - how to log a message to server
   */
  //this.log(viewerAppConstants.loggingLevels.DEBUG_S, 'ViewerApp.ViewerApp', 'This is a test debug info string, could also be an object', {someMsg:'Further optional debug info'});
  //this.log(viewerAppConstants.loggingLevels.INFO_S, 'ViewerApp.ViewerApp', 'This is a test info string, could also be an object', {someMsg:'Further optional info'});
  //this.log(viewerAppConstants.loggingLevels.WARN_S, 'ViewerApp.ViewerApp', 'This is a test warning string, could also be an object', {someMsg:'Further optional warning'});
  //this.log(viewerAppConstants.loggingLevels.ERROR_S, 'ViewerApp.ViewerApp', 'This is a test error string, could also be an object', {someMsg:'Further optional error'});

  // initialize container
  this.viewportVisibilityHandler.init();

  // log build data
  if (___settings.build_version && ___settings.build_date) {
    if (typeof ___settings.brandedModeConsole === 'number') {
      let str = ___settings.brandedModeConsole === 1 ? 'SD' : 'ShapeDiver';
      console.log(str + ' Viewer App ' + ___settings.build_version + ', built ' + ___settings.build_date); // eslint-disable-line no-console
    } else {
      let styleA = 'font-size:16px; font-weight:200; letter-spacing:0.02em; line-height:1.4em; font-family:helvetica,arial; color: #0a12c6; background:url(https://s3.amazonaws.com/wp-shapediver-content/static/images/logomark_gradient%40400x17.png) no-repeat; display: block; background-size: 400px 17px; background-position: right bottom;';
      let styleB = 'font-size:10px; font-weight:200; letter-spacing:0.02em; line-height:1.4em; font-family:helvetica,arial; color: #be47fd;';
      let styleC = 'font-size:12px; font-weight:200; letter-spacing:0.02em; line-height:1.4em; font-family:helvetica,arial; color: #0a12c6;';
      let string = '%cShapeDiver Viewer App ' + ___settings.build_version + '           \n%c' + ___settings.build_date + '\n\n%cWe\'re hiring: https://shapediver.com/jobs';
      console.log(string, styleA, styleB, styleC); // eslint-disable-line no-console
    }
  }

  return this;
};

// here we could define public functions
//ViewerApp.prototype.

// export the constructor
module.exports = ViewerApp;
