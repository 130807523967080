"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var OrbitControlsEventDistribution = /** @class */ (function () {
    // #endregion Properties (1)
    // #region Constructors (1)
    function OrbitControlsEventDistribution(_settings, _controls, _cameraLogic) {
        this._settings = _settings;
        this._controls = _controls;
        this._cameraLogic = _cameraLogic;
        // #region Properties (1)
        this._active = {
            rotation: false,
            zoom: false,
            pan: false
        };
    }
    // #endregion Constructors (1)
    // #region Public Methods (14)
    OrbitControlsEventDistribution.prototype.onDown = function (event) {
        var _a = this.convertInput(event), x = _a.x, y = _a.y;
        var input = window.TouchEvent && event instanceof TouchEvent ? event.touches.length : event.button;
        var mapping = window.TouchEvent && event instanceof TouchEvent ? this._settings.getSetting('input').touch : this._settings.getSetting('input').mouse;
        if (input === mapping.rotate && this._settings.getSetting('enableRotation')) {
            this._cameraLogic.rotate(x, y, this._active.rotation, window.TouchEvent && event instanceof TouchEvent);
            this._active.rotation = true;
            this._active.pan = false;
            this._active.zoom = false;
        }
        if (input === mapping.pan && this._settings.getSetting('enablePan')) {
            this._cameraLogic.pan(x, y, this._active.pan, window.TouchEvent && event instanceof TouchEvent);
            this._active.rotation = false;
            this._active.pan = true;
            this._active.zoom = false;
        }
        if (input === mapping.zoom && this._settings.getSetting('enableZoom')) {
            var x1 = x, y1 = y;
            if (window.TouchEvent && event instanceof TouchEvent && this._settings.getSetting('input').touch.zoom === 2) {
                x1 = (event.touches[0].pageX - event.touches[1].pageX) / window.innerWidth * (window.innerWidth / window.innerHeight);
                y1 = (event.touches[0].pageY - event.touches[1].pageY) / window.innerHeight;
            }
            this._cameraLogic.zoom(x1, y1, this._active.zoom, window.TouchEvent && event instanceof TouchEvent);
            this._active.rotation = false;
            this._active.pan = false;
            this._active.zoom = true;
        }
    };
    OrbitControlsEventDistribution.prototype.onKey = function (event) {
        if (!this._settings.getSetting('enableKeyPan'))
            return;
        switch (event.keyCode) {
            case this._settings.getSetting('input').keys.up:
                this._cameraLogic.pan(0, 0, false, false);
                this._cameraLogic.pan(0, this._settings.getSetting('keyPanSpeed') * 0.05, true, false);
                event.preventDefault();
                event.stopPropagation();
                break;
            case this._settings.getSetting('input').keys.down:
                this._cameraLogic.pan(0, 0, false, false);
                this._cameraLogic.pan(0, -this._settings.getSetting('keyPanSpeed') * 0.05, true, false);
                event.preventDefault();
                event.stopPropagation();
                break;
            case this._settings.getSetting('input').keys.left:
                this._cameraLogic.pan(0, 0, false, false);
                this._cameraLogic.pan(this._settings.getSetting('keyPanSpeed') * 0.05, 0, true, false);
                event.preventDefault();
                event.stopPropagation();
                break;
            case this._settings.getSetting('input').keys.right:
                this._cameraLogic.pan(0, 0, false, false);
                this._cameraLogic.pan(-this._settings.getSetting('keyPanSpeed') * 0.05, 0, true, false);
                event.preventDefault();
                event.stopPropagation();
                break;
        }
    };
    OrbitControlsEventDistribution.prototype.onKeyDown = function (event) {
        this.onKey(event);
    };
    OrbitControlsEventDistribution.prototype.onMouseDown = function (event) {
        this.onDown(event);
    };
    OrbitControlsEventDistribution.prototype.onMouseMove = function (event) {
        this.onMove(event);
    };
    OrbitControlsEventDistribution.prototype.onMouseUp = function (event) {
        this.onUp(event);
    };
    OrbitControlsEventDistribution.prototype.onMouseWheel = function (event) {
        this.onWheel(event);
    };
    OrbitControlsEventDistribution.prototype.onMove = function (event) {
        var _a = this.convertInput(event), x = _a.x, y = _a.y;
        if (this._settings.getSetting('enableRotation') && this._active.rotation)
            this._cameraLogic.rotate(x, y, this._active.rotation, window.TouchEvent && event instanceof TouchEvent);
        if (this._settings.getSetting('enablePan') && this._active.pan)
            this._cameraLogic.pan(x, y, this._active.pan, window.TouchEvent && event instanceof TouchEvent);
        if (this._settings.getSetting('enableZoom') && this._active.zoom) {
            var x1 = x, y1 = y;
            if (window.TouchEvent && event instanceof TouchEvent && this._settings.getSetting('input').touch.zoom === 2) {
                x1 = (event.touches[0].pageX - event.touches[1].pageX) / window.innerWidth * (window.innerWidth / window.innerHeight);
                y1 = (event.touches[0].pageY - event.touches[1].pageY) / window.innerHeight;
            }
            this._cameraLogic.zoom(x1, y1, this._active.zoom, window.TouchEvent && event instanceof TouchEvent);
        }
    };
    OrbitControlsEventDistribution.prototype.onTouchEnd = function (event) {
        this.onUp(event);
    };
    OrbitControlsEventDistribution.prototype.onTouchMove = function (event) {
        this.onMove(event);
    };
    OrbitControlsEventDistribution.prototype.onTouchStart = function (event) {
        this.onDown(event);
    };
    OrbitControlsEventDistribution.prototype.onUp = function (event) {
        this._active.rotation = false;
        this._active.zoom = false;
        this._active.pan = false;
    };
    OrbitControlsEventDistribution.prototype.onWheel = function (event) {
        if (!this._settings.getSetting('enableZoom'))
            return;
        var delta = 0;
        if (event.deltaY !== undefined) {
            // WebKit / Opera / Explorer 9
            delta = -event.deltaY;
        }
        else if (event.detail !== undefined) {
            // Firefox
            delta = -event.detail;
        }
        // convert to 2 screen coordinates that are far enough
        if (Math.sign(delta) > 0) {
            this._cameraLogic.zoom(0, 0, false, false);
            this._cameraLogic.zoom(1, 0, true, false);
        }
        else {
            this._cameraLogic.zoom(1, 0, false, false);
            this._cameraLogic.zoom(0, 0, true, false);
        }
    };
    OrbitControlsEventDistribution.prototype.reset = function () {
        this._active = {
            rotation: false,
            zoom: false,
            pan: false
        };
    };
    // #endregion Public Methods (14)
    // #region Private Methods (1)
    OrbitControlsEventDistribution.prototype.convertInput = function (event) {
        var aspect = window.innerWidth / window.innerHeight;
        if (event instanceof MouseEvent) {
            return {
                x: event.clientX / window.innerWidth * aspect,
                y: event.clientY / window.innerHeight
            };
        }
        else {
            return {
                x: event.touches[0].pageX / window.innerWidth * aspect,
                y: event.touches[0].pageY / window.innerHeight
            };
        }
    };
    return OrbitControlsEventDistribution;
}());
exports.OrbitControlsEventDistribution = OrbitControlsEventDistribution;
