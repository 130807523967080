"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SDControlsParameterLinker = /** @class */ (function () {
    // #region Constructors (1)
    function SDControlsParameterLinker(_api) {
        this._api = _api;
    }
    // #endregion Constructors (1)
    // #region Public Methods (1)
    SDControlsParameterLinker.prototype.createLinkIfDefined = function (parameterDef) {
        var _this = this;
        // @ts-ignore
        if (parameterDef.type === "group" /* GROUP */) {
            parameterDef.parameters.forEach(function (p) { return _this.createLinkIfDefined(p); });
        }
        if (parameterDef.link) {
            var link_1 = parameterDef.link;
            var choiceMap_1 = link_1.choiceMap;
            // set update callback depending on type
            // @ts-ignore
            if (parameterDef.type === "checklist" /* CHECKLIST */) {
                parameterDef.update = function (val) {
                    var idxArr = val.map(function (v) {
                        var idx = parameterDef.choices.indexOf(v);
                        return choiceMap_1 && choiceMap_1.length > idx ? choiceMap_1[idx] : idx;
                    });
                    _this._api.parameters.updateAsync({ id: link_1.id, idOrName: link_1.idOrName, name: link_1.name, plugin: link_1.plugin, value: idxArr });
                };
            }
            // @ts-ignore
            else if (parameterDef.type === "dropdown" /* DROPDOWN */) {
                parameterDef.update = function (val) {
                    var idx = parameterDef.choices.indexOf(val);
                    idx = choiceMap_1 && choiceMap_1.length > idx ? choiceMap_1[idx] : idx;
                    _this._api.parameters.updateAsync({ id: link_1.id, idOrName: link_1.idOrName, name: link_1.name, plugin: link_1.plugin, value: idx });
                };
            }
            else {
                parameterDef.update = function (val) { return _this._api.parameters.updateAsync({ id: link_1.id, idOrName: link_1.idOrName, name: link_1.name, plugin: link_1.plugin, value: val }); };
            }
        }
        return parameterDef;
    };
    return SDControlsParameterLinker;
}());
exports.SDControlsParameterLinker = SDControlsParameterLinker;
