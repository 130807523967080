"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var THREE = require("../../../../../../externals/three");
var OrthographicLogic = /** @class */ (function () {
    // #endregion Properties (10)
    // #region Constructors (1)
    function OrthographicLogic(_settings, _controls) {
        var _this = this;
        this._settings = _settings;
        this._controls = _controls;
        this._damping = {
            rotation: {
                time: 0,
                duration: 0,
                theta: 0,
                phi: 0
            },
            zoom: {
                time: 0,
                duration: 0,
                delta: 0
            },
            pan: {
                time: 0,
                duration: 0,
                offset: new THREE.Vector3()
            },
        };
        this._dollyDelta = 0;
        this._dollyEnd = 0;
        this._dollyStart = 0;
        this._panDelta = new THREE.Vector2();
        this._panEnd = new THREE.Vector2();
        this._panStart = new THREE.Vector2();
        this._settingsAdjustements = {
            damping: 1.0,
            movementSmoothness: 1.0,
            panSpeed: 2.0,
            zoomSpeed: 0.025,
        };
        this._touchAdjustements = {
            damping: 1.0,
            movementSmoothness: 1.0,
            panSpeed: 2.0,
            zoomSpeed: 50.0,
        };
        this._adjustedSettings = {
            damping: this._settings.getSetting('damping') * this._settingsAdjustements.damping,
            movementSmoothness: this._settings.getSetting('movementSmoothness') * this._settingsAdjustements.movementSmoothness,
            panSpeed: this._settings.getSetting('panSpeed') * this._settingsAdjustements.panSpeed,
            zoomSpeed: this._settings.getSetting('zoomSpeed') * this._settingsAdjustements.zoomSpeed,
        };
        Object.keys(this._adjustedSettings).forEach(function (element) {
            _this._settings.registerHook(element, function (v) {
                _this._adjustedSettings[element] = v * _this._settingsAdjustements[element];
                return true;
            });
        });
    }
    // #endregion Constructors (1)
    // #region Public Methods (4)
    OrthographicLogic.prototype.pan = function (x, y, active, touch) {
        if (touch) {
            x = x / window.devicePixelRatio;
            y = y / window.devicePixelRatio;
        }
        if (!active) {
            this._panStart.set(x, y);
        }
        else {
            this._panEnd.set(x, y);
            this._panDelta.subVectors(this._panEnd, this._panStart);
            if (this._panDelta.x === 0 && this._panDelta.y === 0)
                return;
            this._panStart.copy(this._panEnd);
            var offset = this.panDeltaToOffset(this._panDelta.multiplyScalar(this._adjustedSettings.panSpeed * (touch ? this._touchAdjustements.panSpeed : 1.0)));
            if (this._damping.pan.duration > 0) {
                if (offset.x < 0) {
                    offset.x = Math.min(offset.x, this._adjustedSettings.movementSmoothness * this._damping.pan.offset.x);
                }
                else {
                    offset.x = Math.max(offset.x, this._adjustedSettings.movementSmoothness * this._damping.pan.offset.x);
                }
                if (offset.y < 0) {
                    offset.y = Math.min(offset.y, this._adjustedSettings.movementSmoothness * this._damping.pan.offset.y);
                }
                else {
                    offset.y = Math.max(offset.y, this._adjustedSettings.movementSmoothness * this._damping.pan.offset.y);
                }
                if (offset.z < 0) {
                    offset.z = Math.min(offset.z, this._adjustedSettings.movementSmoothness * this._damping.pan.offset.z);
                }
                else {
                    offset.z = Math.max(offset.z, this._adjustedSettings.movementSmoothness * this._damping.pan.offset.z);
                }
            }
            var damping = 1 - Math.max(0.01, Math.min(1, this._adjustedSettings.damping));
            var framesOffsetX = (Math.log(1 / Math.abs(offset.x)) - 5 * Math.log(10)) / (Math.log(damping));
            var framesOffsetY = (Math.log(1 / Math.abs(offset.y)) - 5 * Math.log(10)) / (Math.log(damping));
            var framesOffsetZ = (Math.log(1 / Math.abs(offset.z)) - 5 * Math.log(10)) / (Math.log(damping));
            this._damping.pan.time = 0;
            this._damping.pan.duration = Math.max(framesOffsetX, Math.max(framesOffsetY, framesOffsetZ)) * 16.6666;
            this._damping.pan.offset = offset.clone();
            this._damping.zoom.duration = 0;
            this._controls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z), true);
            this._controls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z), true);
        }
    };
    OrthographicLogic.prototype.reset = function () {
        this._damping = {
            rotation: {
                time: 0,
                duration: 0,
                theta: 0,
                phi: 0
            },
            zoom: {
                time: 0,
                duration: 0,
                delta: 0
            },
            pan: {
                time: 0,
                duration: 0,
                offset: new THREE.Vector3()
            },
        };
        this._dollyDelta = 0;
        this._dollyEnd = 0;
        this._dollyStart = 0;
        this._panDelta = new THREE.Vector2();
        this._panEnd = new THREE.Vector2();
        this._panStart = new THREE.Vector2();
    };
    OrthographicLogic.prototype.update = function (time, manualInteraction) {
        if (manualInteraction === true) {
            this._damping.zoom.duration = 0;
            this._damping.pan.duration = 0;
        }
        var damping = 1 - Math.max(0.01, Math.min(1, this._adjustedSettings.damping));
        if (this._damping.pan.duration > 0) {
            if (this._damping.pan.time + time > this._damping.pan.duration) {
                this._damping.pan.time = this._damping.pan.duration;
                this._damping.pan.duration = 0;
            }
            else {
                this._damping.pan.time += time;
                var frameSinceStart = this._damping.pan.time / 16.6666;
                var offset = this._damping.pan.offset.clone().multiplyScalar(Math.pow(damping, frameSinceStart));
                this._controls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z));
                this._controls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z));
            }
        }
        else {
            this._damping.pan.time = 0;
        }
        if (this._damping.zoom.duration > 0) {
            if (this._damping.zoom.time + time > this._damping.zoom.duration) {
                this._damping.zoom.time = this._damping.zoom.duration;
                this._damping.zoom.duration = 0;
            }
            else {
                this._damping.zoom.time += time;
                var frameSinceStart = this._damping.zoom.time / 16.6666;
                var delta = this._damping.zoom.delta * Math.pow(damping, frameSinceStart);
                var newDistance = this._controls.getTargetWithManualUpdates().distanceTo(this._controls.getPositionWithManualUpdates())
                    * (1 - delta);
                var dir = new THREE.Vector3(), offset = new THREE.Vector3();
                dir.copy(this._controls.getTargetWithManualUpdates()).sub(this._controls.getPositionWithManualUpdates()).normalize();
                offset.copy(this._controls.getPositionWithManualUpdates().clone().add(dir.multiplyScalar(newDistance)));
                offset.sub(this._controls.getTargetWithManualUpdates());
                this._controls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z), true);
            }
        }
        else {
            this._damping.zoom.time = 0;
        }
    };
    OrthographicLogic.prototype.zoom = function (x, y, active, touch) {
        var distance = Math.sqrt(x * x + y * y);
        if (touch)
            distance = distance / window.devicePixelRatio;
        if (!active) {
            this._dollyStart = distance;
        }
        else {
            this._dollyEnd = distance;
            this._dollyDelta = this._dollyEnd - this._dollyStart;
            this._dollyStart = this._dollyEnd;
            if (this._damping.zoom.duration > 0) {
                if (this._dollyDelta < 0) {
                    this._dollyDelta = Math.min(this._dollyDelta, this._adjustedSettings.movementSmoothness * this._damping.zoom.delta);
                }
                else {
                    this._dollyDelta = Math.max(this._dollyDelta, this._adjustedSettings.movementSmoothness * this._damping.zoom.delta);
                }
            }
            var delta = this._dollyDelta * this._adjustedSettings.zoomSpeed * (touch ? this._touchAdjustements.zoomSpeed : 1.0);
            var damping = 1 - Math.max(0.01, Math.min(1, this._adjustedSettings.damping));
            var framesDelta = (Math.log(1 / Math.abs(this._dollyDelta)) - 5 * Math.log(10)) / (Math.log(damping));
            this._damping.zoom.time = 0;
            this._damping.zoom.duration = framesDelta * 16.6666;
            this._damping.zoom.delta = delta;
            this._damping.pan.duration = 0;
            var newDistance = this._controls.getTargetWithManualUpdates().distanceTo(this._controls.getPositionWithManualUpdates())
                * (1 - delta);
            var dir = new THREE.Vector3(), offset = new THREE.Vector3();
            dir.copy(this._controls.getTargetWithManualUpdates()).sub(this._controls.getPositionWithManualUpdates()).normalize();
            offset.copy(this._controls.getPositionWithManualUpdates().clone().add(dir.multiplyScalar(newDistance)));
            offset.sub(this._controls.getTargetWithManualUpdates());
            this._controls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z), true);
        }
    };
    // #endregion Public Methods (4)
    // #region Private Methods (1)
    OrthographicLogic.prototype.panDeltaToOffset = function (panDelta) {
        var offset = new THREE.Vector3();
        var panOffset = new THREE.Vector3();
        // perspective
        offset.copy(this._controls.getPositionWithManualUpdates()).sub(this._controls.getTargetWithManualUpdates());
        // we use only clientHeight here so aspect ratio does not distort speed
        // left
        var v1 = new THREE.Vector3();
        v1.setFromMatrixColumn(this._controls.camera.matrix, 0); // get X column of objectMatrix
        v1.multiplyScalar(-(panDelta.x * (this._controls.camera.right - this._controls.camera.left) * 0.5 / this._controls.camera.zoom));
        panOffset.add(v1);
        // up
        var v = new THREE.Vector3();
        v.setFromMatrixColumn(this._controls.camera.matrix, 1); // get Y column of objectMatrix
        v.multiplyScalar(panDelta.y * (this._controls.camera.right - this._controls.camera.left) * 0.5 / this._controls.camera.zoom);
        panOffset.add(v);
        return panOffset.clone();
    };
    return OrthographicLogic;
}());
exports.OrthographicLogic = OrthographicLogic;
;
