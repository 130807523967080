"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CameraType;
(function (CameraType) {
    CameraType[CameraType["MIN"] = 0] = "MIN";
    CameraType[CameraType["PERSPECTIVE"] = 0] = "PERSPECTIVE";
    CameraType[CameraType["TOP"] = 1] = "TOP";
    CameraType[CameraType["BOTTOM"] = 2] = "BOTTOM";
    CameraType[CameraType["RIGHT"] = 3] = "RIGHT";
    CameraType[CameraType["LEFT"] = 4] = "LEFT";
    CameraType[CameraType["BACK"] = 5] = "BACK";
    CameraType[CameraType["FRONT"] = 6] = "FRONT";
    CameraType[CameraType["AR"] = 7] = "AR";
    CameraType[CameraType["MAX"] = 7] = "MAX";
})(CameraType = exports.CameraType || (exports.CameraType = {}));
;
var ControlType;
(function (ControlType) {
    ControlType[ControlType["MIN"] = 0] = "MIN";
    ControlType[ControlType["ORBIT"] = 0] = "ORBIT";
    ControlType[ControlType["FPS"] = 1] = "FPS";
    ControlType[ControlType["ORTHOGRAPHIC"] = 2] = "ORTHOGRAPHIC";
    ControlType[ControlType["MAX"] = 2] = "MAX";
})(ControlType = exports.ControlType || (exports.ControlType = {}));
;
var CameraEventType;
(function (CameraEventType) {
    CameraEventType["CAMERA_START"] = "start";
    CameraEventType["CAMERA_MOVE"] = "move";
    CameraEventType["CAMERA_END"] = "end";
})(CameraEventType = exports.CameraEventType || (exports.CameraEventType = {}));
;
