"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseParameterComponent_1 = require("./BaseParameterComponent");
var BooleanInputCustomComponent_1 = require("../custom-components/BooleanInputCustomComponent");
var BooleanInputParameterComponent = /** @class */ (function (_super) {
    __extends(BooleanInputParameterComponent, _super);
    // #region Constructors (1)
    function BooleanInputParameterComponent(api, domManager, queue, container, parameter, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, api, domManager, queue, new BooleanInputCustomComponent_1.BooleanInputCustomComponent(parameter.id, {
            name: parameter.name,
            type: parameter.elementType,
            title: parameter.note,
            value: parameter.defval,
            order: parameter.order,
        }, container, domManager, queue), parameter, editMode) || this;
        _this._component.parameterDefinition.update = _this.updateAsync.bind(_this);
        return _this;
    }
    Object.defineProperty(BooleanInputParameterComponent.prototype, "children", {
        // #endregion Constructors (1)
        // #region Public Accessors (2)
        get: function () {
            return this._component.children;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BooleanInputParameterComponent.prototype, "hidden", {
        get: function () {
            return this._component.hidden;
        },
        set: function (v) {
            this._component.hidden = v;
            if (this._editMode) {
                if (this.inputComponent) {
                    var component = this._component;
                    v ? component.boolContainer.classList.add('sdv-hidden') : component.boolContainer.classList.remove('sdv-hidden');
                }
            }
            else {
                if (v) {
                    this.destroy();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    return BooleanInputParameterComponent;
}(BaseParameterComponent_1.BaseParameterComponent));
exports.BooleanInputParameterComponent = BooleanInputParameterComponent;
