/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ViewerAppPluginManager.js*
 *
 * ### Content
 *   * Plugin functionality of ViewerApp
 *
 * @module ViewerAppPluginManager
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Imported plugin constant definitions
 */
var pluginConstants = require('../../plugins/PluginConstants');

/**
 * Imported message prototype
 */
var MessagePrototype = require('../../shared/messages/MessagePrototype');

/**
 * Constructor of the ViewerAppPluginManager mixin
 * @mixin ViewerAppPluginManager
 * @author Alex Schiftner <alex@shapediver.com>
 */
var ViewerAppPluginManager = function() {

  var that = this;

  /**
   * Object for collecting public members, this object will be returned instead of the default "this"
   */
  var _o = {};

  /**
   * Private container for plugins
   */
  var _plugins = [];

  /**
   * Plugin message subscription tokens
   */
  var _pluginSubTokens = [];

  /**
   * Get plugin status description for all plugin instances known
   *
   * @public
   * @return {module:PluginConstantsGlobal~PluginStatusDescription[]} Array of plugin status descriptions
   */
  _o.getStatusDescription = function() {
    let r = [];
    _plugins.forEach((p) => {
      r.push(p.getStatusDescription());
    });
    return r;
  };

  /**
   * Register a Plugin
   *
   * Loads the plugin and hooks it up with the ViewerApp's pub/sub logging and messaging
   * @public
   * @param {Object} plugin - The plugin to load
   * @return {String} plugin id in case of success, nothing (undefined) on error
   */
  _o.registerPlugin = function(plugin) {
    let scope = 'ViewerAppPluginManager.registerPlugin';

    // check status of plugin
    if ( plugin.getStatus() !== pluginConstants.pluginStatuses.READY ) {
      that.error( scope, 'Unexpected plugin status before loading: ' + plugin.getStatus() );
      return;
    }

    // get runtime id of plugin, check whether it already exists
    var runtimeId = plugin.getRuntimeId();
    if ( _o.getPluginByRuntimeId(runtimeId) !== undefined ) {
      that.error( scope, 'Plugin with runtime id ' + runtimeId + ' already loaded' );
      return;
    }

    // inject our logging
    plugin.log = function() {
      // divide arguments into loggingLevel, scope, and msgs
      var args = (arguments.length === 1 ? [arguments[0]] : Array.apply(null, arguments));
      var loggingLevel = args[0];
      var scope = args[1];
      var msgs = args.slice(2);

      // prefix plugin shortName and runtimeId to scope
      let shortName = plugin.getShortName();
      let newScope = shortName + '.' + runtimeId + '.' + scope;

      that.log(loggingLevel, newScope, ...msgs);
    };

    // inject our messaging
    plugin.message = function(topic, msg) {
      // set origin of msg according to plugin runtime id
      if ( msg.origin === undefined || typeof msg.origin !== 'object' )
        msg.origin = {};
      msg.origin.plugin = runtimeId;
      // set creator of msg token if none exists
      if ( msg.token !== undefined && typeof msg.token === 'object' && msg.token.creator === undefined ) {
        msg.token.creator = {plugin : runtimeId};
      }
      // send message
      that.message(topic, msg);
    };

    // provide plugin with access to the API
    plugin.app = { api: that.api };

    // provide access to the used container
    plugin.app.getContainer = that.getContainer;

    // read plugin capabilities, do sth accordingly, e.g. set timeout to check later whether plugin has become active
    // we do not provide full access to manager or handlers, only to those member functions
    // which are required depending on the capabilities
    let capabilities = plugin.getCapabilities();
    // interactive editing
    if ( capabilities.includes(pluginConstants.pluginCapabilities.INTERACTIVE) ) {
      // we could limit this to the functionality for adding/removing geometry
      plugin.app.sceneGeometryManager = that.sceneGeometryManager;
      plugin.app.viewportManager = that.viewportManager;
    }
    // drag & drop
    if ( capabilities.includes(pluginConstants.pluginCapabilities.DRAGANDDROP) ) {
      // we could limit this to the functionality for drag & drop & highlighting
      plugin.app.sceneGeometryManager = that.sceneGeometryManager;
      plugin.app.viewportManager = that.viewportManager;
    }
    // message topic subscriptions
    let pluginSubTokens = [];
    if ( capabilities.includes(pluginConstants.pluginCapabilities.MESSAGECALLBACKS) && that.subscribeToMessageStream ) {
      let cbs = plugin.getMessageCallbacks();
      for (let cb in cbs) {
        let t = that.subscribeToMessageStream(cb, cbs[cb]);
        pluginSubTokens = pluginSubTokens.concat(t);
      }
    }

    // load plugin - override settings can be passed here
    if ( !plugin.load( {loggingLevel: that.getSetting('loggingLevel')} ) ) {
      that.error( scope, 'Failed to load plugin "' + plugin.getName() + '" with runtime id ' + runtimeId );
      return;
    }

    // again - check status of plugin
    if ( plugin.getStatus() !== pluginConstants.pluginStatuses.LOADED && plugin.getStatus() !== pluginConstants.pluginStatuses.ACTIVE ) {
      that.error( scope, 'Unexpected plugin status after loading: ' + plugin.getStatus() );
      return;
    }

    // tell the world that we just registered a new plugin
    that.message( pluginConstants.messageTopics.PLUGIN_REGISTERED, new MessagePrototype(
      pluginConstants.messageDataTypes.PLUGIN_RUNTIME_ID,
      runtimeId
    )
    );
    that.info(scope, 'Registered plugin "' + plugin.getName() + '" with capabilities ' + JSON.stringify(plugin.getCapabilities()) + ' and runtime id ' + plugin.getRuntimeId() );

    // remember plugin in container
    _plugins.push(plugin);
    _pluginSubTokens.push(pluginSubTokens);

    return runtimeId;
  };

  /**
   * Deregister a plugin
   * @public
   * @param {String} runtimeId - runtime id of the plugin to deregister
   * @return {Boolean} true in case of success, false otherwise
   */
  _o.deregisterPlugin = function(runtimeId) {
    let scope = 'ViewerAppPluginManager.deregisterPlugin';
    var idx = _plugins.findIndex( function(p) { return p.getRuntimeId() === runtimeId; } );
    if ( idx === -1 ) {
      that.error(scope, 'Plugin with runtimeId ' + runtimeId + ' not found');
      return false;
    }
    // #SS-39 to be implemented:
    //   deregister parameters of plugin
    //   deregister exports of plugin
    //   deregister data outputs of plugin
    //   remove geometry of plugin

    // unsubscribe from message topics
    if (that.unsubscribeFromMessageStream) {
      that.unsubscribeFromMessageStream(_pluginSubTokens[idx]);
    }
    // remove plugin from list
    var plugin = _plugins.splice(idx, 1)[0];
    _pluginSubTokens.splice(idx, 1);
    that.info(scope, 'Deregistered plugin "' + plugin.getName() + '" with capabilities ' + JSON.stringify(plugin.getCapabilities()) + ' and runtime id ' + plugin.getRuntimeId() );
    return true;
  };

  /**
   * Get runtime ids of all plugins currently registered
   */
  _o.getRuntimeIds = function() {
    let imax = _plugins.length;
    let ids = new Array(_plugins.length);
    for (let i=0; i<imax; i++) {
      ids[i] = _plugins[i].getRuntimeId();
    }
    return ids;
  };

  /**
   * Get plugin by its runtime id
   * Returns the first plugin found
   * @public
   * @param {String} runtimeId - runtime id of the plugin to get
   * @return {Object} first plugin object found on success, undefined on error
   */
  _o.getPluginByRuntimeId = function(runtimeId) {
    return _plugins.find( function(p) { return p.getRuntimeId() === runtimeId; } );
  };

  /**
   * Get plugin by its name
   * Returns the first plugin found
   * @public
   * @param {String} name - name of the plugin to get
   * @return {Object} first plugin object found on success, undefined on error
   */
  _o.getPluginByName = function(name) {
    return _plugins.find( function(p) { return p.getName() === name; } );
  };

  /**
   * Get plugin by its short name
   * Returns the first plugin found
   * @public
   * @param {String} shortName - short name of the plugin to get
   * @return {Object} first plugin object found on success, undefined on error
   */
  _o.getPluginByShortName = function(shortName) {
    return _plugins.find( function(p) { return p.getShortName() === shortName; } );
  };

  /**
   * Get plugin by one or more plugin capabilities
   * Returns the first plugin found
   * @public
   * @param {module:PluginConstantsGlobal~PluginCapability[]|module:PluginConstantsGlobal~PluginCapability} capabilites - plugin capabilities to look for
   * @return {Object} first plugin object found on success, undefined on error
   */
  _o.getPluginByCapabilities = function(capabilities) {
    if (!capabilities) capabilities = [];
    if (!Array.isArray(capabilities)) capabilities = [capabilities];
    return _plugins.find( function(p) {
      let pluginCapabilities = p.getCapabilities();
      return capabilities.every( (c) => (pluginCapabilities.includes(c)));
    });
  };

  /**
    * Delay in msec for retrying to send log messages to server
    */
  var _logDelay = 999;

  /**
    * Delay in msec which must pass between individual log messages in general
    */
  var _logInterval = 9999;

  /**
    * Promise chain for sending log messages in intervals
    */
  var _lastLogPromise = Promise.resolve();

  /**
    * Local function for handling log messages that should be sent to a server
    * Uses the first plugin found which has the pluginConstants.pluginCapabilities.LOGGING capability
    * @private
    * @param {String} topic - topic received from pub/sub
    * @param {Object[]} data - payload received from pub/sub
    */
  var _logToServer = function(topic, data) {
    let s = 'ViewerAppPluginManager._logToServer';
    // get logging level from topic
    let idx = topic.indexOf('.');
    let loggingLevel = Number(topic.slice(0,idx)) & pluginConstants.loggingLevels.LVLBITS;
    let scope = topic.slice(idx+1);

    // look for a plugin which has the capability to receive log messages
    let plugin = _plugins.find( function(p) {
      return p.getCapabilities().includes(pluginConstants.pluginCapabilities.LOGGING);
    });

    if ( plugin ) {
      _lastLogPromise = _lastLogPromise
        .finally(
          function() {
            that.debug(s, 'Logging to server', {
              loggingLevel: loggingLevel,
              scope: scope,
              data: data
            });
            // send log message
            if ( !Array.isArray(data) ) data = [data];
            plugin.logToServer(loggingLevel, scope, ...data).catch( (err) => {
              that.warn(s, err);
            });
            // reset _logDelay
            _logDelay = 999;
          }
        )
        .finally(
          // wait for at least _logInterval msec before sending the next log message
          function() {
            return new Promise(function(resolve) {
              setTimeout(resolve, _logInterval);
            });
          }
        )
      ;
    }
    else {
      that.debug(s, 'Delaying logging to server by ' + _logDelay + 'msec', {
        loggingLevel: loggingLevel,
        scope: scope,
        data: data
      });
      // queue the message if no plugin found for logging
      setTimeout(_logToServer, _logDelay, topic, data);
      // increase _logDelay
      _logDelay = _logDelay * 2;
    }
  };

  // subscribe to log stream
  if ( typeof that.subscribeToLogStream === 'function' ) {
    that.subscribeToLogStream([
      pluginConstants.loggingLevels.ERROR_S,
      pluginConstants.loggingLevels.WARN_S,
      pluginConstants.loggingLevels.INFO_S,
      pluginConstants.loggingLevels.DEBUG_S
    ], '', _logToServer);
  }

  return _o;
};

module.exports = ViewerAppPluginManager;
