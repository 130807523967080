"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Control parameter definition error.
 */
var ControlParameterDefinitionMissingPropertyError = /** @class */ (function (_super) {
    __extends(ControlParameterDefinitionMissingPropertyError, _super);
    // #region Constructors (1)
    function ControlParameterDefinitionMissingPropertyError(parameterDefinition, property) {
        return _super.call(this, "Custom parameter definition of type " + parameterDefinition.type + " is missing property " + property) || this;
    }
    return ControlParameterDefinitionMissingPropertyError;
}(Error));
var ControlParameterDefinitionWrongPropertyTypeError = /** @class */ (function (_super) {
    __extends(ControlParameterDefinitionWrongPropertyTypeError, _super);
    // #region Constructors (1)
    function ControlParameterDefinitionWrongPropertyTypeError(parameterDefinition, property, givenType, expectedType) {
        return _super.call(this, "Custom parameter definition property " + property + " has a wrong type assigned. Expected type: " + expectedType + ", current type: " + givenType + ". Parameter type: " + parameterDefinition.type) || this;
    }
    return ControlParameterDefinitionWrongPropertyTypeError;
}(Error));
var SDControlComponentsContracts = /** @class */ (function () {
    function SDControlComponentsContracts() {
    }
    // #region Public Methods (2)
    SDControlComponentsContracts.prototype.controlParameterDefinitionContract = function (parameterDefinition) {
        this.checkProperty(parameterDefinition, 'type', 'string');
        //@ts-ignore
        if (parameterDefinition.type === "checkbox" /* CHECKBOX */)
            this.checkboxContract(parameterDefinition);
        //@ts-ignore
        else if (parameterDefinition.type === "string" /* STRING */)
            this.stringContract(parameterDefinition);
        //@ts-ignore
        else if (parameterDefinition.type === "button" /* BUTTON */)
            this.buttonContract(parameterDefinition);
        //@ts-ignore
        else if (parameterDefinition.type === "checklist" /* CHECKLIST */)
            this.checklistContract(parameterDefinition);
        //@ts-ignore
        else if (parameterDefinition.type === "color" /* COLOR */)
            this.colorContract(parameterDefinition);
        //@ts-ignore
        else if (parameterDefinition.type === "dropdown" /* DROPDOWN */)
            this.dropdownContract(parameterDefinition);
        //@ts-ignore
        else if (parameterDefinition.type === "file" /* FILE */)
            this.fileContract(parameterDefinition);
        //@ts-ignore
        else if (parameterDefinition.type === "modal" /* MODAL */)
            this.modalContract(parameterDefinition);
        //@ts-ignore
        else if (parameterDefinition.type === "group" /* GROUP */)
            this.multipleContract(parameterDefinition);
        //@ts-ignore
        else if (parameterDefinition.type === "slider" /* SLIDER */)
            this.sliderContract(parameterDefinition);
        //@ts-ignore
        else if (parameterDefinition.type === "vector3" /* VECTOR3 */)
            this.vectorContract(parameterDefinition);
        //@ts-ignore
        else if (parameterDefinition.type === "statichtml" /* STATICHTML */)
            this.staticHTMLContract(parameterDefinition);
    };
    SDControlComponentsContracts.prototype.settingDefinitionContract = function (settingDefinition) {
        this.checkProperty(settingDefinition, 'type', 'string');
        this.checkProperty(settingDefinition, 'id', 'string');
        if (settingDefinition.type === "statichtml" /* STATICHTML */)
            this.staticHTMLContract(settingDefinition);
    };
    // #endregion Public Methods (2)
    // #region Private Methods (14)
    SDControlComponentsContracts.prototype.buttonContract = function (parameterDefinition) {
        this.checkProperty(parameterDefinition, 'update', 'function');
    };
    SDControlComponentsContracts.prototype.checkArrayProperty = function (parameterDefinition, property) {
        this.checkProperty(parameterDefinition, property, 'object');
        if (!Array.isArray(parameterDefinition[property])) {
            throw new ControlParameterDefinitionWrongPropertyTypeError(parameterDefinition, property, typeof parameterDefinition[property], 'array');
        }
    };
    SDControlComponentsContracts.prototype.checkProperty = function (parameterDefinition, property, type) {
        if (!parameterDefinition.hasOwnProperty(property)) {
            throw new ControlParameterDefinitionMissingPropertyError(parameterDefinition, property);
        }
        if (type && typeof parameterDefinition[property] !== type) {
            throw new ControlParameterDefinitionWrongPropertyTypeError(parameterDefinition, property, typeof parameterDefinition[property], type);
        }
    };
    SDControlComponentsContracts.prototype.checkboxContract = function (parameterDefinition) {
        this.checkProperty(parameterDefinition, 'update', 'function');
    };
    SDControlComponentsContracts.prototype.checklistContract = function (parameterDefinition) {
        this.checkProperty(parameterDefinition, 'update', 'function');
        this.checkArrayProperty(parameterDefinition, 'choices');
    };
    SDControlComponentsContracts.prototype.colorContract = function (parameterDefinition) {
        this.checkProperty(parameterDefinition, 'update', 'function');
    };
    SDControlComponentsContracts.prototype.dropdownContract = function (parameterDefinition) {
        this.checkProperty(parameterDefinition, 'update', 'function');
        this.checkArrayProperty(parameterDefinition, 'choices');
    };
    SDControlComponentsContracts.prototype.fileContract = function (parameterDefinition) {
        this.checkProperty(parameterDefinition, 'update', 'function');
    };
    SDControlComponentsContracts.prototype.modalContract = function (parameterDefinition) {
        this.checkProperty(parameterDefinition, 'modalContent', 'string');
    };
    SDControlComponentsContracts.prototype.multipleContract = function (parameterDefinition) {
        this.checkArrayProperty(parameterDefinition, 'parameters');
    };
    SDControlComponentsContracts.prototype.sliderContract = function (parameterDefinition) {
        this.checkProperty(parameterDefinition, 'update', 'function');
    };
    SDControlComponentsContracts.prototype.staticHTMLContract = function (ParameterDefinition) {
        this.checkProperty(ParameterDefinition, 'content', 'string');
    };
    SDControlComponentsContracts.prototype.stringContract = function (parameterDefinition) {
        this.checkProperty(parameterDefinition, 'update', 'function');
    };
    SDControlComponentsContracts.prototype.vectorContract = function (parameterDefinition) {
        this.checkProperty(parameterDefinition, 'update', 'function');
    };
    return SDControlComponentsContracts;
}());
exports.SDControlComponentsContractsInstance = new SDControlComponentsContracts();
