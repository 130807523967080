"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StatePromise_1 = require("./StatePromise");
var StateDashboard = /** @class */ (function () {
    function StateDashboard() {
        this._firstTimeVisible = new StatePromise_1.State();
        this._settingsLoaded = new StatePromise_1.State();
        this._customStates = {};
    }
    Object.defineProperty(StateDashboard.prototype, "firstTimeVisible", {
        get: function () {
            return this._firstTimeVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateDashboard.prototype, "settingsLoaded", {
        get: function () {
            return this._settingsLoaded;
        },
        enumerable: true,
        configurable: true
    });
    StateDashboard.prototype.getCustomState = function (id) {
        return this._customStates[id];
    };
    StateDashboard.prototype.createCustomState = function (id) {
        this._customStates[id] = new StatePromise_1.State();
        return this._customStates[id];
    };
    return StateDashboard;
}());
var StateDashboardLibrary = /** @class */ (function () {
    function StateDashboardLibrary() {
    }
    StateDashboardLibrary.getInstance = function (id) {
        if (!StateDashboardLibrary.instances[id])
            StateDashboardLibrary.instances[id] = new StateDashboard();
        return StateDashboardLibrary.instances[id];
    };
    StateDashboardLibrary.instances = {};
    return StateDashboardLibrary;
}());
exports.StateDashboardLibrary = StateDashboardLibrary;
