"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var THREE = require("../../../externals/three");
var AnchorElement = /** @class */ (function () {
    function AnchorElement(definition) {
        this._id = definition.id;
        this._localPosition = definition.localPosition;
        this._rotationMatrix = new THREE.Matrix4().makeBasis(definition.orientation.direction.clone().cross(definition.orientation.axis), definition.orientation.direction, definition.orientation.axis);
        this._orientation = definition.orientation;
        this._rotationMatrix.getInverse(this._rotationMatrix);
    }
    Object.defineProperty(AnchorElement.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnchorElement.prototype, "localPosition", {
        get: function () {
            return this._localPosition;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnchorElement.prototype, "rotationMatrix", {
        get: function () {
            return this._rotationMatrix;
        },
        enumerable: true,
        configurable: true
    });
    return AnchorElement;
}());
exports.AnchorElement = AnchorElement;
