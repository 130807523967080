let HighlightingHandler = function (___settings, ___handlers) {
  const PATH_UTILS = ___settings.pathUtils,
        _geometryNode = ___settings.geometryNode,
        _handlers = ___handlers;

  let that,
      _hovered = null,
      _dragged = null,
      _selected = [],
      _interactable = [],
      _activeHighlighting = false,
      _justSelected = false;

  ////////////
  ////////////
  //
  // the hooks for the settings go below
  //
  ////////////
  ////////////


  class HighlightingHandler {

    constructor() {
      that = this;
    }

    /**
     * Hightlight
     *
     * @param {THREE.Mesh} mesh
     * @param {Object} highlight
     * @param {Boolean} toggle
     */
    _highlight(mesh, highlight, toggle) {
      if (highlight) {
        let obj = PATH_UTILS.getPathObject(_geometryNode, mesh.interactionPath + '');
        if (obj.highlighted != toggle) {
          obj.highlighted = toggle;
          obj.traverse(function (object) {
            if (toggle) {
              highlight.apply(object, highlight.options);
            } else {
              highlight.remove(object, highlight.options);
            }
          });
        }
      }
    }


    ////////////
    ////////////
    //
    // HighlightingHandler API
    //
    ////////////
    ////////////


    /**
     * Set the currently dragged object.
     *
     * @param {Object} object The object
     */
    setDragged(object) {
      that.setToggleHighlights(false);
      _dragged = object;
      that.setToggleHighlights(true);
      return true;
    }

    /**
     * Removes currently dragged object.
     */
    removeDragged() {
      that.setToggleHighlights(false);
      _dragged = null;
      that.setToggleHighlights(true);
      return true;
    }

    /**
     * Returns currently dragged object.
     */
    getDragged() {
      return _dragged;
    }

    /**
     * Toggles the selection status for the given object.
     *
     * @param {Object} object The object
     */
    toggleSelected(object) {
      that.setToggleHighlights(false);
      let index = -1;
      for (let i = 0; i < _selected.length; i++) {
        if (object.interactionPath !== undefined) {
          if (object.interactionPath === _selected[i].interactionPath)
            index = i;
        } else {
          if (_selected[i] == object)
            index = i;
        }
      }

      let toggled = false;
      if (index > -1) {
        _selected.splice(index, 1);
      } else {
        _selected.push(object);
        toggled = true;
      }

      _justSelected = true;
      that.setToggleHighlights(true, toggled);
      return toggled;
    }

    /**
     * Set the given object to selected.
     *
     * @param {Object} object The object
     */
    setSelected(object) {
      if (!object.interactionGroup) return false;
      that.setToggleHighlights(false);
      let index = -1;
      for (let i = 0; i < _selected.length; i++) {
        if (object.interactionPath !== undefined) {
          if (object.interactionPath === _selected[i].interactionPath)
            index = i;
        } else {
          if (_selected[i] == object)
            index = i;
        }
      }
      if (index === -1)
        _selected.push(object);

      _justSelected = index === -1;
      that.setToggleHighlights(true);
      return true;
    }

    /**
     * Set the given object to unselected.
     */
    removeSelected(object) {
      if (!object.interactionGroup) return false;
      var unSelected = false;
      that.setToggleHighlights(false);
      let index = -1;
      for (let i = 0; i < _selected.length; i++) {
        if (object.interactionPath !== undefined) {
          if (object.interactionPath === _selected[i].interactionPath)
            index = i;
        } else {
          if (_selected[i] == object)
            index = i;
        }
      }

      if (index !== -1) {
        _selected.splice(index, 1);
        unSelected = true;
      }

      _justSelected = index !== -1;
      that.setToggleHighlights(true);
      return unSelected;
    }

    /**
     * @return {Array} Array of currently selected objects.
     */
    getSelected() {
      return _selected;
    }

    /**
     * Set the currently hovered object.
     *
     * @param {Object} object The object
     */
    setHovered(object) {
      that.setToggleHighlights(false);
      _hovered = object;
      that.setToggleHighlights(true);
      return true;
    }

    /**
     * Removes currently hovered object.
     */
    removeHovered() {
      that.setToggleHighlights(false);
      _hovered = null;
      that.setToggleHighlights(true);
      return true;
    }

    /**
     * Returns currently hovered object.
     */
    getHovered() {
      return _hovered;
    }

    /**
     * Removes an object from all possible interactions.
     *
     * @param {Mesh} object
     */
    removeObject(object) {
      that.removeSelected(object);
      if (_hovered === object)
        _hovered = null;
      if (_dragged === object)
        _dragged = null;
      let index = -1;
      index = _interactable.indexOf(object);
      if (index != -1)
        _interactable.splice(index, 1);
      return true;
    }

    /**
     * Exchanges the array of interactable objects.
     *
     * @param {Objects[]} interactable
     * @return {Boolean} true on success, false on error
     */
    setInteractable(interactable) {
      if (!Array.isArray(interactable)) {
        return false;
      }
      that.setToggleHighlights(false);
      _interactable = interactable;
      that.setToggleHighlights(true);
      return true;
    }

    /**
     * Applies or removes the highlights depending on the toggle.
     *
     * @param {Boolean} toggle The toggle
     */
    setToggleHighlights(toggle) {
      let scope = 'HighlightingHandler.setToggleHighlights';

      if (typeof toggle !== 'boolean') {
        _handlers.threeDManager.warn(scope, 'Not a boolean. The highlighting was not toggled.');
        return false;
      }
      if (_activeHighlighting == toggle) {
        // Alex to Michael: this warning seems unnecessary to me and clutters warning output, therefore I have commented it for now.
        // It always appears when adding new geometry, which causes addToInteractionGroup and in turn
        // setInteractable to be called.
        //_handlers.threeDManager.warn(scope, 'The highlighting was not toggled, as it was already the same value.');
        return false;
      }

      _handlers.threeDManager.helpers.toggleViewport(true);
      _activeHighlighting = toggle;

      /**
       * ACTIVE HIGHLIGHTING
       * dragged > selected > hovered
       */

      if (_dragged) {
        let g = _handlers.interactionGroupManager.getGroup(_dragged.interactionGroup);
        that._highlight(_dragged, g.getDraggableHighlight().active, toggle);
      }

      for (let j = 0, len1 = _selected.length; j < len1; j++) {
        let selected = _selected[j];
        let g = _handlers.interactionGroupManager.getGroup(selected.interactionGroup);
        that._highlight(selected, g.getSelectableHighlight().active, toggle);
      }


      if (_hovered && !_justSelected) {
        let g = _handlers.interactionGroupManager.getGroup(_hovered.interactionGroup);
        that._highlight(_hovered, g.getHoverableHighlight().active, toggle);

      }


      /**
       * PASSIVE HIGHLIGHTING
       *
       * dragged > hovered > selected
       */

      if (_dragged) {
        let g = _handlers.interactionGroupManager.getGroup(_dragged.interactionGroup);

        for (let i = 0, len = _interactable.length; i < len; i++) {
          if (_interactable[i].interactionGroup == _dragged.interactionGroup &&
            _interactable[i] != _dragged)
            that._highlight(_interactable[i], g.getDraggableHighlight().passive, toggle);
        }
      }

      if (_hovered && !_justSelected) {
        let g = _handlers.interactionGroupManager.getGroup(_hovered.interactionGroup);

        for (let i = 0, len = _interactable.length; i < len; i++) {
          if (_interactable[i].interactionGroup == _hovered.interactionGroup &&
            _interactable[i] != _hovered)
            that._highlight(_interactable[i], g.getHoverableHighlight().passive, toggle);
        }
      }

      for (let j = 0, len1 = _selected.length; j < len1; j++) {
        let selected = _selected[j];
        let g = _handlers.interactionGroupManager.getGroup(selected.interactionGroup);

        for (let i = 0, len2 = _interactable.length; i < len2; i++) {
          if (_interactable[i].interactionGroup == selected.interactionGroup) {
            if (!_selected.includes(_interactable[i])) {
              if (_interactable[i].interactionPath !== undefined) {
                if (_selected.filter(function (s) { return s.interactionPath === _interactable[i].interactionPath; }).length === 0)
                  that._highlight(_interactable[i], g.getSelectableHighlight().passive, toggle);
              } else {
                that._highlight(_interactable[i], g.getSelectableHighlight().passive, toggle);
              }
            }
          }
        }
      }

      if (_justSelected)
        _justSelected = _activeHighlighting;

      _handlers.threeDManager.helpers.toggleViewport(false);

      return true;
    }

    /**
     * Returns the toggle for the current state of highlighting.
     *
     * @returns {Boolean} The toggle for this mode
     */
    getToggleHighlights() {
      return _activeHighlighting;
    }

  }

  return new HighlightingHandler(___settings);
};

module.exports = HighlightingHandler;
