"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Node_1 = require("./Node");
var THREE = require("../../../externals/three.js");
var SceneHierarchy = /** @class */ (function () {
    // #endregion Properties (5)
    // #region Constructors (1)
    function SceneHierarchy(gltf) {
        var _this = this;
        // #region Properties (5)
        this.nodes = [];
        this.allNodes = [];
        this.sceneId = gltf.scene || 0;
        this.sceneRef = gltf.scenes[this.sceneId];
        this.rotationMatrix = new THREE.Matrix4();
        this.rotationMatrix.makeRotationAxis(new THREE.Vector3(1, 0, 0), 0.5 * Math.PI);
        gltf.rotationMatrix = this.rotationMatrix;
        this.sceneRef.nodes.forEach(function (nodeId) { return _this.nodes.push(new Node_1.Node(nodeId, gltf, null, _this.allNodes, true)); });
    }
    return SceneHierarchy;
}());
exports.SceneHierarchy = SceneHierarchy;
