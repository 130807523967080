"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BaseCustomComponent = /** @class */ (function () {
    // #endregion Properties (15)
    // #region Constructors (1)
    function BaseCustomComponent(_type, _id, _parameterDefinition, _parent, _domManager, _queue, _editMode) {
        if (_editMode === void 0) { _editMode = false; }
        this._type = _type;
        this._id = _id;
        this._parameterDefinition = _parameterDefinition;
        this._parent = _parent;
        this._domManager = _domManager;
        this._queue = _queue;
        this._editMode = _editMode;
        // #region Properties (15)
        this._children = [];
        this._destroyEvents = [];
        this._labelClasses = ["uk-width-expand", "uk-form-label", "uk-text-small", "uk-margin-small-bottom", "uk-padding-remove", "uk-text-uppercase", "uk-text-truncate"];
        this._limit = 50;
        this._valueStack = [];
        this._inputContainerClasses = ["uk-width-1-1", "uk-padding-remove"];
        this._isChanged = false;
        this._rowId = this._id + "-controlmenu-row";
        this._labelId = this._id + "-label";
        this._inputContainerId = this._id + "-input-container";
        this._initalValue = this.parameterDefinition.value;
    }
    Object.defineProperty(BaseCustomComponent.prototype, "children", {
        // #endregion Constructors (1)
        // #region Public Accessors (15)
        get: function () {
            return this._children;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "inputComponent", {
        get: function () {
            return this._inputComponent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "inputContainer", {
        get: function () {
            return this._inputContainer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "isChanged", {
        get: function () {
            return this._isChanged;
        },
        set: function (v) {
            this._isChanged = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "label", {
        get: function () {
            return this._label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "order", {
        get: function () {
            var _a;
            return (_a = this._parameterDefinition.order) !== null && _a !== void 0 ? _a : 0;
        },
        set: function (v) {
            this._parameterDefinition.order = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "parameterDefinition", {
        get: function () {
            return this._parameterDefinition;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "row", {
        get: function () {
            return this._row;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "type", {
        get: function () {
            return this._type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "value", {
        get: function () {
            return this._inputComponent.value;
        },
        set: function (v) {
            this._inputComponent.value = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "valueStack", {
        get: function () {
            return this._valueStack;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Abstract Accessors (1)
    // #region Public Methods (6)
    BaseCustomComponent.prototype.destroy = function () {
        var _this = this;
        this._row.remove();
        this._destroyEvents.forEach(function (x) { return x(_this.id); });
    };
    BaseCustomComponent.prototype.insertBefore = function (other) {
        var _a;
        (_a = this.row.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(this.row, other.row);
    };
    BaseCustomComponent.prototype.isCurrentValueSameAsInital = function () {
        return this.value === this._initalValue;
    };
    /**
     * Add on destroy listener
     * @param { () => void } cb
     * @return void
     */
    BaseCustomComponent.prototype.onDestroy = function (cb) {
        this._destroyEvents.push(cb);
    };
    BaseCustomComponent.prototype.queueCommitExecuted = function (val, event) {
        this.update(val, event);
        this.value = val;
        if (Array.isArray(this.value)) {
            this._initalValue = this.value.map(function (x) { return x; });
        }
        else {
            this._initalValue = this.value;
        }
        this._valueStack = [];
    };
    BaseCustomComponent.prototype.setToInitalValueAndClearStack = function () {
        this.value = this._initalValue;
        this._valueStack = [];
    };
    // #endregion Public Methods (6)
    // #region Protected Methods (5)
    BaseCustomComponent.prototype.createElement = function () {
        this.createRow();
        this.createLabel();
        this.createInputContainer();
        this.createInput();
    };
    BaseCustomComponent.prototype.createInputContainer = function () {
        this._inputContainer = this._domManager.createDomElement('div', this._inputContainerId, this._row, this._inputContainerClasses);
    };
    BaseCustomComponent.prototype.createLabel = function () {
        var _this = this;
        var labelAttributes = {
            title: this._parameterDefinition.title || this._parameterDefinition.name,
        };
        this._label = this._domManager.createDomElement('label', this._labelId, this._row, this._labelClasses, labelAttributes);
        this._label.innerHTML = this._parameterDefinition.name;
        if (this._editMode) {
            this._label.classList.add("sdv-edit-mode");
        }
        var f = function () {
            var text = _this._label.innerText;
            if (text.length > _this._limit) {
                _this._label.innerText = text.substr(0, _this._limit);
            }
        };
        this._label.onkeyup = f;
        this._label.onkeydown = f;
    };
    BaseCustomComponent.prototype.createRow = function () {
        this._row = this._domManager.createDomElement('div', this._rowId, this._parent, ["sd-max-width-1", "uk-padding-small", "uk-margin-remove", "sdv-control-panel-row-border"], { "uk-grid": 'margin: "";' });
    };
    /**
     * Update, used to bind events.
     * @param { any } v - changed value
     * @param { Event } event - called event
     */
    BaseCustomComponent.prototype.update = function (v, event) {
        var _a, _b, _c, _d;
        if ((_a = this._queue) === null || _a === void 0 ? void 0 : _a.use) {
            this._queue.add(this, v, event);
        }
        else if ((_b = this._queue) === null || _b === void 0 ? void 0 : _b.executing) {
            this._queue.add(this, v, event);
            if (typeof ((_c = this._parameterDefinition) === null || _c === void 0 ? void 0 : _c.update) === "function") {
                this._parameterDefinition.update(v);
            }
        }
        else {
            if (typeof ((_d = this._parameterDefinition) === null || _d === void 0 ? void 0 : _d.update) === "function") {
                this._parameterDefinition.update(v);
            }
        }
    };
    return BaseCustomComponent;
}());
exports.BaseCustomComponent = BaseCustomComponent;
