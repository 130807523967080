"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrthographicControlsHooks = {
    "damping": function (value) {
        if (!this._globalUtils.typeCheck(value, "notnegative", this._logWarning, "OrthographicControls.Hook->damping"))
            return false;
        return true;
    },
    "enableKeyPan": function (value) {
        if (!this._globalUtils.typeCheck(value, "boolean", this._logWarning, "OrthographicControls.Hook->enableKeyPan"))
            return false;
        return true;
    },
    "enablePan": function (value) {
        if (!this._globalUtils.typeCheck(value, "boolean", this._logWarning, "OrthographicControls.Hook->enablePan"))
            return false;
        return true;
    },
    "enableZoom": function (value) {
        if (!this._globalUtils.typeCheck(value, "boolean", this._logWarning, "OrthographicControls.Hook->enableZoom"))
            return false;
        return true;
    },
    "input": function (value) {
        return true;
    },
    "keyPanSpeed": function (value) {
        if (!this._globalUtils.typeCheck(value, "number", this._logWarning, "OrthographicControls.Hook->keyPanSpeed"))
            return false;
        return true;
    },
    "movementSmoothness": function (value) {
        if (!this._globalUtils.typeCheck(value, "number", this._logWarning, "OrthographicControls.Hook->movementSmoothness"))
            return false;
        return true;
    },
    "panSpeed": function (value) {
        if (!this._globalUtils.typeCheck(value, "number", this._logWarning, "OrthographicControls.Hook->panSpeed"))
            return false;
        return true;
    },
    "zoomSpeed": function (value) {
        if (!this._globalUtils.typeCheck(value, "notnegative", this._logWarning, "OrthographicControls.Hook->zoomSpeed"))
            return false;
        return true;
    },
};
exports.OrthographicControlsNotifiers = {};
