/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *getImageBase64.js*
 *
 * ### Content
 *   * loads an image and converts it to a base64 encoded string
 *
 * @module getImageBase64
 * @author Mathias Höbinger <mathias@shapediver.com>
 */

const AXIOS = require('../../externals/axios');

// #SS-977 clean up the Promises in here

// Convert raster data into base64 string
var _converterEngine = function(input) {
  var uInt8Array = new Uint8Array(input),
      i = uInt8Array.length;
  var biStr = []; //new Array(i);
  while (i--) {
    biStr[i] = String.fromCharCode(uInt8Array[i]);
  }
  let base64 = window.btoa(biStr.join(''));
  return base64;
};

// Get the target raster file and convert it, return data
var getImageBase64 = function(url) {
  // 1. Loading file from url:
  return AXIOS({
    method: 'GET',
    url: url,
    responseType: 'arraybuffer'
  })
    // .catch(
    //   function(error){
    //     return Promise.reject(error);
    //   }
    // )
    .then(
      function(response) {
        let imgBase64 = _converterEngine(response.data); // convert BLOB to base64
        let contentType = response.headers['content-type']; // get mime type of response
        return Promise.resolve({data: imgBase64, contentType: contentType}); //execute callback function with data
      }
    )
  ;
};

module.exports = getImageBase64;
