"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var DropdownCustomComponent = /** @class */ (function (_super) {
    __extends(DropdownCustomComponent, _super);
    // #endregion Properties (3)
    // #region Constructors (1)
    function DropdownCustomComponent(id, parameterDefinition, parent, domManager, queue, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, "dropdown" /* DROPDOWN */, id, parameterDefinition, parent, domManager, queue, editMode) || this;
        // #region Properties (3)
        _this._choicesElements = {};
        _this._inputId = id + "-form";
        _this._choices = _this._parameterDefinition.choices;
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(DropdownCustomComponent.prototype, "size", {
        // #endregion Constructors (1)
        // #region Public Accessors (1)
        get: function () {
            return 2 /* HALF_WIDTH */;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (1)
    // #region Public Methods (1)
    /**
     * Creates dropdown choices.
     * @param choices
     * @param value
   */
    DropdownCustomComponent.prototype.updateOptions = function (choices, value) {
        this._choices = choices;
        this.parameterDefinition.choices = choices;
        this.inputComponent.innerHTML = "";
        this.createOptions();
        this.inputComponent.value = value;
    };
    DropdownCustomComponent.prototype.createOptions = function () {
        for (var i in this._choices) {
            var optionId = this.id + "-option-" + i;
            var optionElem = this._domManager.createDomElement("option", optionId, this._inputComponent, [], { name: optionId });
            optionElem.innerHTML = this._choices[i];
            optionElem.selected = this._choices[i] == this._parameterDefinition.value;
            this._choicesElements["cOption" + i] = optionElem;
        }
    };
    // #endregion Public Methods (1)
    // #region Protected Methods (2)
    DropdownCustomComponent.prototype.createInput = function () {
        this._inputComponent = this._domManager.createDomElement("select", this._inputId, this._inputContainer, ["uk-select", "uk-form-small", "uk-width-expand"]);
        this.createOptions();
        this._inputComponent.value = this._parameterDefinition.value;
    };
    DropdownCustomComponent.prototype.createInputEvents = function () {
        this._domManager.setDomEventListener(this._inputComponent, "change", 10, this.update.bind(this));
    };
    return DropdownCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.DropdownCustomComponent = DropdownCustomComponent;
