"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var enums_1 = require("./enums/enums");
var GenericEvent_1 = require("../../shapediver-generic-event/GenericEvent");
var ApiLightHelperWrapper_1 = require("./ApiLightHelperWrapper");
var CreateDefinitions_1 = require("./CreateDefinitions");
var DragControlsCache_1 = require("./DragControlsCache");
var DragControlsScenePathHelper_1 = require("./DragControlsScenePathHelper");
var LightControls = /** @class */ (function () {
    // #endregion Properties (16)
    // #region Constructors (1)
    /**
     * Creates shapediver drag controls
     * @param { API } api - ShapeDiver api.
     * @param { THREE } three - THREE dependency.
     */
    function LightControls(api, three) {
        this.three = three;
        this._chevronString = '<polyline fill="none" stroke="#000" stroke-width="1.03" points="10 6 14 10 10 14"></polyline><polyline fill="none" stroke="#000" stroke-width="1.03" points="6 6 10 10 6 14"></polyline>';
        this._events = new GenericEvent_1.GenericEvent();
        this._offsets = {};
        this._polygonString = '<polygon fill="none" stroke="#000" stroke-width="1.01" points="10 2 12.63 7.27 18.5 8.12 14.25 12.22 15.25 18 10 15.27 4.75 18 5.75 12.22 1.5 8.12 7.37 7.27"></polygon>';
        this._svgScaling = 2000;
        this._currentScale = 50;
        this._dragging = false;
        this._isEnabled = false;
        this._apiWrapper = new ApiLightHelperWrapper_1.ApiLightHelperWrapper(api);
        this._createDefinitions = new CreateDefinitions_1.CreateDefinitions(this.three);
        this._dragControlsCache = new DragControlsCache_1.DragControlsCache(this._apiWrapper);
        this._scenePathHelper = new DragControlsScenePathHelper_1.DragControlsScenePathHelper();
        this.addEventListeners();
    }
    Object.defineProperty(LightControls.prototype, "events", {
        // #endregion Constructors (1)
        // #region Public Accessors (1)
        get: function () {
            return this._events;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (1)
    // #region Public Methods (6)
    LightControls.prototype.disableDragging = function () {
        this._dragControlsCache.clear(false, true, true);
    };
    LightControls.prototype.enableDragging = function (id) {
        var _this = this;
        // get the light
        var l = this._apiWrapper.getLight(id);
        if (!l)
            return;
        // for a spot light, the position and target are made draggable
        if (this._apiWrapper.isSpotLight(l)) {
            var id_pos = l.type + "_" + id + "_position";
            var assets0 = this.createAssetDefinition(id_pos, l.properties.position);
            var id_tar = l.type + "_" + id + "_target";
            var assets1 = this.createAssetDefinition(id_tar, l.properties.target);
            this._dragControlsCache.put(l, "position", null, assets0.anchorAssetDefinition, assets0.geometryAssetDefinition);
            this._dragControlsCache.put(l, "target", null, assets1.anchorAssetDefinition, assets1.geometryAssetDefinition);
            this._offsets[id_pos] = new this.three.Vector3(l.properties.position.x, l.properties.position.y, l.properties.position.z);
            this._offsets[id_tar] = new this.three.Vector3(l.properties.target.x, l.properties.target.y, l.properties.target.z);
        }
        // for a point,only the position is made draggable
        else if (this._apiWrapper.isPointLight(l)) {
            var id_pos = l.type + "_" + id + "_position";
            var assets0 = this.createAssetDefinition(id_pos, l.properties.position);
            this._dragControlsCache.put(l, "position", null, assets0.anchorAssetDefinition, assets0.geometryAssetDefinition);
            this._offsets[id_pos] = new this.three.Vector3(l.properties.position.x, l.properties.position.y, l.properties.position.z);
        }
        // for a directional light, calculate position from direction
        else if (this._apiWrapper.isDirectionalLight(l)) {
            var boundingSphere = this.getBoundingSphere();
            var center = boundingSphere.center;
            var radius = boundingSphere.radius;
            // vector pointing in opposite dir            point is = center + (nor(l.direction) * Scalar);
            var final = new this.three.Vector3(l.properties.direction.x, l.properties.direction.y, l.properties.direction.z).normalize();
            final.multiplyScalar(2.35 * radius);
            final.add(center);
            var id_pos = l.type + "_" + id + "_position";
            var assets0 = this.createAssetDefinition(id_pos, final);
            this._dragControlsCache.put(l, "position", null, assets0.anchorAssetDefinition, assets0.geometryAssetDefinition);
            this._offsets[id_pos] = final;
        }
        var toUpdate = this._dragControlsCache.getAllAsArray(false, true, true);
        // update the scene with the created asset definitons.
        this._apiWrapper.updateAsync(toUpdate).then(function (data) {
            var p = [];
            // hide all actual drag objects, the icons will be the indicators instead
            var geometries = _this._dragControlsCache.getAllAsArray(false, false, true);
            geometries.forEach(function (g) {
                data.data.forEach(function (d) {
                    if (d.id == g.id) {
                        p.push(d.scenePath);
                    }
                });
            });
            _this._apiWrapper.toggleGeometry(p);
        });
    };
    LightControls.prototype.makeLightDraggableAndZoomAsync = function (id) {
        this.makeLightDraggable(id);
        // call a zoom extents to a adjusted bb with the light controls
        this._apiWrapper.zoomAsync(this.getSceneAndLightBoundingBox());
    };
    LightControls.prototype.makeLightDraggable = function (id) {
        if (!this._isEnabled) {
            var sceneBB = this._apiWrapper.getSceneBoundingBox();
            this._dist = sceneBB.min.distanceTo(sceneBB.max) * 0.025;
            // create the simple interaction group
            this._apiWrapper.createInteractionsGroup();
            this._isEnabled = true;
        }
        this.disableDragging();
        this.enableDragging(id);
    };
    LightControls.prototype.makeLightDraggableAndZoom = function (id) {
        this.makeLightDraggable(id);
        // call a zoom extents to a adjusted bb with the light controls
        this._apiWrapper.zoomAsync(this.getSceneAndLightBoundingBox());
    };
    LightControls.prototype.update = function (lightDef) {
        this.makeLightDraggable(lightDef.id);
    };
    LightControls.prototype.useLightHelpers = function (val) {
        this._apiWrapper.setLightHelpers(val);
        var svgs = this._dragControlsCache.getAllAsArray(true, false, false);
        svgs.forEach(function (svg) { return svg.style.visibility = val ? "visible" : "hidden"; });
        if (val === false) {
            this.disableDragging();
        }
    };
    LightControls.prototype.zoomAsync = function () {
        // call a zoom extents to a adjusted bb with the light controls
        this._apiWrapper.zoomAsync(this.getSceneAndLightBoundingBox());
    };
    // #endregion Public Methods (5)
    // #region Private Methods (9)
    /**
     * Checks if light is beind dragged
     * @param evt { scenePath: string }
     * @returns boolean
     */
    LightControls.prototype.isLightHelperBeingDragged = function (evt) {
        return evt.scenePath.indexOf("lightHelperGeometry_") > 0;
    };
    /**
     * Create all event listeners that are needed.
     */
    LightControls.prototype.addEventListeners = function () {
        var _this = this;
        /**
         * Create the event listener for the ANCHOR_ADD event.
         *
         * What happens here:
         * 1. The div in which the icons, that replace the dragged objects, live is created or just searched for here.
         * 2. The icon for the current draggable object is created and added to the div.
         * 3. The position of the icon is adjusted so that it is exactly on the same position as the anchor and the draggable object.
         * 4. The update function, that is called on every anchor update, is created and assigned here.
         */
        this._apiWrapper.addAnchorAddListener(function (evt) {
            for (var i = 0, len = evt.anchors.length; i < len; i++) {
                var anchor = evt.anchors[i];
                if (anchor.format !== 'light_anchor')
                    continue;
                // Part 1: create the div in which the icons live, if it isn't already here
                var id = anchor.viewport + "-shapediver-lights-domElements";
                _this._domEl = document.getElementById(id);
                if (!_this._domEl) {
                    _this._domEl = document.createElement("div");
                    _this._domEl.style.pointerEvents = "none";
                    _this._domEl.style.position = "absolute";
                    _this._domEl.style.width = "100%";
                    _this._domEl.style.height = "100%";
                    _this._domEl.style.top = "0";
                    _this._domEl.style.left = "0";
                    _this._domEl.id = id;
                    _this._apiWrapper.appendElementToVieweport(anchor.viewport, _this._domEl);
                }
                // Part 2: create the icon
                var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                svg.style.position = "absolute";
                svg.style.color = "#000000";
                svg.style.cursor = "pointer";
                svg.setAttribute("viewBox", "0 0 20 20");
                svg.setAttribute("width", "20");
                svg.setAttribute("height", "20");
                if (anchor.data.text.includes("position")) {
                    svg.appendChild(_this.createPolygonElement());
                }
                else {
                    var children = _this.createPolylineHtmlElements();
                    svg.appendChild(children[0]);
                    svg.appendChild(children[1]);
                }
                svg.id = anchor.data.text;
                // remove old
                var currentSvg = _this._dragControlsCache.getFromKey(svg.id).svg;
                if (currentSvg)
                    _this._domEl.removeChild(currentSvg);
                // add new 
                _this._domEl.appendChild(svg);
                _this._dragControlsCache.putWithkey(svg.id, svg);
                // Part 3: Adjust the position of the icon
                var off = _this._offsets[anchor.data.text];
                _this._domRect = _this._domEl.getBoundingClientRect();
                var x = void 0, y = void 0;
                if (off) {
                    var p = new _this.three.Vector3(anchor.location.x, anchor.location.y, anchor.location.z);
                    var data2D1 = _this._apiWrapper.convertTo2D(p);
                    x = data2D1.clientX;
                    y = data2D1.clientY;
                }
                else {
                    x = anchor.clientX;
                    y = anchor.clientY;
                }
                _this.setSvgPositionAndScale(svg, x, y);
                // Part 4: create and assign the update function
                // This function updates the icon to position of the anchor only if there is no dragging happening
                // (otherwise the icon wouldn't move while dragging as the anchor stays at the same position during the dragging)
                var update = function (event) {
                    if (!_this._dragging) {
                        _this._currentScale = (1.0 / (event.distance / _this._dist)) * _this._svgScaling;
                        var currentSvg_1 = _this._dragControlsCache.getFromKey(event.data.text).svg;
                        _this.setSvgPositionAndScale(currentSvg_1, event.clientX, event.clientY);
                    }
                };
                anchor.updateFunction(update);
            }
        });
        /**
         * Create the event listener for the ANCHOR_REMOVE event.
         *
         * Just remove the icon from the list and from the div.
         */
        this._apiWrapper.addAnchorRemoveListener(function (evt) {
            for (var i = 0, len = evt.anchors.length; i < len; i++) {
                var anchor = evt.anchors[i];
                if (anchor.format !== 'light_anchor')
                    continue;
                var domEl = document.getElementById(anchor.viewport + "-shapediver-lights-domElements");
                var key = anchor.data.text;
                var currentIcon = _this._dragControlsCache.getFromKey(key).svg;
                _this._dragControlsCache.clearWithKey(key, true, false, false);
                domEl.removeChild(currentIcon);
            }
        });
        /**
         * Create the event listener for the DRAG_START event.
         *
         * Calculate the current scene bounding sphere.
         */
        this._apiWrapper.addDragStartListener(function (evt) {
            if (!_this.isLightHelperBeingDragged(evt))
                return;
            _this._dragging = true;
            var sceneBB = _this._apiWrapper.getSceneBoundingBox();
            sceneBB = new _this.three.Box3(sceneBB.min, sceneBB.max);
            _this._boundingSphere = new _this.three.Sphere();
            sceneBB.getBoundingSphere(_this._boundingSphere);
        });
        /**
         * Create the event listener for the DRAG_MOVE event.
         *
         * Update the icon to the currently dragged position. The anchor position stays the same for the moment.
         * Update the light with the current position.
         */
        this._apiWrapper.addDragMoveListener(function (evt) {
            if (!_this.isLightHelperBeingDragged(evt))
                return;
            var item_id = evt.scenePath.substring(evt.scenePath.indexOf(".lightHelperGeometry_") + ".lightHelperGeometry_".length);
            var off = _this._offsets[item_id];
            // Decode the id to the several parts of it
            var property = _this._scenePathHelper.extractPropertyFromPath(item_id);
            var def = _this.getLightDefinitionFromItemId(item_id);
            var p = evt.obj.position.clone().add(off);
            _this.handlePositionAndLightDefinitionFromDirectionalLight(p, def);
            // adjust the position of the icon
            var data2D = _this._apiWrapper.convertTo2D(p);
            var currentIcon = _this._dragControlsCache.get(def, property).svg;
            _this.setSvgPositionAndScale(currentIcon, data2D.clientX, data2D.clientY);
            // update the light
            def.properties[property] = { x: p.x, y: p.y, z: p.z };
            _this._apiWrapper.updateLight(def);
            _this._events.trigger(enums_1.LightControlsEvents.UPDATED, def);
        });
        /**
         * Create the event listener for the DRAG_END event.
         *
         * Remove the old anchor and create a new one with the position where the dragging ended.
         */
        this._apiWrapper.addDragEndListener(function (evt) {
            if (!_this.isLightHelperBeingDragged(evt))
                return;
            _this._dragging = false;
            var assets = [];
            var item_id = evt.scenePath.substring(evt.scenePath.indexOf(".lightHelperGeometry_") + ".lightHelperGeometry_".length);
            //this._apiWrapper.removeAsync({ id: "lightHelperAnchor_" + item_id });
            var off = _this._offsets[item_id];
            var p = evt.obj.position.clone().add(off);
            var isDirectional = false;
            // added
            var def = _this.getLightDefinitionFromItemId(item_id);
            if (_this._apiWrapper.isDirectionalLight(def)) {
                //this._apiWrapper.removeAsync({ id: "lightHelperGeometry_" + item_id });
                p = new _this.three.Vector3(0, 0, 0);
                var boundingSphere = _this.getBoundingSphere();
                var center = boundingSphere.center;
                var radius = boundingSphere.radius;
                // vector pointing in opposite dir            point is = center + (nor(l.direction) * Scalar);
                var final = new _this.three.Vector3(def.properties.direction.x, def.properties.direction.y, def.properties.direction.z).normalize();
                final.multiplyScalar(2.35 * radius);
                p = final.add(center);
                isDirectional = true;
            }
            var assetDef = _this.createAssetDefinition(item_id, p);
            if (isDirectional) {
                assets.push(assetDef.geometryAssetDefinition);
                _this._offsets[item_id] = new _this.three.Vector3(p.x, p.y, p.z);
            }
            _this._dragControlsCache.put(def, "position", null, assetDef.anchorAssetDefinition, isDirectional ? assetDef.geometryAssetDefinition : null);
            assets.push(assetDef.anchorAssetDefinition);
            _this._apiWrapper.updateAsync(assets);
        });
    };
    LightControls.prototype.createAssetDefinition = function (id, p) {
        var sphere = new this.three.Mesh(new this.three.SphereGeometry(this._dist, 2, 2), new this.three.MeshBasicMaterial({ color: 0xffff00, opacity: 0, transparent: true }));
        sphere.geometry.applyMatrix(new this.three.Matrix4().makeTranslation(p.x, p.y, p.z));
        var g = this._createDefinitions.createGeometryAssetDefinition();
        g.id = "lightHelperGeometry_" + id;
        g.content[0].data.threeObject = sphere;
        var a = this._createDefinitions.createAnchorAssetDefinition();
        a.id = "lightHelperAnchor_" + id;
        a.content[0].data[0].location = p;
        a.content[0].data[0].data.text = id;
        return {
            geometryAssetDefinition: g,
            anchorAssetDefinition: a
        };
    };
    LightControls.prototype.createPolygonElement = function () {
        var polygon = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
        polygon.setAttribute("fill", "none");
        polygon.setAttribute("stroke", "#000");
        polygon.setAttribute("stroke-width", "1.03");
        polygon.setAttribute("points", "10 2 12.63 7.27 18.5 8.12 14.25 12.22 15.25 18 10 15.27 4.75 18 5.75 12.22 1.5 8.12 7.37 7.27");
        return polygon;
    };
    LightControls.prototype.createPolylineHtmlElements = function () {
        var polyline1 = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');
        polyline1.setAttribute("fill", "none");
        polyline1.setAttribute("stroke", "#000");
        polyline1.setAttribute("stroke-width", "1.03");
        polyline1.setAttribute("points", "10 6 14 10 10 14");
        var polyline2 = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');
        polyline2.setAttribute("fill", "none");
        polyline2.setAttribute("stroke", "#000");
        polyline2.setAttribute("stroke-width", "1.03");
        polyline2.setAttribute("points", "6 6 10 10 6 14");
        return [polyline1, polyline2];
    };
    LightControls.prototype.getBoundingSphere = function (newSphere) {
        if (newSphere === void 0) { newSphere = false; }
        if (!this._boundingSphere || newSphere) {
            var sceneBB = this._apiWrapper.getSceneBoundingBox();
            sceneBB = new this.three.Box3(sceneBB.min, sceneBB.max);
            this._boundingSphere = new this.three.Sphere();
            sceneBB.getBoundingSphere(this._boundingSphere);
        }
        return this._boundingSphere;
    };
    LightControls.prototype.getLightDefinitionFromItemId = function (itemId) {
        return this._apiWrapper.getLight(this._scenePathHelper.extractLightIdFromPath(itemId));
    };
    LightControls.prototype.getSceneAndLightBoundingBox = function () {
        var bb_size = this._apiWrapper.getSceneBoundingBox(), bb = new this.three.Box3(bb_size.min, bb_size.max), bs = bb.getBoundingSphere(), center = bs.center, radius = bs.radius, ls = this._apiWrapper.getLightScene().lights;
        for (var id in ls) {
            var light = ls[id];
            if (this._apiWrapper.isSpotLight(light)) {
                bb.union(new this.three.Box3().setFromPoints([
                    new this.three.Vector3(light.properties.position.x, light.properties.position.y, light.properties.position.z),
                    center.clone().add(center.clone().sub(new this.three.Vector3(light.properties.position.x, light.properties.position.y, light.properties.position.z))),
                    new this.three.Vector3(light.properties.target.x, light.properties.target.y, light.properties.target.z),
                    center.clone().add(center.clone().sub(new this.three.Vector3(light.properties.target.x, light.properties.target.y, light.properties.target.z))),
                ]));
            }
            if (this._apiWrapper.isDirectionalLight(light)) {
                bb.union(new this.three.Box3().setFromPoints([
                    center.clone().add(new this.three.Vector3(light.properties.direction.x, light.properties.direction.y, light.properties.direction.z).normalize().multiplyScalar(2.35 * radius)),
                    center.clone().add(new this.three.Vector3(light.properties.direction.x, light.properties.direction.y, light.properties.direction.z).normalize().multiplyScalar(-2.35 * radius)),
                ]));
            }
            if (this._apiWrapper.isPointLight(light)) {
                bb.union(new this.three.Box3().setFromPoints([
                    new this.three.Vector3(light.properties.position.x, light.properties.position.y, light.properties.position.z),
                    center.clone().add(center.clone().sub(new this.three.Vector3(light.properties.position.x, light.properties.position.y, light.properties.position.z))),
                ]));
            }
        }
        return bb;
    };
    LightControls.prototype.handlePositionAndLightDefinitionFromDirectionalLight = function (p, def) {
        if (this._apiWrapper.isDirectionalLight(def)) {
            var boundingSphere = this.getBoundingSphere();
            var t = boundingSphere.center;
            var rad = boundingSphere.radius;
            def.properties.direction = new this.three.Vector3(p.x, p.y, p.z)
                .sub(new this.three.Vector3(t.x, t.y, t.z))
                .normalize();
            var d = def.properties.direction.clone();
            d.multiplyScalar(2.35 * rad);
            var newPos = new this.three.Vector3(t.x, t.y, t.z).add(d);
            p.x = newPos.x;
            p.y = newPos.y;
            p.z = newPos.z;
        }
    };
    LightControls.prototype.setSvgPositionAndScale = function (svg, x, y) {
        if (!(svg.firstElementChild || svg.innerHTML)) {
            return;
        }
        var l = x - this._currentScale / 2 - this._domRect.left;
        var t = y - this._currentScale / 2 - this._domRect.top;
        var w = this._domEl.clientWidth - svg.clientWidth;
        var h = this._domEl.clientHeight - svg.clientHeight;
        var isInScreenSpace = !(l < 0 || l > w || t < 0 || t > h);
        if (!this._apiWrapper.getLightHelpersVisible() || !isInScreenSpace) {
            svg.style.visibility = "hidden";
        }
        else {
            svg.style.visibility = "visible";
            svg.setAttribute("width", this._currentScale);
            svg.setAttribute("height", this._currentScale);
            svg.style.left = l + "px";
            svg.style.top = t + "px";
        }
    };
    return LightControls;
}());
exports.LightControls = LightControls;
