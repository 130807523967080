/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *main.js*
 *
 * ### Content
 *   * everything that should be exported from the main build
 *
 */

var SDVApp = require('./package');
