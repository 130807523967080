"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * The base abstract class of settings components.
 */
var BaseSettingComponent = /** @class */ (function () {
    // #endregion Properties (2)
    // #region Constructors (1)
    function BaseSettingComponent(_api, _settingDefinition, _component) {
        var _this = this;
        this._api = _api;
        this._settingDefinition = _settingDefinition;
        this._component = _component;
        if (!this._settingDefinition.update) {
            if (this._settingDefinition.setting) {
                this._settingDefinition.update = function (v) { return _this._api.updateSettingAsync(_this._settingDefinition.setting, v); };
            }
        }
        BaseSettingComponent._componentsRegistered.push(this);
        if (!BaseSettingComponent._settingsUpdateEvent) {
            BaseSettingComponent._settingsUpdateEvent = _api.addEventListener("settings.update", function (event) {
                BaseSettingComponent._componentsRegistered.forEach(function (x) {
                    if (x.parameterDefinition.setting == event.settings.key) {
                        if (typeof x.parameterDefinition.convertValue === "function") {
                            x.value = x.parameterDefinition.convertValue(event.settings.valueNew);
                        }
                        else {
                            x.value = event.settings.valueNew;
                        }
                    }
                });
            });
        }
    }
    Object.defineProperty(BaseSettingComponent.prototype, "children", {
        // #endregion Constructors (1)
        // #region Public Accessors (16)
        get: function () {
            return this._component.children;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "groupId", {
        get: function () {
            return this._settingDefinition.groupId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "hidden", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "id", {
        get: function () {
            return this._settingDefinition.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "inputComponent", {
        get: function () {
            return this._component.inputComponent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "component", {
        get: function () {
            return this._component;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "inputContainer", {
        get: function () {
            return this._component.inputContainer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "isChanged", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "label", {
        get: function () {
            return this._component.label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "order", {
        get: function () {
            return this._component.order;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "parameterDefinition", {
        get: function () {
            return this._settingDefinition;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "row", {
        get: function () {
            return this._component.row;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "size", {
        get: function () {
            return this._component.size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "type", {
        get: function () {
            return this._component.type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "value", {
        get: function () {
            return this._component.value;
        },
        set: function (v) {
            this._component.value = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "valueStack", {
        get: function () {
            return null;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (16)
    // #region Public Methods (6)
    /**
     * Destroys the component. Removes it from dom.
     * @return { void }
     */
    BaseSettingComponent.prototype.destroy = function () {
        this._component.destroy();
    };
    /**
     * Inserts this component before other component.
     * @param { ISortableComponent } - other sortable component
     * @return { void }
     */
    BaseSettingComponent.prototype.insertBefore = function (other) {
        this._component.insertBefore(other);
    };
    BaseSettingComponent.prototype.isCurrentValueSameAsInital = function () {
        throw new Error("Method not implemented.");
    };
    /**
     * Add on destroy listener
     * @param { () => void } cb
     * @return void
     */
    BaseSettingComponent.prototype.onDestroy = function (cb) {
        var _this = this;
        this._component.onDestroy(function () { return cb(_this.id); });
    };
    BaseSettingComponent.prototype.queueCommitExecuted = function (val, event) {
        throw new Error("Method not implemented.");
    };
    BaseSettingComponent.prototype.setToInitalValueAndClearStack = function () {
        throw new Error("Method not implemented.");
    };
    // #region Properties (2)
    BaseSettingComponent._componentsRegistered = [];
    return BaseSettingComponent;
}());
exports.BaseSettingComponent = BaseSettingComponent;
