/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *LoggingConstants.js*
 *
 * ### Content
 *   * definition of constants related to logging (logging levels etc)
 *
 * @module LoggingConstants
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
  * Logging level type
  * @typedef {Number} LoggingLevel
  */

/**
 * Possible logging levels
 * @typedef {Object} LoggingLevels
 * @property {LoggingLevel} NONE - do not display any log messages
 * @property {LoggingLevel} ERROR - display log messages of type "error" or below
 * @property {LoggingLevel} WARN - display log messages of type "warning" or below
 * @property {LoggingLevel} INFO - display log messages of type "info" or below
 * @property {LoggingLevel} DEBUG - display log messages of type "debug" or below
 */
var loggingLevels = {
  NONE: -1,
  ERROR: 0,
  WARN: 1,
  INFO: 2,
  DEBUG: 3,
  LVLBITS: 3,
  LOG_TO_SERVER: 8,
  ERROR_S: 8 | 0,
  WARN_S: 8 | 1,
  INFO_S: 8 | 2,
  DEBUG_S: 8 | 3,
};

/**
 * Definition of default settings for logging
 */
var defaultSettings = {
  loggingLevel: loggingLevels.NONE,
};

/**
 * Collection of all constants
 */
var loggingConstants = {
  loggingLevels: loggingLevels,
  defaultSettings: defaultSettings
};

/**
 * Global utilities
 */
var GlobalUtils = require('../util/GlobalUtils');

/**
 * Export a deep copy of the constants
 */
module.exports = GlobalUtils.deepCopy(loggingConstants);
