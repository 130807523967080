/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ProcessStatusHandler.js*
 *
 * ### Content
 *   * Functionality to keep track of busy processes in the ViewerApp
 *
 * @module ProcessStatusHandler
 * @author Mathias Höbinger <mathias@shapediver.com>
 */

/**
 * Import global utilities
 */
var GlobalUtils = require('../../shared/util/GlobalUtils');

/**
 * Import message prototype
 */
var MessagePrototype = require('../../shared/messages/MessagePrototype');

/**
 * Import ViewerApp constants
 */
var viewerAppConstants = require('../ViewerAppConstants');

/**
 * Constructor of the ProcessStatusHandler mixin
 * @mixin ProcessStatusHandler
 * @author Mathias Höbinger <mathias@shapediver.com>
 *
 * @param {Object} references - References to other managers
 * @param {Object} references.viewportManager - Reference to the viewportManager
 */
var ProcessStatusHandler = function(___refs) {

  var that = this;

  var _viewportManager;
  if ( ___refs && ___refs.viewportManager )
    _viewportManager = ___refs.viewportManager;

  /**
   * index of busy processes
   */
  var _processStatus = {};

  /**
   * keep a summary of the process status
   */
  var _summary = {
    busy: false,
    progress: 1,
    processes: GlobalUtils.deepCopy(_processStatus)
  };

  /**
   * Object for collecting public members, this object will be returned instead of the default "this"
   */
  var _o = {};

  /**
   * Get a summary of the latest process status
   */
  _o.getSummary = function() {
    return GlobalUtils.deepCopy(_summary);
  };

  /**
   * Update viewer status from process status
   */
  _o.updateViewerStatus = function() {
    const scope = 'ProcessStatusHandler.updateViewerStatus';

    // remember busy status before update
    let wasBusy = _summary.busy;

    // update the summary
    let n = {
      busy: false,
      progress: 1,
      processes: GlobalUtils.deepCopy(_processStatus)
    };

    for (var proc in _processStatus) {
      let s = _processStatus[proc];
      if ( s.busy ) {
        n.busy = true;
      }
      if ( s.progress !== undefined && typeof s.progress === 'number' ) {
        if ( s.progress < n.progress && s.progress >= 0 )
          n.progress = s.progress;
      }
    }

    // remember _summary
    _summary = GlobalUtils.deepCopy(n);

    if (_summary.busy) {
      // send a STATUS_BUSY message in any case
      let m = new MessagePrototype(viewerAppConstants.messageDataTypes.APP_STATUS, n);
      that.message(viewerAppConstants.messageTopics.STATUS_BUSY, m);
      if (!wasBusy) {
        // entering busy status
        that.debug(scope, 'Entering busy mode, progress ' + 100*_summary.progress + '%', n);
        if ( _viewportManager && that.getSettingShallow('blurSceneWhenBusy') )
          _viewportManager.threeDManager.renderingHandler.setBlur(_summary.busy, 100);
      }
    }
    else if (!_summary.busy && wasBusy) {
      // leaving busy status
      that.debug(scope, 'Entering idle mode', n);
      let m = new MessagePrototype(viewerAppConstants.messageDataTypes.APP_STATUS, n);
      that.message(viewerAppConstants.messageTopics.STATUS_IDLE, m);
      if ( _viewportManager && that.getSettingShallow('blurSceneWhenBusy') )
        _viewportManager.threeDManager.renderingHandler.setBlur(_summary.busy, 100);
    }
    else if (_summary.busy) {
      that.debug(scope, 'Updating busy mode', n);
    }
  };

  /**
   * Update process status, message handler.
   *
   * @param  {String|module:MessagingConstants~MessageToken} token - Unique token of the process
   * @param  {module:MessagingConstants~ProcessStatusMessage} data - status message for process (busy, progress)
   * @return {Boolean}    True if the process status was updated
   */
  _o.updateProcessStatus = function(token, data) {
    let scope = 'ProcessStatusHandler.updateProcessStatus';
    // get unique token id
    let id = token;
    if ( id && typeof id === 'object' && GlobalUtils.typeCheck(token.id, 'string') )
      id = token.id;
    if ( !GlobalUtils.typeCheck(id, 'string') ) return false;
    // different behavior depending on progress
    data = data || {};
    if (!_processStatus.hasOwnProperty(id)) {
      // process id is not known
      if (data.progress === 0) {
        // register new process if progress is 0
        _processStatus[id] = {initTime: Date.now()};
        GlobalUtils.inject(data, _processStatus[id], true);
      }
      else {
        // do NOT register a new process if progress is !== 0
        that.debug(scope, 'Ignoring status message for unknown process ' + id, data);
        return false;
      }
    }
    else {
      // process id is known, update status
      GlobalUtils.inject(data, _processStatus[id], true);
    }
    // update busy status
    _o.updateViewerStatus();
    return true;
  };

  /**
   * Fork process status, message handler.
   *
   * @param  {String|module:MessagingConstants~MessageToken} token - Unique token of the process
   * @param  {String[]} newtokens - new process tokens to replace the existing one
   * @return {Boolean}    True if the process status was updated
   */
  _o.forkProcessStatus = function(token, newtokens) {
    // get unique token id
    let id = token;
    if ( id && typeof id === 'object' && GlobalUtils.typeCheck(token.id, 'string') )
      id = token.id;
    if ( !GlobalUtils.typeCheck(id, 'string'))
      return false;
    // store process status
    if (!_processStatus.hasOwnProperty(id)) {
      return false;
    }
    // newtokens sanity
    if ( !Array.isArray(newtokens) )
      return false;
    let ret = newtokens.every( (t) => {
      return t.startsWith(id + '.');
    });
    if (!ret)
      return false;
    // register new tokens
    let stat = _processStatus[id];
    newtokens.forEach( (t) => {
      _processStatus[t] = GlobalUtils.deepCopy(stat);
    });
    // get and delete current process status
    delete _processStatus[id];
    // done
    return true;
  };

  /**
   * Unregister process from being busy
   *
   * @param  {String|module:MessagingConstants~MessageToken} token - Unique token of the process
   * @return {Boolean}    True if the process has been unregistered
   */
  _o.unregisterProcess = function(token) {
    let scope = 'ProcessStatusHandler.unregisterProcess';
    // get unique token id
    let id = token;
    if ( id && typeof id === 'object' && GlobalUtils.typeCheck(token.id, 'string') )
      id = token.id;
    if ( !GlobalUtils.typeCheck(id, 'string') ) return false;
    // remove process status
    if (!_processStatus.hasOwnProperty(id)) {
      that.debug(scope, 'Unregistering unknown process ' + id);
      return false;
    }
    delete _processStatus[id];
    // update busy status
    _o.updateViewerStatus();
    return true;
  };

  return _o;
};

module.exports = ProcessStatusHandler;
