
/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiViewportsImplementationV2.1.js*
 *
 * ### Content
 *   * Implementation of the ShapeDiver 3D Viewer Viewports API V2.1
 *
 * @module ViewportsApi
 * @author ShapeDiver <contact@shapediver.com>
 */

/**
* Imported messaging constant definitions
*/
var messagingConstants = require('../../../shared/constants/MessagingConstants');

/**
* Import GlobalUtils
*/
var GlobalUtils = require('../../../shared/util/GlobalUtils');

/**
* ApiInterfaceV2
*/
var ApiInterfaceV2 = new (require('../ApiInterfaceV2.1'))();

/**
* APIResponse factory
*/
const APIResponse = require('../ApiResponse');


////////////
////////////
//
// Viewport Interface
//
////////////
////////////


/**
 * ### ShapeDiver 3D Viewer API V2 - Viewport Interface
 *
 * The viewport interface is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2#viewports API v2 viewport}.
 *
 * It allows to
 *  * get viewport apis
 *  * create and destroy viewports
 *
 * @interface ViewportsApi
 * @param {Object} api - The global api object to which this api belongs
 * @param {Object} references - Object containing references to various handlers
 * @param {Object} references.viewportManager - Reference to the viewport manager
 */
var ViewportsApi = function (_api, ___refs) {

  var that = this;

  let _viewportManager = ___refs.viewportManager;
  this.EVENTTYPE = ApiInterfaceV2.viewports.EVENTTYPE;

  /** @inheritdoc */
  this.get = function (runtimeId) {
    if (!runtimeId)
      return _viewportManager.getApis().length === 1 ? _viewportManager.getApis()[0] : _viewportManager.getApis();

    if (!GlobalUtils.typeCheck(runtimeId, 'string'))
      return APIResponse('The provided input is not a valid runtimeId');

    let api = _viewportManager.getApi(runtimeId);
    if (api) return api;

    return APIResponse('The requested api was not found');
  };

  /** @inheritdoc */
  this.create = function (obj) {
    return _viewportManager.createThreeDManager(obj)[0];
  };

  /** @inheritdoc */
  this.destroy = function (runtimeId) {
    return _viewportManager.removeThreeDManager(runtimeId);
  };

  /** @inheritdoc */
  this.addEventListener = function (type, cb) {
    // sanity check
    if (!GlobalUtils.typeCheck(type, 'string') || type.length <= 0)
      return APIResponse('Event type must be a string');
    // check if event type is supported
    if (!Object.keys(that.EVENTTYPE).find((k) => (that.EVENTTYPE[k] === type)))
      return APIResponse('Unsupported event type');
    // compose topic and subscribe to message stream
    let t = messagingConstants.messageTopics.VIEWPORTS + '.' + type;
    let subtokens = _api.subscribeToMessageStream(t, function (topic, msg) {
      // create event object, add common event properties
      let event = new CustomEvent(type);
      event.api = msg.api;
      if (msg.token) event.token = msg.token;
      // get relevant data parts from message, add special event properties
      let partTypes = {};
      partTypes[messagingConstants.messageDataTypes.GENERIC] = null; // null: copy all properties
      for (let pt in partTypes) {
        let part = msg.getUniquePartByType(pt);
        if (part) {
          if (part.data) part = part.data;
          // add special event properties
          let props = partTypes[pt] ? partTypes[pt] : part;
          for (let k in props) {
            // in case event[k] is an array, and part[k] is an array, should we merge the arrays?
            event[k] = part[k];
          }
        }
      }
      // invoke callback (exception handling takes place in _api.subscribeToMessageStream)
      cb(event);
    });
    return APIResponse(null, subtokens);
  };

  /** @inheritdoc */
  this.removeEventListener = function (token) {
    return APIResponse(null, _api.unsubscribeFromMessageStream(token));
  };
};

module.exports = ViewportsApi;