"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrbitControlsHooks = {
    "autoRotationSpeed": function (value) {
        if (!this._globalUtils.typeCheck(value, "float", this._logWarning, "OrbitControls.Hook->autoRotationSpeed"))
            return false;
        if (value !== 0) {
            this._settings.updateSetting("enableAutoRotation", true);
        }
        else {
            this._settings.updateSetting("enableAutoRotation", false);
        }
        return true;
    },
    "damping": function (value) {
        if (!this._globalUtils.typeCheck(value, "notnegative", this._logWarning, "OrbitControls.Hook->damping"))
            return false;
        return true;
    },
    "enableAutoRotation": function (value) {
        if (!this._globalUtils.typeCheck(value, "boolean", this._logWarning, "OrbitControls.Hook->enableAutoRotation"))
            return false;
        if (value)
            this._triggerRender();
        return true;
    },
    "enableKeyPan": function (value) {
        if (!this._globalUtils.typeCheck(value, "boolean", this._logWarning, "OrbitControls.Hook->enableKeyPan"))
            return false;
        return true;
    },
    "enablePan": function (value) {
        if (!this._globalUtils.typeCheck(value, "boolean", this._logWarning, "OrbitControls.Hook->enablePan"))
            return false;
        return true;
    },
    "enableRotation": function (value) {
        if (!this._globalUtils.typeCheck(value, "boolean", this._logWarning, "OrbitControls.Hook->enableRotation"))
            return false;
        return true;
    },
    "enableZoom": function (value) {
        if (!this._globalUtils.typeCheck(value, "boolean", this._logWarning, "OrbitControls.Hook->enableZoom"))
            return false;
        return true;
    },
    "input": function (value) {
        return true;
    },
    "keyPanSpeed": function (value) {
        if (!this._globalUtils.typeCheck(value, "number", this._logWarning, "OrbitControls.Hook->keyPanSpeed"))
            return false;
        return true;
    },
    "movementSmoothness": function (value) {
        if (!this._globalUtils.typeCheck(value, "number", this._logWarning, "OrbitControls.Hook->movementSmoothness"))
            return false;
        return true;
    },
    "restrictions.position.cube.min": function (value) {
        var s = this._settings.getSetting("restrictions.position.cube");
        s.min = value;
        return this._settings.updateSetting("restrictions.position.cube", s);
    },
    "restrictions.position.cube.max": function (value) {
        var s = this._settings.getSetting("restrictions.position.cube");
        s.max = value;
        return this._settings.updateSetting("restrictions.position.cube", s);
    },
    "restrictions.position.cube": function (value) {
        var scope = "OrbitControls.Hook->restrictions.position.cube";
        if (!(this._globalUtils.typeCheck(value.min, "vector3any", this._logWarning, scope) && this._globalUtils.typeCheck(value.max, "vector3any", this._logWarning, scope)))
            return false;
        this._triggerRender();
        return true;
    },
    "restrictions.position.sphere.center": function (value) {
        var s = this._settings.getSetting("restrictions.position.sphere");
        s.center = value;
        return this._settings.updateSetting("restrictions.position.sphere", s);
    },
    "restrictions.position.sphere.radius": function (value) {
        var s = this._settings.getSetting("restrictions.position.sphere");
        s.radius = value;
        return this._settings.updateSetting("restrictions.position.sphere", s);
    },
    "restrictions.position.sphere": function (value) {
        var scope = "OrbitControls.Hook->restrictions.position.sphere";
        if (!(this._globalUtils.typeCheck(value.center, "vector3any", this._logWarning, scope) && this._globalUtils.typeCheck(value.radius, "notnegative", this._logWarning, scope)))
            return false;
        this._triggerRender();
        return true;
    },
    "restrictions.target.cube.min": function (value) {
        var s = this._settings.getSetting("restrictions.target.cube");
        s.min = value;
        return this._settings.updateSetting("restrictions.target.cube", s);
    },
    "restrictions.target.cube.max": function (value) {
        var s = this._settings.getSetting("restrictions.target.cube");
        s.max = value;
        return this._settings.updateSetting("restrictions.target.cube", s);
    },
    "restrictions.target.cube": function (value) {
        var scope = "OrbitControls.Hook->restrictions.target.cube";
        if (!(this._globalUtils.typeCheck(value.min, "vector3any", this._logWarning, scope) && this._globalUtils.typeCheck(value.max, "vector3any", this._logWarning, scope)))
            return false;
        this._triggerRender();
        return true;
    },
    "restrictions.target.sphere.center": function (value) {
        var s = this._settings.getSetting("restrictions.target.sphere");
        s.center = value;
        return this._settings.updateSetting("restrictions.target.sphere", s);
    },
    "restrictions.target.sphere.radius": function (value) {
        var s = this._settings.getSetting("restrictions.target.sphere");
        s.radius = value;
        return this._settings.updateSetting("restrictions.target.sphere", s);
    },
    "restrictions.target.sphere": function (value) {
        var scope = "OrbitControls.Hook->restrictions.target.sphere";
        if (!(this._globalUtils.typeCheck(value.center, "vector3any", this._logWarning, scope) && this._globalUtils.typeCheck(value.radius, "notnegative", this._logWarning, scope)))
            return false;
        this._triggerRender();
        return true;
    },
    "restrictions.rotation.minPolarAngle": function (value) {
        var s = this._settings.getSetting("restrictions.rotation");
        s.minPolarAngle = value;
        return this._settings.updateSetting("restrictions.rotation", s);
    },
    "restrictions.rotation.maxPolarAngle": function (value) {
        var s = this._settings.getSetting("restrictions.rotation");
        s.maxPolarAngle = value;
        return this._settings.updateSetting("restrictions.rotation", s);
    },
    "restrictions.rotation.minAzimuthAngle": function (value) {
        var s = this._settings.getSetting("restrictions.rotation");
        s.minAzimuthAngle = value;
        return this._settings.updateSetting("restrictions.rotation", s);
    },
    "restrictions.rotation.maxAzimuthAngle": function (value) {
        var s = this._settings.getSetting("restrictions.rotation");
        s.maxAzimuthAngle = value;
        return this._settings.updateSetting("restrictions.rotation", s);
    },
    "restrictions.rotation": function (value) {
        var scope = "OrbitControls.Hook->restrictions.rotation";
        if (!(this._globalUtils.typeCheck(value.minPolarAngle, "number", this._logWarning, scope) &&
            this._globalUtils.typeCheck(value.maxPolarAngle, "number", this._logWarning, scope) &&
            this._globalUtils.typeCheck(value.minAzimuthAngle, "number", this._logWarning, scope) &&
            this._globalUtils.typeCheck(value.maxAzimuthAngle, "number", this._logWarning, scope)))
            return false;
        this._triggerRender();
        return true;
    },
    "restrictions.zoom.minDistance": function (value) {
        var s = this._settings.getSetting("restrictions.zoom");
        s.minDistance = value;
        return this._settings.updateSetting("restrictions.zoom", s);
    },
    "restrictions.zoom.maxDistance": function (value) {
        var s = this._settings.getSetting("restrictions.zoom");
        s.maxDistance = value;
        return this._settings.updateSetting("restrictions.zoom", s);
    },
    "restrictions.zoom": function (value) {
        var scope = "OrbitControls.Hook->restrictions.zoom";
        if (!(this._globalUtils.typeCheck(value.minDistance, "number", this._logWarning, scope) && this._globalUtils.typeCheck(value.maxDistance, "number", this._logWarning, scope)))
            return false;
        this._triggerRender();
        return true;
    },
    "rotationSpeed": function (value) {
        if (!this._globalUtils.typeCheck(value, "float", this._logWarning, "OrbitControls.Hook->rotationSpeed"))
            return false;
        return true;
    },
    "panSpeed": function (value) {
        if (!this._globalUtils.typeCheck(value, "number", this._logWarning, "OrbitControls.Hook->panSpeed"))
            return false;
        return true;
    },
    "zoomSpeed": function (value) {
        if (!this._globalUtils.typeCheck(value, "notnegative", this._logWarning, "OrbitControls.Hook->zoomSpeed"))
            return false;
        return true;
    },
};
exports.OrbitControlsNotifiers = {};
